import React from "react";
import "./Organ.css";
const Organ = () => {
  return (
    <>
    <div className="responsive-image">
    <img src="images/organ_banner.jpg" alt="organbanner"/>
    </div>
    <div className="container">
      <h1 style={{textAlign: 'center'}}>Organ Donation in India</h1>
      <h2>What is Organ Donation?</h2>
      <p>
        Organ donation is the act of giving one’s organs or tissue to someone in
        need of a transplant. A donor can save up to eight lives by donating
        organs such as the heart, liver, kidneys, lungs, pancreas, and
        intestines. Organ donation can occur in two ways: living donation and
        deceased (cadaver) donation.
      </p>

      <h2>How is Organ Donation Done?</h2>
      <p>
        The process of organ donation varies depending on whether the donor is
        living or deceased:
      </p>
      <ul>
        <li>
          <strong>Living Donation:</strong> A healthy individual voluntarily
          donates an organ or part of an organ while they are still alive.
          Common living donations include a kidney or a part of the liver.
        </li>
        <li>
          <strong>Deceased Donation:</strong> When a person dies due to brain
          death or cardiac death, their organs can be donated if consent has
          been given by the donor while alive or by the donor’s family after
          death.
        </li>
      </ul>
      <p>
        The transplant team then assesses the viability of the organs and
        proceeds with the surgical removal and transplantation process.
      </p>

      <h2>Who Can Donate?</h2>
      <p>
        Anyone can become a donor regardless of age, gender, or medical history.
        However, certain conditions must be met for a successful donation:
      </p>
      <ul>
        <li>
          <strong>Living Donors:</strong> Generally healthy individuals who are
          at least 18 years old can donate organs such as a kidney or part of
          their liver. A thorough medical evaluation is conducted to ensure that
          the donor is fit for surgery.
        </li>
        <li>
          <strong>Deceased Donors:</strong> People of any age can donate organs
          after death, provided the organs are healthy and suitable for
          transplantation. In cases of brain death, organ donation is considered
          if the donor has expressed their wish to donate, or if the family
          consents.
        </li>
      </ul>

      <h2>Benefits of Organ Donation</h2>
      <p>Organ donation has several benefits, including:</p>
      <ul>
        <li>
          <strong>Saving Lives:</strong> One donor can save up to eight lives
          and improve many more through tissue donation.
        </li>
        <li>
          <strong>Enhancing Quality of Life:</strong> Transplants can
          significantly improve the quality of life for recipients, allowing
          them to return to normal activities.
        </li>
        <li>
          <strong>Encouraging Medical Research:</strong> Donated organs and
          tissues can also be used for medical research and education,
          contributing to advancements in medical science.
        </li>
        <li>
          <strong>Giving Back:</strong> Organ donation provides a way to give
          back to society and help those in need.
        </li>
      </ul>

      <h2>How to Register as an Organ Donor</h2>
      <p>Registering as an organ donor in India is a simple process:</p>
      <div className="register-info">
        <ol>
          <li>
            Visit the official website of the{" "}
            <a href="https://notto.gov.in" target="_blank" rel="noreferrer">
              National Organ & Tissue Transplant Organization (NOTTO)
            </a>{" "}
            or any other authorized organ donation organization.
          </li>
          <li>
            Fill out the online registration form with your personal details,
            including your consent to donate organs.
          </li>
          <li>
            You may receive an organ donor card, which indicates your
            willingness to donate organs after your death. Carry this card with
            you at all times and inform your family about your decision.
          </li>
          <li>
            It's also advisable to register with your state’s specific organ
            donation authority if available.
          </li>
        </ol>
      </div>

      <h2>Whom to Contact in India</h2>
      <p>
        If you are interested in becoming an organ donor in India or need more
        information, you can contact the following organizations:
      </p>
      <div className="contact-info">
        <h3>National Organ & Tissue Transplant Organization (NOTTO)</h3>
        <p>
          Website:{" "}
          <a href="https://notto.gov.in" target="_blank" rel="noreferrer">
            https://notto.gov.in
          </a>
        </p>
        <p>Phone: 1800-11-4770 (Toll-Free)</p>
        <p>
          Email: <a href="mailto:dir@notto.nic.in">dir@notto.nic.in</a>
        </p>
      </div>
    </div>
    </>
  );
};

export default Organ;
