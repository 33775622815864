import React from 'react'
import './Privacy.css'
const Privacy = () => {
  return (
    <div class="container">
        <h1>Privacy Policy</h1>
        <p>This Privacy Policy explains how our website collects, uses, and discloses information about you. By using our website, you agree to the terms of this policy. Please read it carefully.</p>

        <h2>Information We Collect</h2>
        <p>We collect information that you provide directly to us when you register as a blood donor, request information, or use any of our services. This may include your name, email address, phone number, and any other details you provide.</p>

        <h2>How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
            <li>To register you as a blood donor and provide information to those in need of blood.</li>
            <li>To communicate with you about your registration and other requests.</li>
            <li>To provide information about organ donation, hair donation, and ambulance services.</li>
            <li>To improve our website and services based on your feedback.</li>
        </ul>

        <h2>Sharing Your Information</h2>
        <p>We do not share your personal information with third parties except in the following circumstances:</p>
        <ul>
            <li>With your consent or at your direction.</li>
            <li>To comply with legal obligations or respond to lawful requests from public authorities.</li>
            <li>To protect and defend the rights or property of our website.</li>
        </ul>

        <h2>Data Security</h2>
        <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure. We cannot guarantee the absolute security of your information.</p>

        <h2>Your Rights</h2>
        <p>You have the right to access, update, or delete your personal information. To do so, please contact us through our website’s contact page. We will respond to your request within a reasonable timeframe.</p>

        <h2>Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

        <p>If you have any questions about this Privacy Policy, please contact us through our website's contact page.</p>
    </div>
  )
}

export default Privacy