export const bloodGropups = [
  "A+",
  "A-",
  "A1+",
  "A1-",
  "A1B+",
  "A1B-",
  "A2+",
  "A2-",
  "A2B+",
  "A2B-",
  "AB+",
  "AB-",
  "B+",
  "B-",
  "Bombay Blood Group",
  "INRA",
  "O+",
  "O-",
];


export const statesandcities = [
    {
      "Country": "INDIA",
      "State": "Andaman and Nicobar Islands",
      "District": "Andaman and Nicobar Islands",
      "City": "Garacharma"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Prathipadu"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Rajahmundry"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Tuni"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Guntur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Kuchinapudi"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Narasaraopet"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Piduguralla"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kadapa",
      "City": "Kodur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Adoni"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Dhone"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Sarvepalli"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Kandukur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Kondepi"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Markapur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Parchur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Araku"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Elamanchali"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Madugula"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Paderu"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Payakaraopeta"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Pendurthi"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Gajapathinagaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Vizianagaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Bhimavaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Chintalapudi"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Dibang Valley",
      "City": "Anini"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lohit",
      "City": "Namsai"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lowersubansiri",
      "City": "Koloriang"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lowersubansiri",
      "City": "Tali"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Papum Pare",
      "City": "Sagalee"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Tirap",
      "City": "Pongchao Wakka"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Upper Siang",
      "City": "Mariyang-geku"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Uppersubansiri",
      "City": "Raga"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Uppersubansiri",
      "City": "Taliha"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "West Kameng",
      "City": "Thrizino-Buragaon"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Barpeta",
      "City": "Patacharkuchi"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Cacher",
      "City": "Katigora"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Darrang",
      "City": "Sipajhar"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Golaghat",
      "City": "Golaghat"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Hailakandi",
      "City": "Katlicherra"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Jorhat",
      "City": "Jorhat"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Jorhat",
      "City": "Mariani"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kamrup",
      "City": "Boko"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kamrup",
      "City": "Hajo"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kamrup",
      "City": "Kamalpur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kokrajhar",
      "City": "Sidli"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Lakhimpur",
      "City": "Dhakuakhana"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Morigaon",
      "City": "Laharighat"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nagaon",
      "City": "Hojai"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nagaon",
      "City": "Lumding"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nalbari",
      "City": "Barkhetry"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sonitpur",
      "City": "Behali"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Udalguri",
      "City": "Udalguri"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Araria",
      "City": "Raniganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Aurangabad",
      "City": "Rafiganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Begusarai",
      "City": "Bachhavada"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Begusarai",
      "City": "Barauni"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Begusarai",
      "City": "Matihani"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhagalpur",
      "City": "Bihpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Darbhanga",
      "City": "Baheda"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Darbhanga",
      "City": "Bahedi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Darbhanga",
      "City": "Jale"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gaya",
      "City": "Atari"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gaya",
      "City": "Boudh Gaya"
    },
    {
      "Country": "INDIA",
      "State": "Andaman and Nicobar Islands",
      "District": "Andaman and Nicobar Islands",
      "City": "Bombooflat"
    },
    {
      "Country": "INDIA",
      "State": "Andaman and Nicobar Islands",
      "District": "Port Blair",
      "City": "Port Blair"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Tadpatri"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Alamuru"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Kadiam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Mummidivaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Pithapuram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Macherla"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Avanigadda"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Vijayawada"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Panyam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Anakapalli"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Bheemunipatnam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Bobbili"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Perumali"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Kovvur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Penugonda"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "East Kameng",
      "City": "Bameng"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "East Siang",
      "City": "Pangin"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "East Siang",
      "City": "Pasighat West"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lohit",
      "City": "Chowkham"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Tirap",
      "City": "Khonsa East"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Tirap",
      "City": "Longding Pumao"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Upper Siang",
      "City": "Tuting Yingkiong"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "West Kameng",
      "City": "Dirang"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "West Siang",
      "City": "Along East"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Barpeta",
      "City": "Barpeta"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Bongaigaon",
      "City": "Bongaigaon"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Cacher",
      "City": "Dholai"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Darrang",
      "City": "Mangaldoi"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dibrugarh",
      "City": "Lahowal"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Golaghat",
      "City": "Khumtai"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kamrup Metro",
      "City": "Dispur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Karbi Anglong",
      "City": "Bokajan"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Karbi Anglong",
      "City": "Diphu"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Karimganj",
      "City": "Patharkandi"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kokrajhar",
      "City": "Kokrajhar"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sibsagar",
      "City": "Mahmara"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sonitpur",
      "City": "Barchalla"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sonitpur",
      "City": "Dhekiajuli"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Aurangabad",
      "City": "Aurangabad"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Aurangabad",
      "City": "Goh"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Buxar",
      "City": "Brahmapur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Darbhanga",
      "City": "6.3Darbhanga"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Darbhanga",
      "City": "Manigachhi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gaya",
      "City": "Fatehpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gaya",
      "City": "Barachatti"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gopalganj",
      "City": "Barauli"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Jahanabad",
      "City": "Makhdamapur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Jamui",
      "City": "Chakai"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Kaimur",
      "City": "Chainpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Khagaria",
      "City": "Alauli"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhubani",
      "City": "Khajauli"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Muzaffarpur",
      "City": "Bochaha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Muzaffarpur",
      "City": "Kanti"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Muzaffarpur",
      "City": "Minapur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Madakasira"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Nallamada"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Rayadurgam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Chittoor"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Satyavedu"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Jaggampeta"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Yellavaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Bapatla"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Ponnur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Sattenapalle"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Vemur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Nidumolu"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Alur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Nandikotkur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Kavali"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Chirala"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Darsi"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Srikakulam",
      "City": "Sompeta"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Srikakulam",
      "City": "Srikakulam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Parvathipuram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Tanuku"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "East Siang",
      "City": "Mebo"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Uppersubansiri",
      "City": "Nacho"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "West Siang",
      "City": "Likabali"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "West Siang",
      "City": "Rumgong"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Barpeta",
      "City": "Chenga"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Bongaigaon",
      "City": "Abhayapuri"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Cacher",
      "City": "Lakhipur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Cacher",
      "City": "Silchar"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Darrang",
      "City": "Kalaigaon"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dhubri",
      "City": "Dhubri"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dhubri",
      "City": "Golakganj"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dhubri",
      "City": "Mankachar"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dibrugarh",
      "City": "Dibrugarh"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dibrugarh",
      "City": "Tingkhong"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Goalpara",
      "City": "Dudhnai"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Golaghat",
      "City": "Bokakhat"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Jorhat",
      "City": "Majuli"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kamrup Metro",
      "City": "Jalukbari"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Lakhimpur",
      "City": "Bihpuria"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Morigaon",
      "City": "Marigaon"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nagaon",
      "City": "Jamunamukh"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nalbari",
      "City": "Nalbari"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sibsagar",
      "City": "Sibsagar"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sonitpur",
      "City": "Biswanath"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sonitpur",
      "City": "Tezpur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Tinsukia",
      "City": "Doom Dooma"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Tinsukia",
      "City": "Sadiya"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Araria",
      "City": "Sikti"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Arwal",
      "City": "Arwal"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Buxar",
      "City": "Dumarav"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Darbhanga",
      "City": "Hayaghat"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Kaimur",
      "City": "Bhabhua"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "katihar",
      "City": "Kadwa"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Khagaria",
      "City": "Khagaria"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhubani",
      "City": "Benipatti"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhubani",
      "City": "Harlakhi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhubani",
      "City": "Madhubani"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Munger",
      "City": "Jamalpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Muzaffarpur",
      "City": "Baruraj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Muzaffarpur",
      "City": "Muzaffarpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gaya",
      "City": "Belaganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gaya",
      "City": "Gaya Town"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gaya",
      "City": "Gurua"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gaya",
      "City": "Imamganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gopalganj",
      "City": "Baikunthpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Jahanabad",
      "City": "Ghosi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Jamui",
      "City": "Sikandra"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Kaimur",
      "City": "Ramagadh"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "katihar",
      "City": "Manihari"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhubani",
      "City": "Pandaul"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Muzaffarpur",
      "City": "Gaighatti"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Kuppam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Samalkot"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Amaravathi"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Tenali"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kadapa",
      "City": "Proddatur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Nandigama"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Kurnool"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Nandyal"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Yemmiganur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Gudur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Rapur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Santhanuthalapadu"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Chodavaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Cheppurupalli"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Tagarapuvalasa"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Dendulur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Gopalpuram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Nidadavole"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Changlang",
      "City": "Bordumsa-diyum"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Changlang",
      "City": "Changlang South"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lohit",
      "City": "Hayuliang"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lohit",
      "City": "Lekang"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lohit",
      "City": "Tezu"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lower Dibang Valley",
      "City": "Dambuk"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lower Dibang Valley",
      "City": "Roing"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Barpeta",
      "City": "Jania"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Barpeta",
      "City": "Sarukhetri"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Bongaigaon",
      "City": "Bijni"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dibrugarh",
      "City": "Moran"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Goalpara",
      "City": "Goalpara"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Hailakandi",
      "City": "Hailakandi"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Jorhat",
      "City": "Titabar"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Lakhimpur",
      "City": "Lakhimpur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Lakhimpur",
      "City": "Naoboicha"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nagaon",
      "City": "Barhampur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nagaon",
      "City": "Dhing"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nagaon",
      "City": "Kaliabor"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nagaon",
      "City": "Raha"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nalbari",
      "City": "Dharmapur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sibsagar",
      "City": "Amguri"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Tinsukia",
      "City": "Tinsukia"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Udalguri",
      "City": "Panery"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Aurangabad",
      "City": "Nabinagar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Banka",
      "City": "Amarpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Begusarai",
      "City": "Cheria Bariyarpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhagalpur",
      "City": "Nathnagar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhojpur",
      "City": "Jagdishpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Darbhanga",
      "City": "Darbhanga(Gamin)"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gopalganj",
      "City": "Bhore"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gopalganj",
      "City": "Mirganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "katihar",
      "City": "Pranapur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Khagaria",
      "City": "Parbatta"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Kishanganj",
      "City": "Kishanganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhepura",
      "City": "Kumarkhand"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhepura",
      "City": "Singheshwar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Muzaffarpur",
      "City": "Kurhani"
    },
    {
      "Country": "INDIA",
      "State": "Andaman and Nicobar Islands",
      "District": "Andaman and Nicobar Islands",
      "City": "Car Nicobar"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Anantapur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Hindupur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Penukonda"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Madanapalle"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Srikalahasti"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Tirupathi"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Allavaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Amalapuram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Anaparthy"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Burugupudi"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Peddapuram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Thallarevu"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Chilakaluripet"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Tadikonda"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Vinukonda"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kadapa",
      "City": "Badvel"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kadapa",
      "City": "Kadapa"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kadapa",
      "City": "Pulivendula"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Bandar"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Kankipadu"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Tiruvuru"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Atmakur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Koilkuntla"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Sulurpet"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Srikakulam",
      "City": "Palasa"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Srikakulam",
      "City": "Rajam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Srikakulam",
      "City": "Tekkali"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Salur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Palacole"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Tadepalligudem"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "East Kameng",
      "City": "Seppa West"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "East Siang",
      "City": "Nari-koyu"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "East Siang",
      "City": "Pasighat East"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lowersubansiri",
      "City": "Nyapin"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Papum Pare",
      "City": "Itanagar"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Tawang",
      "City": "Lumla"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Tirap",
      "City": "Borduria Bogapani"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Tirap",
      "City": "Khonsa West"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "West Siang",
      "City": "Mechukha"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Barpeta",
      "City": "Baghbar"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dhemji",
      "City": "Jonai"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dhubri",
      "City": "Gauripur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dhubri",
      "City": "Salmara South"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Golaghat",
      "City": "Sarupathar"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kamrup",
      "City": "Chaygaon"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kamrup Metro",
      "City": "Guwahati"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Karimganj",
      "City": "Karimganj"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Karimganj",
      "City": "Ratabari"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "N.c hills",
      "City": "Haflong"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nagaon",
      "City": "Rupohihat"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Tinsukia",
      "City": "Margherita"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Aurangabad",
      "City": "Deb"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Banka",
      "City": "Banka"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Begusarai",
      "City": "Begusarai"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhojpur",
      "City": "Badahara"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhojpur",
      "City": "Piro"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Buxar",
      "City": "Buxor"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Buxar",
      "City": "Rajpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gaya",
      "City": "Gaya Mufafasil"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Jamui",
      "City": "Jamui"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Kaimur",
      "City": "Mohaniya"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "katihar",
      "City": "katihar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhepura",
      "City": "3.Madhepura"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhepura",
      "City": "Kishanganj"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Guntakal"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Singanamala"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Kakinada"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Mandapeta"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Anu Campus"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Mangalagiri"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Phirangipuram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Prathipadu"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kadapa",
      "City": "Kamalapuram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kadapa",
      "City": "Rajampet"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Gudivada"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Jaggayapet"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Mudinepalli"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Vuyyuru"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Kodumur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Allur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Kovur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Nellore"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Udayagiri"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Venkatagiri"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Srikakulam",
      "City": "Ichapuram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Srikakulam",
      "City": "Nivigam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Gajuwaka"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Balijipeta"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Attili"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Undi"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Ungutur"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "East Kameng",
      "City": "Seppa East"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lowersubansiri",
      "City": "Palin"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lowersubansiri",
      "City": "Yachuli"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Papum Pare",
      "City": "Doimukh"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Barpeta",
      "City": "Sorbhog"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Cacher",
      "City": "Barkhola"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Cacher",
      "City": "Sonai"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Darrang",
      "City": "Dalgaon"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dibrugarh",
      "City": "Chabua"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Hailakandi",
      "City": "Algapur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kamrup",
      "City": "Rangiya"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Karimganj",
      "City": "Badarpur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kokrajhar",
      "City": "Gossaigaon"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Morigaon",
      "City": "Jagiroad"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nagaon",
      "City": "Nowgong"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nalbari",
      "City": "Barama"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nalbari",
      "City": "Tamulpur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sonitpur",
      "City": "Gohpur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sonitpur",
      "City": "Sootea"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Araria",
      "City": "Araria"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Araria",
      "City": "Forbesganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Aurangabad",
      "City": "Ovra"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhagalpur",
      "City": "Bhagalpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gaya",
      "City": "Konch"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gopalganj",
      "City": "Kateya"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Jahanabad",
      "City": "Jahanabad"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Kishanganj",
      "City": "Thakurganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhubani",
      "City": "Babubrhi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhubani",
      "City": "Madhepur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Muzaffarpur",
      "City": "Aurai"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Gooty"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhubani",
      "City": "9.Fulparas"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhubani",
      "City": "Bisfi"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Gorantla"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Kadiri"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Kalyandurgam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Uravakonda"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Chandragiri"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Pileru"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Puttur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Vayalpad"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Vepanjeri"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Rampachodavaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Sampara"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Gurazala"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Tadepalle"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kadapa",
      "City": "Jammalamadugu"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kadapa",
      "City": "Rayachoty"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Gannavaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Kaikalur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Mylavaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Allagadda"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Giddalur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Visakhapatnam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Badangi"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Srungavarapu kota"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Vizianagaram",
      "City": "Therlam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Achanta"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Narsapur"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Changlang",
      "City": "Changlang North"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "East Kameng",
      "City": "Pakke Kessang"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Tawang",
      "City": "Mukto"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Tirap",
      "City": "Kanubari"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Tirap",
      "City": "Namsang"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Uppersubansiri",
      "City": "Daporijo"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Uppersubansiri",
      "City": "Dumporijo"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "West Kameng",
      "City": "Bomdila"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "West Kameng",
      "City": "Kalaktang"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "West Siang",
      "City": "Liromoba"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dhubri",
      "City": "Bilasipara"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dibrugarh",
      "City": "Duliajan"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Goalpara",
      "City": "Jaleswar"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Jorhat",
      "City": "Teok"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Kamrup",
      "City": "Palasbari"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Karbi Anglong",
      "City": "Baithalangso"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Karbi Anglong",
      "City": "Howraghat"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nagaon",
      "City": "Batadroba"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nagaon",
      "City": "Samaguri"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Tinsukia",
      "City": "Digboi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Araria",
      "City": "Narapatganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Arwal",
      "City": "Kurtha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Banka",
      "City": "Dhuraiya"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Banka",
      "City": "Katoriya"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhagalpur",
      "City": "Pirpainti"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhagalpur",
      "City": "Sultanganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhojpur",
      "City": "Ara"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhojpur",
      "City": "Shahpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Darbhanga",
      "City": "Keoti"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Jamui",
      "City": "Jhanjha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "katihar",
      "City": "Barsoi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Khagaria",
      "City": "Chautham"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhepura",
      "City": "Alamnagar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhubani",
      "City": "Jhanjhrpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Munger",
      "City": "Khadagpur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Anantapur",
      "City": "Dhamavaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Nagari"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Palmaner"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Punganur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Chittor",
      "City": "Thamballapalle"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Annavaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Ramachandrapuram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Ravulapalem"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "East Godavari",
      "City": "Seethanagaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Duggirala"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Peddakurapaadu"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Guntur",
      "City": "Repalle"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kadapa",
      "City": "Lakkireddipalli"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kadapa",
      "City": "Mydukur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Machilipatnam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Malleswaram"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Krishna",
      "City": "Nuzvid"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Kurnool",
      "City": "Pattikonda"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Atmakur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Nellore",
      "City": "Podalakur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Addanki"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Cumbum"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Kanigiri"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Martur"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Prakasam",
      "City": "Ongole"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Srikakulam",
      "City": "Hiramandalam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "Visakapatnam",
      "City": "Narsipatnam"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Eluru"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Polamuru"
    },
    {
      "Country": "INDIA",
      "State": "Andhra Pradesh",
      "District": "West Godavari",
      "City": "Polavaram"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Changlang",
      "City": "Miao"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Changlang",
      "City": "Nampong"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "East Kameng",
      "City": "Chayang Tajo"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Lowersubansiri",
      "City": "Ziro Hapoli"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "Tawang",
      "City": "Tawang"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "West Siang",
      "City": "Along West"
    },
    {
      "Country": "INDIA",
      "State": "Arunachal Pradesh",
      "District": "West Siang",
      "City": "Basar"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Barpeta",
      "City": "Bhabanipur"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Cacher",
      "City": "Udharbond"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dhemji",
      "City": "Dhemaji"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Dibrugarh",
      "City": "Naharkatia"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Jorhat",
      "City": "Dergaon"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Nalbari",
      "City": "Chapaguri"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sibsagar",
      "City": "Nazira"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sibsagar",
      "City": "Sonari"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sibsagar",
      "City": "Thowra"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Sonitpur",
      "City": "Rangapara"
    },
    {
      "Country": "INDIA",
      "State": "Assam",
      "District": "Udalguri",
      "City": "Majbat"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Araria",
      "City": "Jokihat"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Banka",
      "City": "Belhar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Begusarai",
      "City": "Bakhri"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Begusarai",
      "City": "Balia"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhagalpur",
      "City": "Gopalpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhagalpur",
      "City": "Kahalgaon"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Bhojpur",
      "City": "Sandesh"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Darbhanga",
      "City": "Ghanshyampur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Gopalganj",
      "City": "Gopalganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Jamui",
      "City": "Tarapur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "katihar",
      "City": "Barari"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "katihar",
      "City": "Kodha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Kishanganj",
      "City": "Bahadurganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Lakhisarai",
      "City": "Lakhisarai"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Lakhisarai",
      "City": "Surajagadha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Madhubani",
      "City": "Laukaha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Munger",
      "City": "Munger"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Muzaffarpur",
      "City": "Paru"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nalanda",
      "City": "Chandi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nalanda",
      "City": "Islampur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Fatuha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Muzaffarpur",
      "City": "Sahebganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nalanda",
      "City": "Hilsa"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Maner"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Patna(west)"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Purnea",
      "City": "Amour"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Samastipur",
      "City": "Kalyanpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Samastipur",
      "City": "Varishnagar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saran",
      "City": "Chapra"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saran",
      "City": "Jalalpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saran",
      "City": "Maraurha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saran",
      "City": "Parsa"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Sheikhpura",
      "City": "Barbigha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Supaul",
      "City": "Chhatapur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Supaul",
      "City": "Raghopur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Madhuban"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Ramnagar"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Bijapur",
      "City": "Bijapur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Bilaspur",
      "City": "Mungeli"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Janjgir",
      "City": "Champa"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Janjgir",
      "City": "Masturi"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Kanker",
      "City": "Sihawa"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Korea",
      "City": "Korea"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raigarh",
      "City": "Dharmjaigarh"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raigarh",
      "City": "Kharsia"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raipur",
      "City": "Raipur Garmin"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raipur",
      "City": "Raipur Nagar"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Sarangarh",
      "City": "Saria"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Surguja",
      "City": "Premnagar"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Surguja",
      "City": "Samri"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "Central Delhi",
      "City": "Matia Mahal"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "East delhi",
      "City": "patpar ganj"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north delhi",
      "City": "Kamla nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "West delhi",
      "City": "Hari nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "West delhi",
      "City": "Patel nagar"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Goa",
      "City": "Dabolim"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Panaji",
      "City": "Raibandar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bharuch",
      "City": "Bharuch"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bharuch",
      "City": "Jambusar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Sihor"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "kolavada"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "veraval"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "kachchh",
      "City": "Bachau"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Kheda",
      "City": "chaklasi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Kheda",
      "City": "Mahemdavad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Mahesana",
      "City": "kheralu"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Navsari",
      "City": "Chikhli"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Panchmahals",
      "City": "Godhra"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Patan",
      "City": "Harij"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "surendranagar",
      "City": "Halvad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "Dungra"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "umbergoan Ina"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nawada",
      "City": "Rajoli"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Danapur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Mokama"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Patna(Central)"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Purnea",
      "City": "Dhamdaha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saran",
      "City": "Sonpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Sitamarhi",
      "City": "Majorganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Sitamarhi",
      "City": "Runi Saiadpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Supaul",
      "City": "Triveniganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Vaishali",
      "City": "Vaishali"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Bilaspur",
      "City": "Manendragarh"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Bilaspur",
      "City": "Marwahi"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Dhamtari",
      "City": "Dhamtari"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Janjgir",
      "City": "Tanakhar"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Kabirdham",
      "City": "Kabirdham"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Mahasamund",
      "City": "Bindranavagrh"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raipur",
      "City": "Dharsiwa"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Rajnandgon",
      "City": "Rajnandgon"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Sarangarh",
      "City": "Chandrapur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Sarangarh",
      "City": "Kasdol"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Sarangarh",
      "City": "Sarangarh"
    },
    {
      "Country": "INDIA",
      "State": "Daman and Diu",
      "District": "Daman",
      "City": "Daman"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north delhi",
      "City": "Timapur"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north east delhi",
      "City": "Ghonda"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north east delhi",
      "City": "Seemapuri"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Shalimar Bagh"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "South Delhi",
      "City": "Malviya Nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "South Delhi",
      "City": "Okhla"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "south west delhi",
      "City": "Najafgarh"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "West delhi",
      "City": "Nangloi Jat"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "West delhi",
      "City": "Vishnu Garden"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Panaji",
      "City": "Santacruz"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Amerli",
      "City": "Lathi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Amerli",
      "City": "Rajula"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Banaskantha",
      "City": "Dhanera"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Banaskantha",
      "City": "Palanpur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bharuch",
      "City": "Anklesvar Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bharuch",
      "City": "Bharuch Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bharuch",
      "City": "Palej"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Alang"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Gariadhar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "Chandkheda"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "Chhatral Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "Chiloda (naroda)"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "kalol"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "Aarmbhada"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "Jam jodhpur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "khambhalia"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "mangrol"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "kachchh",
      "City": "mandvi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Kheda",
      "City": "Balasinor"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Navsari",
      "City": "Navsari"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Panchmahals",
      "City": "Halol"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Panchmahals",
      "City": "Kalol Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Panchmahals",
      "City": "Lunawada"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Patan",
      "City": "Patan"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Patan",
      "City": "Sidhpur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Rajkot",
      "City": "Dhoraji"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Rajkot",
      "City": "Paddhari"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Rajkot",
      "City": "Wankaner"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "songadh"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "Tarsali"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "Vaghodia Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "Abrama"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Fulwari"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Rohtas",
      "City": "Chenari"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Rohtas",
      "City": "Dehury"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Rohtas",
      "City": "Dinara"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Rohtas",
      "City": "Karakat"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Rohtas",
      "City": "Sasaram"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Samastipur",
      "City": "Bibhutapur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Samastipur",
      "City": "Hasanpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Samastipur",
      "City": "Roseda"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saran",
      "City": "Baniapur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saran",
      "City": "Mashrakh"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Sitamarhi",
      "City": "Saunbarasa"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Siwan",
      "City": "GORIAKOTHI"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Siwan",
      "City": "Raghunathpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Adapur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Ghodasahan"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Raxaul"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Bilaspur",
      "City": "Lormi"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Bilaspur",
      "City": "Takhatpur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Janjgir",
      "City": "Akaltara"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Korba",
      "City": "Korba"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raigarh",
      "City": "Bagicha"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raigarh",
      "City": "Sarangarh"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raipur",
      "City": "Abhanpur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Sarangarh",
      "City": "Malkharuoda"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Surguja",
      "City": "Baikunthpur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Surguja",
      "City": "Pal"
    },
    {
      "Country": "INDIA",
      "State": "Dadra  and nagar haveli",
      "District": "Silvassa",
      "City": "Silvassa"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "Central Delhi",
      "City": "Pahar ganj"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "East delhi",
      "City": "Mandawali"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "East delhi",
      "City": "Vishwash nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "New delhi",
      "City": "minto road"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north east delhi",
      "City": "Qarawal nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north east delhi",
      "City": "Yamuna Vihar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Badli"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Mangolpuri"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "South Delhi",
      "City": "Dr.Ambedkar nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "South Delhi",
      "City": "Saket"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "south west delhi",
      "City": "Delhi cantonment"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "south west delhi",
      "City": "Dwarka"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Goa",
      "City": "Bogmalo"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Goa",
      "City": "Ponda"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Khambhat"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Vitthal Udyognagar Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Banaskantha",
      "City": "Deesa"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Ghogha"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "Dwarka"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "Jamnagar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "chorvad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "kachchh",
      "City": "mundra"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Kheda",
      "City": "kapadvanj"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Patan",
      "City": "Chanasma"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Patan",
      "City": "Radhanpur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Rajkot",
      "City": "Bhayavadar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Rajkot",
      "City": "Gondal"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Rajkot",
      "City": "Morvi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Bardoli"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Ichchhapur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "kadodara"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Limla"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "surendranagar",
      "City": "Kharaghoda"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "surendranagar",
      "City": "Limbdi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "surendranagar",
      "City": "Thangadh"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "Bodeli"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Muzaffarpur",
      "City": "Sakra"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nalanda",
      "City": "Bihar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nalanda",
      "City": "Nalanda"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nawada",
      "City": "Gobindpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Purnea",
      "City": "Rupauli"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saharsa",
      "City": "Mahishi."
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Samastipur",
      "City": "Dalsinghsarai"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Samastipur",
      "City": "Mohiuddin nagar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Samastipur",
      "City": "Samastiapur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Samastipur",
      "City": "Singhiya"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Sitamarhi",
      "City": "Belsand"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Sitamarhi",
      "City": "Pupri"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Sitamarhi",
      "City": "Sursand"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Supaul",
      "City": "Supaul"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Vaishali",
      "City": "Patepur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Betiya"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Gobindganj"
    },
    {
      "Country": "INDIA",
      "State": "Chandigarh",
      "District": "Chandigarh",
      "City": "Chandigarh"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Baster",
      "City": "Baster"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Bilaspur",
      "City": "kota"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Dantewada",
      "City": "Dantewada"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Janjgir",
      "City": "Katghora"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Janjgir",
      "City": "Rampur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Mahasamund",
      "City": "Mahasamund"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Mahasamund",
      "City": "Rajim"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Narayanpur",
      "City": "Narayanpur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raigarh",
      "City": "Lailunga"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raigarh",
      "City": "Tapkara"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Sarangarh",
      "City": "Palari"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Surguja",
      "City": "Pilkha"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "New delhi",
      "City": "gole market"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north delhi",
      "City": "Balli maran"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north delhi",
      "City": "chandni chowk"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north east delhi",
      "City": "Babarpur"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north east delhi",
      "City": "Nand nagari"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north east delhi",
      "City": "Rohtas nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "South Delhi",
      "City": "Tughlakabad"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "south west delhi",
      "City": "R K Puram"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "south west delhi",
      "City": "Rajinder Nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "West delhi",
      "City": "Hastsal"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "West delhi",
      "City": "Sultanpur majra"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "West delhi",
      "City": "Tilak Nagar"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Panaji",
      "City": "Caranzalem"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Panaji",
      "City": "N.i.o Donapaula"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Panaji",
      "City": "Panaji"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Ahmedabad",
      "City": "Ahmedabad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Amerli",
      "City": "Chalala"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Amerli",
      "City": "Jafrabad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Anand"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Anklav"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Umreth"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Banaskantha",
      "City": "Ambaji"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "vartej"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "Gandhi  nagar crpf"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "okha port"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "surajkaradi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "junagadh"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "kachchh",
      "City": "Gandhidam"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "kachchh",
      "City": "raper"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Kheda",
      "City": "Kheda"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Mahesana",
      "City": "vadnagar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Narmada",
      "City": "kevadiya"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Narmada",
      "City": "Rajpipla"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Narmada",
      "City": "Vedia"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Navsari",
      "City": "Mahuvar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nalanda",
      "City": "Harnaut"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Badh"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Vikram"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Purnea",
      "City": "Baisi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Purnea",
      "City": "Purnea"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saran",
      "City": "Taraiya"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Sheohar",
      "City": "Sheohar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Siwan",
      "City": "Darauli"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Siwan",
      "City": "Mairwa"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Supaul",
      "City": "Kisanpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Dhanha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Kesariya"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Loria"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Motihari"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Pipra"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Shikarpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Sikata"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Janjgir",
      "City": "Sakti"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Mahasamund",
      "City": "Kurud"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raigarh",
      "City": "Jashpur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raigarh",
      "City": "Pathalgaon"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raipur",
      "City": "Balodabazar"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raipur",
      "City": "Bhatapara"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raipur",
      "City": "Mandir Hasoud"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Sarangarh",
      "City": "Bhatgaon"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Sarangarh",
      "City": "Pamgarh"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Surguja",
      "City": "Ambikapur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Surguja",
      "City": "Lundra"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Surguja",
      "City": "Sitapur"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "East delhi",
      "City": "Gandhi nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "north delhi",
      "City": "sadar bazar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Bawana"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Bhalaswa Jahangir Pur"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "West delhi",
      "City": "Janakpuri"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Goa",
      "City": "Dramapur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Petlad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Dhola"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Palitana"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Talaja"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Dohad",
      "City": "Dohad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Dohad",
      "City": "Freelandgunj"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "Dahegam"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "Bedi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "Bhanvad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "bantwa"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Kheda",
      "City": "Mahudha"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Kheda",
      "City": "Nadiad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Mahesana",
      "City": "mahesana"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Rajkot",
      "City": "Jasdan"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Rajkot",
      "City": "Upleta"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Sabarkantha",
      "City": "Khedbrahma"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Sabarkantha",
      "City": "Talod"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Hajira Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Ukai"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Utran"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "Bajva"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "nandesari"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "nanakvada"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nalanda",
      "City": "Asthawan"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Bakhtiyarpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Masaudhi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Purnea",
      "City": "Kasba"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Rohtas",
      "City": "Bikramganj."
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saharsa",
      "City": "Simri Bakhtiyarpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saharsa",
      "City": "Sonbarsa."
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saran",
      "City": "Garkha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Siwan",
      "City": "Jiradei"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Siwan",
      "City": "Siwan"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Vaishali",
      "City": "Jandaha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Bagaha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Dhaka"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Nautan"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Bilaspur",
      "City": "Jarhagaon"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Janjgir",
      "City": "Sipat"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Mahasamund",
      "City": "Khaari"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Mahasamund",
      "City": "Saraipali"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raigarh",
      "City": "Raigarh"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Rajnandgon",
      "City": "Dongargarh"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "Central Delhi",
      "City": "Karol bagh"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "East delhi",
      "City": "Trilok puri"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "New delhi",
      "City": "Sarojini nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Sahibabad daulatpur"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Tri nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "South Delhi",
      "City": "Kasturba Nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "south west delhi",
      "City": "Nasipur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Amerli",
      "City": "Bagasara"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Amerli",
      "City": "Damnagar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Amerli",
      "City": "Savar Kundla"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Ode"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Banaskantha",
      "City": "Kanodar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bharuch",
      "City": "andada"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bharuch",
      "City": "Maktampur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Mahuva"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Dohad",
      "City": "Devgadbaria"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "Digvijaygram"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "Kalavad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "joshipura"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "keshod"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Kheda",
      "City": "Dakor"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Mahesana",
      "City": "visnagar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Navsari",
      "City": "Bilimora"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Sabarkantha",
      "City": "Himatnagar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Sabarkantha",
      "City": "Modasa"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Chhaprabatha"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "parvat"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "sachin"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Un"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "surendranagar",
      "City": "Dhrangadhra"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "surendranagar",
      "City": "surendranagar dudhrej"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Thedangs",
      "City": "The dangs"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "Ranoli"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "Vadodara"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "chala"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "valsad Ina"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nawada",
      "City": "Nawada"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Navsari",
      "City": "Vijalpor"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Rajkot",
      "City": "Jetpur Navagadh"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Sabarkantha",
      "City": "Malpur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Sabarkantha",
      "City": "Meghraj"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Chalthan"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Kosamba"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "sachin Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Sayan"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Vyara"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "Chhota udaipur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "Jawaharnagar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "padra"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "Atul"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "Dharampur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "pardi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "parnera"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "umbergoan"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nawada",
      "City": "Warsaliganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Paliganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Purnea",
      "City": "Banmankhi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Sitamarhi",
      "City": "Sitamarhi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Siwan",
      "City": "Basantpur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Siwan",
      "City": "MAHARAJGANJ"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Vaishali",
      "City": "Lalganj"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Vaishali",
      "City": "Mahnar"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Vaishali",
      "City": "Mahua"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Harsiddhi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Sugauli"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Bilaspur",
      "City": "Bilaspur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Durg",
      "City": "Durg"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Kanker",
      "City": "Bhanupratappur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Surguja",
      "City": "Surajpur"
    },
    {
      "Country": "INDIA",
      "State": "Dadra  and nagar haveli",
      "District": "Dadra  and nagar haveli",
      "City": "Naroli"
    },
    {
      "Country": "INDIA",
      "State": "Daman and Diu",
      "District": "Daman and Diu",
      "City": "Daman and Diu"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "Central Delhi",
      "City": "Ram nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "East delhi",
      "City": "Geeta colony"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "East delhi",
      "City": "Shahdara"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Shakur Basti"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "South Delhi",
      "City": "Hauz Khas"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "South Delhi",
      "City": "Mehrauli"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "south west delhi",
      "City": "Mahipalpur"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Goa",
      "City": "Chandor"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Goa",
      "City": "Mardol"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Boriavi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Vasna Borsad Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Banaskantha",
      "City": "Tharad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Botad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "Adalaj"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "kalol Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "mansa"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "motera"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "Dhrol"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "salaya"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "sikka"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "Kodinar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "Vanthali"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "manavadar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "una"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "kachchh",
      "City": "anjar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Mahesana",
      "City": "Unjha"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Navsari",
      "City": "Aantaliya"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Navsari",
      "City": "Devsar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Navsari",
      "City": "Jalalpore"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Panchmahals",
      "City": "Santrampur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Porbandar",
      "City": "Porbandar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Sabarkantha",
      "City": "Idar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Surat",
      "City": "Surat"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "Karachiya"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "karjan"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "nandesari Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "valsad"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nalanda",
      "City": "Rajgir"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Nawada",
      "City": "Hisua"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Patna",
      "City": "Patna(East)"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Rohtas",
      "City": "Nokha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saharsa",
      "City": "Saharsa"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Saran",
      "City": "Manjhi"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Sheikhpura",
      "City": "Sheikhpura"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Sitamarhi",
      "City": "Bathnaha"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Vaishali",
      "City": "Hajipur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "Vaishali",
      "City": "Raghopur"
    },
    {
      "Country": "INDIA",
      "State": "Bihar",
      "District": "West Champaran",
      "City": "Chanpatiya"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Bilaspur",
      "City": "Bilha"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Jashpur",
      "City": "Jashpur"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Mahasamund",
      "City": "Basna"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Mahasamund",
      "City": "Dhamtari"
    },
    {
      "Country": "INDIA",
      "State": "chhattisgarh",
      "District": "Raipur",
      "City": "Arang"
    },
    {
      "Country": "INDIA",
      "State": "Daman and Diu",
      "District": "Daman",
      "City": "Moti Daman"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "East delhi",
      "City": "Krishna nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Adrash Nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Model Town"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Narela"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "North west delhi",
      "City": "Wazir Pur"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "South Delhi",
      "City": "Badarpur"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "South Delhi",
      "City": "Jangpura"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "South Delhi",
      "City": "Kalkaji"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "south west delhi",
      "City": "Palam"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "West delhi",
      "City": "Madipur"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "West delhi",
      "City": "Moti nagar"
    },
    {
      "Country": "INDIA",
      "State": "Delhi",
      "District": "West delhi",
      "City": "Rajouri Garden"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Goa",
      "City": "Aldona"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Goa",
      "City": "Siroda"
    },
    {
      "Country": "INDIA",
      "State": "Goa",
      "District": "Goa",
      "City": "Velpai"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Amerli",
      "City": "Amerli"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Borsad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Karamsad"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Anand",
      "City": "Vallabh Vidyanagar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bharuch",
      "City": "Anklesvar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Bhavnagar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Gadhada"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Bhavnagar",
      "City": "Katpur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Dohad",
      "City": "Zalod"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Gandhinagar",
      "City": "Electonic estate gandhi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "Navghamghed"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Jamnagar",
      "City": "mithapur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "junagadh",
      "City": "visavadar"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "kachchh",
      "City": "Bhuj"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "kachchh",
      "City": "kandla"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Mahesana",
      "City": "Aambaliyasan"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Mahesana",
      "City": "kadi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Mahesana",
      "City": "vijapur"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Navsari",
      "City": "Gandevi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Panchmahals",
      "City": "Kalol"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Porbandar",
      "City": "Aadityana"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Porbandar",
      "City": "Chhaya"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Porbandar",
      "City": "Kutiyana"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Porbandar",
      "City": "Ranavav"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Rajkot",
      "City": "Rajkot"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Sabarkantha",
      "City": "Prantij"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "surendranagar",
      "City": "wadhwan"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "Dabhoi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "petro chemical complex"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "chanod"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "mogravadi"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "Vadodara",
      "City": "Gfsc complex Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "sarigam Ina"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "Vapi"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Bhiwani",
      "City": "dadri"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "ambala",
      "City": "Naggal"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "ambala",
      "City": "Naraingarh"
    },
    {
      "Country": "INDIA",
      "State": "Gujarat",
      "District": "valsad",
      "City": "Vapi Ina"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Faridabad",
      "City": "Faridabad"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Faridabad",
      "City": "Mewlamaharajpur"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Fatehabad",
      "City": "Bhattu kalan"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Fatehabad",
      "City": "Ratia"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Gurgaon",
      "City": "Ferozepur jhikra"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Jind",
      "City": "Jind"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Jind",
      "City": "Uchana kalan"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Kaithal",
      "City": "Kaithal"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Kaithal",
      "City": "Kalayat"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Karnal",
      "City": "indri"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Karnal",
      "City": "Nilokheri"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Mahendragarh",
      "City": "Mahendragarh"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Rewari",
      "City": "Jatusana"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Rewari",
      "City": "Kosli"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Rohtak",
      "City": "Kiloi"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Bilaspur",
      "City": "Geharwin"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Chamba",
      "City": "Bharmour"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kullu",
      "City": "Kullu"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Mandi",
      "City": "Dharampur"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Mandi",
      "City": "Karsog"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Shimla",
      "City": "Chopal"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Shimla",
      "City": "Rampur"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Shimla",
      "City": "simla"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Sirmour",
      "City": "Rainka"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Una",
      "City": "Santokhgarh"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Baramulla",
      "City": "Bandipora"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Baramulla",
      "City": "Baramulla"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Baramulla",
      "City": "Gulmarg"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Budgam",
      "City": "Chadoora"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Kargil",
      "City": "Kargil"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "kupwara",
      "City": "lolab"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Rajouri",
      "City": "Rajouri"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "srinagar",
      "City": "khanyar"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Udhampur",
      "City": "gulabgarh"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Udhampur",
      "City": "ramnagar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "bandhgaro"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "Gumia"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "baliari"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "bhowrah"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "bhuli"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "dhanbad"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "jaria khas"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "kailudih"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "kharkhari"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "mugma"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "pondar kanali"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "ambala",
      "City": "Mullana"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "sahnidih"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "sariadela"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "topchanchi"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Hissar",
      "City": "Adampur"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Godda",
      "City": "Godda"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Jhajjar",
      "City": "Jajjar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "lapanga"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Kaithal",
      "City": "Guhla"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "patratu"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "kurukshetra",
      "City": "pehowa"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Rohtak",
      "City": "Kalanaur"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "latehar",
      "City": "latehar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "palamu",
      "City": "daltonganj"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Yamunanagar",
      "City": "Yamunanagar"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Bilaspur",
      "City": "Bilaspur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "Hungund"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "alipura"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Bilaspur",
      "City": "Ghumarwin"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Nagrota"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "Bagapalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "balareddipalli"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Mandi",
      "City": "Darang"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Mandi",
      "City": "Gopalpur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "Bangalore"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "bydanur"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Shimla",
      "City": "Theog"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "carmelaram"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Sirmour",
      "City": "Pachhad"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Anantnag",
      "City": "Ananthnag"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "gavipuram extension"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "Gottikere"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Anantnag",
      "City": "Dooru"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "j c nagar"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Anantnag",
      "City": "Shangus"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Gurgaon",
      "City": "Taoru"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Doda",
      "City": "ramban"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "Gandhinagar"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "Marh"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Hissar",
      "City": "Ghirai"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "Samba"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Jhajjar",
      "City": "Badli"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Kathua",
      "City": "basohil"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Jind",
      "City": "Safidon"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Kaithal",
      "City": "paj"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "kupwara",
      "City": "handwara"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "kupwara",
      "City": "langate"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Karnal",
      "City": "jundla"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "leh",
      "City": "nobra"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Rohtak",
      "City": "Hassangarh"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "pulwama",
      "City": "Tral"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Sirsa",
      "City": "Dabwali"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Chamba",
      "City": "Bhattiyat"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "pulwama",
      "City": "wachi"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Rajouri",
      "City": "Kalakote"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Kangra"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Pragpur"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Rajouri",
      "City": "nowshera"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Lahaul-spiti",
      "City": "Lahaul-spiti"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "amlabad"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Anantnag",
      "City": "pahalgam"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "jena"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Baramulla",
      "City": "Pattan"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "basaria"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Doda",
      "City": "Bhaderwah"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "godhar"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Doda",
      "City": "Doda"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "jamadoba"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "jammu"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "Raipur domana"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Dumka",
      "City": "mihijham"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Kargil",
      "City": "Zanskar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gridih",
      "City": "dhanwar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gumla",
      "City": "raidih"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Kathua",
      "City": "billawar"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "pulwama",
      "City": "shopian"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "ara"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Bhiwani",
      "City": "loharu"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "kedla"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "srinagar",
      "City": "hazratbal"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Udhampur",
      "City": "Udhampur"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "kuju"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Faridabad",
      "City": "Palwal"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "bokaro"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "pakur",
      "City": "pakur"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "bokaro steel city"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Gurgaon",
      "City": "Sohna"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "palamu",
      "City": "barwadih"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Hissar",
      "City": "Narnaund"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "chandrapura"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "guledguddu"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "kurpania"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Jind",
      "City": "Narwana"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "sijhua"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "banaswadi"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Karnal",
      "City": "Assandh"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Deoghar",
      "City": "jasidih"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Karnal",
      "City": "gharaunda"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "c v raman nagar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "chhatatnar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "hebbal agricultural"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Karnal",
      "City": "Karnal"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "panchet"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "kurukshetra",
      "City": "shahabad"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "herohalli"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gridih",
      "City": "isri"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "nagarbhavi"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Rohtak",
      "City": "Meham"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gridih",
      "City": "paratdih"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Rohtak",
      "City": "Rohtak"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "pavagada"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gumla",
      "City": "bherno"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "ss ghat"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Sirsa",
      "City": "Darba kalan"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "gidi"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Sirsa",
      "City": "Sirsa"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "somanathapura"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "orla"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "venkatapura"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Chamba",
      "City": "Banikhet"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "koderma",
      "City": "koderma"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Hamirpur",
      "City": "Mewa"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Baijnath"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "churi"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "harmu housing society"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Dharmashala"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Guler"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "hehal"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "itki"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Thural"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "muri"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Solan",
      "City": "Solan"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Una",
      "City": "Chintpurni"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "seraikela",
      "City": "ichagarh"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "simdega",
      "City": "simdega"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Una",
      "City": "Una"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Anantnag",
      "City": "Devsar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "singhbhum",
      "City": "singhbhum"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Anantnag",
      "City": "Homeshalibug"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "iikal"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Anantnag",
      "City": "kokernag"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "mahalingpur"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Baramulla",
      "City": "Rafiabad"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "mudhol"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "adugodi"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Baramulla",
      "City": "Sopore"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "Chhamb"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "afs yelahanka"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "banashankari"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Rajouri",
      "City": "Darhal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "Bannerghata"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "srinagar",
      "City": "amirakadal"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Deoghar",
      "City": "madhupur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "byatarayanapura"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "chikka banavara"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "chandaur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "dodbelavangola"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "Egarkunr"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "hal li stage"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "gomoh"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "jharia"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "hosale thippasandra"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "kadugodi"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "kustai"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "rohraband"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "kengeri"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Garhwa",
      "City": "sinduria"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "kodigenahalli"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gumla",
      "City": "bishunpir"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "koramangala"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gumla",
      "City": "chainpur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "madiwal"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "dari"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "parthapalya"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "Hazaribag"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "r m vilas"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "saunda"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "sirka"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "topa"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "palamu",
      "City": "hussainabad"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "namkum"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "tati"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "sahibganj",
      "City": "rajmahal"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "seraikela",
      "City": "gamharia"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "seraikela",
      "City": "kuchai"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "seraikela",
      "City": "seraikela"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "Badami"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "bilgi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "jamkhandi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "bommasandra"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "darinayakanapalya"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "domlur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "govt.electric factory"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "gudibanda"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "kalludi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "kudur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "kumbalagodu"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "mahadevapura"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Bhiwani",
      "City": "tosham"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Faridabad",
      "City": "Hathin"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Gurgaon",
      "City": "Pataudi"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Jhajjar",
      "City": "Bahadurgarh"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Kaithal",
      "City": "pundri"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Mahendragarh",
      "City": "Narnaul"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Rewari",
      "City": "Rewari"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "sonepat",
      "City": "gohana"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Hamirpur",
      "City": "Nadaunta"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Jawalamukhi"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Shimla",
      "City": "Jubbal-kothkhai"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Shimla",
      "City": "Kasumpti"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Shimla",
      "City": "Kumarsain"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Una",
      "City": "Gagret"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Anantnag",
      "City": "Bijbehara"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Baramulla",
      "City": "uri"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Doda",
      "City": "banihal"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Doda",
      "City": "kishtwar"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "Nagrota"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Kathua",
      "City": "bani"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Kathua",
      "City": "Kathua"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "kupwara",
      "City": "karnah"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "kupwara",
      "City": "kupwara"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "poonch",
      "City": "surankote"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Deoghar",
      "City": "Deoghar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "angarpathar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "babua kalan"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "gobindpur"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "jorapokhar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "malkera"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "nagri kalan"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "tisra"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gumla",
      "City": "basia"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gumla",
      "City": "ghaghra"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gumla",
      "City": "palkot"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "okni No.li"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "khunti"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "seraikela",
      "City": "chandil"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "seraikela",
      "City": "nimdih"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "seraikela",
      "City": "rajnagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "terdal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "dasarahali"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "hebbagodi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "peena ss i"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "posettihalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "R t  nagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "srirampuram"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "thavarkere"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "tubagere"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "vasantha nagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "jalhalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "jayanagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "maruthi sevanagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "posalia"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "shivasetty garden"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "v g doddi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "rajaji nagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "sadasiva nagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "tholapalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "thondebhavi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "varthur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "richmond town"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "st.thomas town"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "tyagaraja nagar"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Bhiwani",
      "City": "Badhra"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "ambala",
      "City": "ambala"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Bhiwani",
      "City": "Bhiwani khera"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Bhiwani",
      "City": "Bhawani"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Bhiwani",
      "City": "mundhal khurd"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Faridabad",
      "City": "Hassanpur"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Fatehabad",
      "City": "Fathehabad"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Mahendragarh",
      "City": "Ateli"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Sirsa",
      "City": "Rori"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "sonepat",
      "City": "baroda"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "sonepat",
      "City": "Rohat"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "sonepat",
      "City": "sonepat"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Hamirpur",
      "City": "Hamirpur"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Shahpur"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kullu",
      "City": "Banjar"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Mandi",
      "City": "Chachiot"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Mandi",
      "City": "Jogindernagar"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Mandi",
      "City": "sundernagar"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Sirmour",
      "City": "Nahan"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Sirmour",
      "City": "Paonta-doon"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Sirmour",
      "City": "shillai"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Anantnag",
      "City": "kulgam"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Baramulla",
      "City": "Gurez"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Baramulla",
      "City": "Sangrama"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Baramulla",
      "City": "Sonawari"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Budgam",
      "City": "Badgam"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "Akhnoor"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "Bishnah"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "poonch",
      "City": "mendhar"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "srinagar",
      "City": "batmaloo"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "srinagar",
      "City": "habbakadal"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "srinagar",
      "City": "Sonawari"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "srinagar",
      "City": "zadibal"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Udhampur",
      "City": "chenani"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "Bermo"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "dugda"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "phusro"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Chatra",
      "City": "Chatra"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "bhagatdih"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "lakarka"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "maithon"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "mera"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "siuliban"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Dumka",
      "City": "basukinath"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gridih",
      "City": "gridih"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gumla",
      "City": "kimdara"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gumla",
      "City": "sisai"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "barhi"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "hesla"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "ramgarh cantonment"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "sewai"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "lohardaga",
      "City": "lohardaga"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "singhbhum",
      "City": "jamshedpur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "kerur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "basaveshwar nagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "charmrajpet"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "doddaballapur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "gandhi krishi vignan"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "hesaraghata"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "hkp road"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "hossur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "hunasamaranahalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "indira nagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "jp nagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "kothnur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "manchanehalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "mathikere"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Faridabad",
      "City": "Ballabgarh"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Fatehabad",
      "City": "Tohana"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Gurgaon",
      "City": "Gurgaon"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Gurgaon",
      "City": "Nuh"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Hissar",
      "City": "Hansi"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Hissar",
      "City": "Barwala"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Panipat",
      "City": "Samalkha"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Hissar",
      "City": "Hissar"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Rewari",
      "City": "Bawal"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Jhajjar",
      "City": "Beri"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Jhajjar",
      "City": "Salhawas"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "sonepat",
      "City": "kailana"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Jind",
      "City": "Julana"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Chamba",
      "City": "Chamba"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Jind",
      "City": "Rajond"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Chamba",
      "City": "Rajnagar"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "kurukshetra",
      "City": "Thanesar"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Hamirpur",
      "City": "Bamsan"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Rajgir"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Panchkula",
      "City": "Panchkula"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Panipat",
      "City": "Naultha"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kinnaur",
      "City": "Kinnaur"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kullu",
      "City": "Ani"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Panipat",
      "City": "Panipat"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "Sirsa",
      "City": "Ellenabad"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Mandi",
      "City": "Mandi"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Shimla",
      "City": "Rohru"
    },
    {
      "Country": "INDIA",
      "State": "Haryana",
      "District": "sonepat",
      "City": "rai"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Una",
      "City": "Kultlehar"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Bilaspur",
      "City": "Kotkehloor"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Hamirpur",
      "City": "Nandaun"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Anantnag",
      "City": "noorabad"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Budgam",
      "City": "Khansahib"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Jaswan"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Palampur"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Doda",
      "City": "Inderwal"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "Suchetgarh"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Kangra",
      "City": "Sulah"
    },
    {
      "Country": "INDIA",
      "State": "Himachal Pradesh",
      "District": "Mandi",
      "City": "Balh"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "Vijaypur"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Budgam",
      "City": "beerwah"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "leh",
      "City": "leh"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Budgam",
      "City": "Char-i-sharief"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "poonch",
      "City": "poonch haveli"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "jammu",
      "City": "R.s pura"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "pulwama",
      "City": "pulwama"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Kathua",
      "City": "Hiranagar"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "srinagar",
      "City": "ganderbal"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "srinagar",
      "City": "kangan"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "pulwama",
      "City": "Rajpora"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "srinagar",
      "City": "Idgah"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Udhampur",
      "City": "gool arnas"
    },
    {
      "Country": "INDIA",
      "State": "Jammu and Kashmir",
      "District": "Udhampur",
      "City": "reasi"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "bhojudih"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "chhotaputki"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "chas"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "chirkunda"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "jaridih bazar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "bokaro",
      "City": "Tenu-dum-cum kathha"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "kenduadih"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "dhaunsar"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "loyabad"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "dumarkunda"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "nirsa"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "katras"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "pathardih"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gumla",
      "City": "dumri"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "marma"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "sijua"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "gumla",
      "City": "gumla"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "balkundra"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "dhanbad",
      "City": "sindri"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Dumka",
      "City": "Dumka"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "meru"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "palawa"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Garhwa",
      "City": "Garhwa"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "barkakana"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "bundu"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "barughutu"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "dhurwa"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Hazaribag",
      "City": "religara alias pachhiari"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "kanke"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "Jamtara",
      "City": "Jamtara"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "rabgavi banhatti"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "palamu",
      "City": "deorikalan"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "agaram"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "hatia railway colony"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "anekal"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "khelari"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "ranchi",
      "City": "ranchi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "basavangudi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "benson town"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "sahibganj",
      "City": "sahibganj"
    },
    {
      "Country": "INDIA",
      "State": "Jharkhand",
      "District": "seraikela",
      "City": "kharsawan"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "bommanahalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "Dooravani nagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bagalkot",
      "City": "Bagalkot"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "chickpet"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "Electronics city"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "kothanur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "chikpet"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "doddakalasandra"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "krishnarajapura"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "fraser town"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "marathahalli colony"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "gauribidanur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "nayandahalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "gulur (klr)"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "pattanagere"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "konankunte"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "sheshadripuram"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "kotagudda"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "magadi road"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "subramanyapura"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "mahalakshmipuram"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "malleswaram"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "melekot"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "nandini extension"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "sailapur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "vidurswatha"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "uttarahalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "wilson garden"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "vimanapura"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "viswaneedam"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "belgaum"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "chikodi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "kangrali"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "raybag"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "sadalgi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "bellary"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "kamalapuram"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bidar",
      "City": "chitgoppa"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bijapur",
      "City": "muddebihal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gadag",
      "City": "mulgund"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gadag",
      "City": "naregal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "wadi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "hassan",
      "City": "channaarayanpattana"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "haveri",
      "City": "kodiyal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "haveri",
      "City": "savanur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kodagu",
      "City": "kushalnagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "sidlaghatta"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "koppal",
      "City": "gangawati"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "koppal",
      "City": "kushtagi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "raichur",
      "City": "manvi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "ramanagara",
      "City": "ramanagara"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "shimoga",
      "City": "bhadravati"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "tumkur",
      "City": "adityapatna"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "udupi",
      "City": "saligram"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Uttar kannada",
      "City": "Uttar kannada"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Alappuzha",
      "City": "Kayamkulam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "Kunnathnad"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "narakal"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "Piravom"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kannur",
      "City": "azhicode"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "mynagappally"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "Kaduthuruthy"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "kanjirappally"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "vazhoor"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "Malappuram"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "mankada"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "tanur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "Tirur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Pathanamthitta",
      "City": "aranmula"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Pathanamthitta",
      "City": "konni"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Pathanamthitta",
      "City": "ranni"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Pathanamthitta",
      "City": "thiruvalla"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "nedumangad"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "vamanapuram"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "cherpu"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Wayanad",
      "City": "sulthan's battery"
    },
    {
      "Country": "INDIA",
      "State": "lakshadweep",
      "District": "lakshadweep",
      "City": "minicoy"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "badwani",
      "City": "anjad"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "balaghat",
      "City": "wara seoni"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "betul",
      "City": "bhainsdehi"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "bhind",
      "City": "bhind"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "bhopal",
      "City": "bhopal north"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "damoh",
      "City": "hatta"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "Dewas",
      "City": "bagli"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "Dewas",
      "City": "Dewas"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "Dewas",
      "City": "khategaon"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "dhar",
      "City": "kukshi"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "gwalior",
      "City": "gwalior"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "gwalior",
      "City": "lashkar east"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "indore",
      "City": "sawer"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "katna",
      "City": "murwara"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "hagaribommanahalli"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khargone",
      "City": "khargone"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "mandsaur",
      "City": "sitamau"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "morena",
      "City": "ambah"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bidar",
      "City": "bidar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "chitradurga",
      "City": "challakere"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "chitradurga",
      "City": "molakalmuru"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "dharwad",
      "City": "annigere"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gadag",
      "City": "lakshmeshwar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gadag",
      "City": "nargund"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gadag",
      "City": "ron"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "afzalpur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "gulbarga"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "kurgunta"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "shahabad"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "haveri",
      "City": "bankapura"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "haveri",
      "City": "byadgi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "haveri",
      "City": "raniberrur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "bangarapet"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "gauribidanur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "kolar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "mulbagal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "koppal",
      "City": "koppal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "koppal",
      "City": "yelbarga"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mandya",
      "City": "melavalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mysore",
      "City": "hebbalu"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mysore",
      "City": "mysore"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mysore",
      "City": "piriyapatna"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "tumkur",
      "City": "chiknayankanhalli"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Alappuzha",
      "City": "alleppey"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Alappuzha",
      "City": "Cherthala"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "alwaye"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "kochi"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Idukki",
      "City": "Peermade"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kannur",
      "City": "irikkur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kannur",
      "City": "kuthuparamba"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "pathanapuram"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "quilon"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "poonjar"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "vaikom"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "balusseri"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "calicut"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "Kunnamangalam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "areekode"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "Perinthalmanna"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "tirurangadi"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "kazhakuttam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "varkala"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "nattika"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "ollur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "badwani",
      "City": "badwani"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "badwani",
      "City": "rajpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "balaghat",
      "City": "katngi"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "balaghat",
      "City": "paraswada"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "betul",
      "City": "ghora dongri"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "bhopal",
      "City": "govindpura"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhindwara",
      "City": "amarwara"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhindwara",
      "City": "chhindwara"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "Dewas",
      "City": "sonkatch"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "dhar",
      "City": "sardarpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "guna",
      "City": "ashoknagar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "guna",
      "City": "chachura"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "gwalior",
      "City": "gird"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "gwalior",
      "City": "lashkar west"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "hoshangabad",
      "City": "seoni-malwa"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jabalpur",
      "City": "jab.cantonment"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jabalpur",
      "City": "patan"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "athni"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bijapur",
      "City": "Chamrajnagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "dharwad",
      "City": "kundgol"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "shorapur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "hassan",
      "City": "sakleshpur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mysore",
      "City": "nanjangud"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "tumkur",
      "City": "kunigal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "tumkur",
      "City": "tiptur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "tumkur",
      "City": "tumkur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "udupi",
      "City": "kundapura"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "aroor"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "Vadakkekara"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Idukki",
      "City": "devicolam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kasaragod",
      "City": "hosdrug"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kasaragod",
      "City": "Kasaragod"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kasaragod",
      "City": "Trikkarpur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "Karunagappalli"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "neduvathur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "erumeli"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "Ettumanoor"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "mararikulam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "Puthuppally"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "Beypore"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "nadapuram"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "perambra"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "Thiruvambady"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "Kondotty"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "kuttippuram"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "Nilambur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "kollengode"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "palghat"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Pathanamthitta",
      "City": "kozhencherry"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "attingal"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "nemom"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "chelakara"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "guruvayoor"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "kodakara"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "trichur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Wayanad",
      "City": "kalpetta"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Wayanad",
      "City": "north wayanad"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "badwani",
      "City": "sendhwa"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "balaghat",
      "City": "baihar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "balaghat",
      "City": "kirnapur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "balaghat",
      "City": "balaghat"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "bhind",
      "City": "attair"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhatapur",
      "City": "bijawar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhindwara",
      "City": "damua"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "dhar",
      "City": "badnawar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "dhar",
      "City": "dharmapuri"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "dhar",
      "City": "manawar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "dindori",
      "City": "bajag"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "guna",
      "City": "raghogarh"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "hoshangabad",
      "City": "hoshangabad"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "hoshangabad",
      "City": "piparia"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "indore",
      "City": "indore"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jabalpur",
      "City": "jab.central"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jabalpur",
      "City": "jab.east"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jhabua",
      "City": "petalwad"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jhabua",
      "City": "thandla"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "katna",
      "City": "vijairaghogarh"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khandwa",
      "City": "burhanpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "mandsaur",
      "City": "mandsaur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "neemuch",
      "City": "jawad"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "neemuch",
      "City": "panna"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "vijay nagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "whitefield"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "kudchi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "mudalgi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "ramdurg"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "sankeshwar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "hoovina hadagalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "sandur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bidar",
      "City": "aurad"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bijapur",
      "City": "sindgi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "dharwad",
      "City": "navalgund"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "hassan",
      "City": "hassan"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "haveri",
      "City": "hirekurur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kodagu",
      "City": "madikere"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "robertson pet"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "koppal",
      "City": "munirabad project area"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mandya",
      "City": "krishnarajpet"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mysore",
      "City": "bannur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mysore",
      "City": "bhogadi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "ramanagara",
      "City": "channapatna"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "ramanagara",
      "City": "magadi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "tumkur",
      "City": "gubbi"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Alappuzha",
      "City": "Chengannur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Alappuzha",
      "City": "kuttanad"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "Muvattupuzha"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "parur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "Trippunithura"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kannur",
      "City": "cannanore"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kannur",
      "City": "peravoor"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "chavara"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "Kottarakkara"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "kunnathur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "Changanacherry"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "Kottakkal"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "Manjeri"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "OTTPPALAM"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Pathanamthitta",
      "City": "adoor"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "ariyanad"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "kovalam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "irinjalakuda"
    },
    {
      "Country": "INDIA",
      "State": "lakshadweep",
      "District": "kavaratti",
      "City": "kavaratti"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "balaghat",
      "City": "lanji"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "betul",
      "City": "amla"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "betul",
      "City": "masod"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "bhind",
      "City": "ron"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhatapur",
      "City": "malehra"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhindwara",
      "City": "chaurai"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhindwara",
      "City": "pandhurna"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "datia",
      "City": "seondha"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "dhar",
      "City": "dhar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "guna",
      "City": "shahdora"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "indore",
      "City": "depalpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "indore",
      "City": "mhow"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jabalpur",
      "City": "majholi"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jhabua",
      "City": "jobat"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "mandla",
      "City": "bichhia"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "mandla",
      "City": "niwas"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "morena",
      "City": "sumawali"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "narsimhpur",
      "City": "neemuch"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rajgarh",
      "City": "rajgarh"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ratlam",
      "City": "a lot"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "panna",
      "City": "powai"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "raisen",
      "City": "sanchi"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rewa",
      "City": "deotalab"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rewa",
      "City": "simour"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "katna",
      "City": "badwara"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khandwa",
      "City": "nepanagar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khandwa",
      "City": "shahpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khargone",
      "City": "dhulkot"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "mandla",
      "City": "nainpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "morena",
      "City": "dimni"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rajgarh",
      "City": "narsingarh"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rajgarh",
      "City": "sarangpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rewa",
      "City": "rewa"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "yeshwanthpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ratlam",
      "City": "jaora"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ratlam",
      "City": "ratlam rural"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rewa",
      "City": "gurh"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "yelahanka"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "khanapur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "saundatti -yellamma"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "kutturu"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "kudligi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "tekkalakota"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bidar",
      "City": "basavakalyan"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bijapur",
      "City": "Indi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "dharwad",
      "City": "hubli-dharwad"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gadag",
      "City": "shirhatti"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "hassan",
      "City": "arsikere"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "hassan",
      "City": "belur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "hassan",
      "City": "holenarsipur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kodagu",
      "City": "gonikappul"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kodagu",
      "City": "somvarpet"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mysore",
      "City": "heggadedevanekote"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "raichur",
      "City": "mudgal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "shimoga",
      "City": "sagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "shimoga",
      "City": "sorab"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "tumkur",
      "City": "koratagere"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Alappuzha",
      "City": "Haripad"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Idukki",
      "City": "Idukki"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Idukki",
      "City": "Udumbanchola"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kannur",
      "City": "edakkad"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "Kottayam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "Ponnani"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "wandoor"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "malampuzha"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "mala"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "manalur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "damoh",
      "City": "damoh"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "datia",
      "City": "datia"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "dindori",
      "City": "dindori"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "hoshangabad",
      "City": "itarsi"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jabalpur",
      "City": "bargi"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jabalpur",
      "City": "jab.west"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jabalpur",
      "City": "panagar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jabalpur",
      "City": "sihora"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khandwa",
      "City": "nimarkhedi"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khandwa",
      "City": "pandhana"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khargone",
      "City": "barwaha"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "mandsaur",
      "City": "garoth"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "morena",
      "City": "sabalgarh"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rajgarh",
      "City": "biaora"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rewa",
      "City": "mangawan"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "bail hongal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "virgonagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "hindalgi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "hukeri"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "nipani"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "donimallai township"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "kampli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bidar",
      "City": "bhalki"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Dakshin kannada",
      "City": "Dakshin kannada"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "dharwad",
      "City": "kalghatgi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "jevargi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "sedam"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "yadgir"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "hassan",
      "City": "arkalgud"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "hassan",
      "City": "sathyamangla"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "haveri",
      "City": "shiggaon"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kodagu",
      "City": "virajpet"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mandya",
      "City": "pandavapura"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "raichur",
      "City": "devadurga"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "raichur",
      "City": "lingsugur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "shimoga",
      "City": "shikarpur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "shimoga",
      "City": "shimoga"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "shimoga",
      "City": "siralkoppa"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "shimoga",
      "City": "tirthahalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "tumkur",
      "City": "madhugiri"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "tumkur",
      "City": "pavangada"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "tumkur",
      "City": "sira"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Alappuzha",
      "City": "Ambalappuzha"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Alappuzha",
      "City": "pandalam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "palluruthy"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kannur",
      "City": "Payyannur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kasaragod",
      "City": "manjeswar"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "Chathannoor"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "Kundara"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "badagara"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "quilandy"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "MANNARKKAD"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "pattambi"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "thrithala"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "kilimanoor"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "neyyattinkara"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "parassala"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "kunnamkulam"
    },
    {
      "Country": "INDIA",
      "State": "lakshadweep",
      "District": "lakshadweep",
      "City": "amini"
    },
    {
      "Country": "INDIA",
      "State": "lakshadweep",
      "District": "lakshadweep",
      "City": "kavaratti"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "balaghat",
      "City": "khairlanjee"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "betul",
      "City": "betul"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "bhind",
      "City": "mehgaon"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "bhopal",
      "City": "berasia"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "bhopal",
      "City": "bhopal south"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhatapur",
      "City": "chhatapur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhindwara",
      "City": "parasia"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "damoh",
      "City": "nohata"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "damoh",
      "City": "patheria"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "datia",
      "City": "bhander"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "dindori",
      "City": "shahpura"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "guna",
      "City": "mungaoli"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "gwalior",
      "City": "morar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "harda",
      "City": "timarni"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jhabua",
      "City": "alirajpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khandwa",
      "City": "harsud"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "mandla",
      "City": "mandla"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "morena",
      "City": "joura"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "morena",
      "City": "morena"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "raisen",
      "City": "udaipura"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rewa",
      "City": "mouganj"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bangalore",
      "City": "vivek nagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "gokak"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "londa"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "hospet"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bellary",
      "City": "siruguppa"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bijapur",
      "City": "basavana bagevadi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bijapur",
      "City": "Bijapur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "chikmaglur",
      "City": "chikmaglur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "chitradurga",
      "City": "Hiriyur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gadag",
      "City": "gadag-betigeri"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gadag",
      "City": "gajendragarh"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "aland"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "chincholli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "gurmatkal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "hassan",
      "City": "alur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "haveri",
      "City": "haveri"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "bagepalli"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "malur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mandya",
      "City": "krishnarajasagara"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mandya",
      "City": "mandya"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mandya",
      "City": "nagamangala"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mandya",
      "City": "srirangapattana"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mysore",
      "City": "krishnarajanagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "raichur",
      "City": "hatti"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "raichur",
      "City": "raichur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "raichur",
      "City": "saktinagar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "shimoga",
      "City": "hosanagara"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "shimoga",
      "City": "jog falls"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "tumkur",
      "City": "turuvekere"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "udupi",
      "City": "karkal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "udupi",
      "City": "mallar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "udupi",
      "City": "udupi"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Alappuzha",
      "City": "Mavelikkara"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "Ankamali"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "Kothamangalam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kannur",
      "City": "Mattanur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kannur",
      "City": "peringalam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kasaragod",
      "City": "udma"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "chadayamangalam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "eravipuram"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kollam",
      "City": "Punaloor"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kottayam",
      "City": "palai"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "koduvally"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "narikkuni"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Malappuram",
      "City": "vengara"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "alathur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "CHITTUR"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "sreekrishnapuram"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Pathanamthitta",
      "City": "kallooppara"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Pathanamthitta",
      "City": "Pathanamthitta"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thiruvananthapuram",
      "City": "trivandrum"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "chalakudi"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "wadakkancherry"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "betul",
      "City": "multai"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "bhind",
      "City": "gohad"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "bhind",
      "City": "lahar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhindwara",
      "City": "sausar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khandwa",
      "City": "khandwa"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "mandsaur",
      "City": "suwasara"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "narsimhpur",
      "City": "bohani"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "narsimhpur",
      "City": "gadarwara"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "narsimhpur",
      "City": "gotegaon"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "narsimhpur",
      "City": "narsimhpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "panna",
      "City": "amanganj"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "belgaum",
      "City": "konnur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "bidar",
      "City": "homnabad"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Bijapur",
      "City": "Talikota"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "chitradurga",
      "City": "chitradurga"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "chitradurga",
      "City": "holalkere"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "chitradurga",
      "City": "hosdurga"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "Davangere",
      "City": "Davangere"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "dharwad",
      "City": "alnavar"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gadag",
      "City": "mundargi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "bhimarayanagudi"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "chitapur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "gulbarga",
      "City": "shahpur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "haveri",
      "City": "hangal"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "chik ballapur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "chintamani"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "gudibanda"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "kolar",
      "City": "srinivasapur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mandya",
      "City": "maddur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mysore",
      "City": "hunsur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "mysore",
      "City": "thirumankundal-narsipur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "raichur",
      "City": "sindhnur"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "ramanagara",
      "City": "kanakapura"
    },
    {
      "Country": "INDIA",
      "State": "karnataka",
      "District": "udupi",
      "City": "yenagudde"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "Ernakulam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "mattancherry"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Ernakulam",
      "City": "Perumbavoor"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Idukki",
      "City": "Thodupuzha"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kannur",
      "City": "Taliparamba"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kannur",
      "City": "tellicherry"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Kozhikode",
      "City": "meppayur"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "agali"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Palakkad",
      "City": "coyalmannam"
    },
    {
      "Country": "INDIA",
      "State": "Kerala",
      "District": "Thrissur",
      "City": "kodungallur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhatapur",
      "City": "chandla"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhatapur",
      "City": "maharjpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "chhindwara",
      "City": "jamai"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "Dewas",
      "City": "hatpiplya"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "guna",
      "City": "guna"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "gwalior",
      "City": "dabra"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "harda",
      "City": "harda"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "jhabua",
      "City": "jhabua"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "katna",
      "City": "bahoriband"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khargone",
      "City": "bhikangaon"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khargone",
      "City": "kasrawad"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "khargone",
      "City": "maheshwar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "neemuch",
      "City": "manasa"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "raisen",
      "City": "bareli"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "raisen",
      "City": "bhojpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ratlam",
      "City": "ratlam town"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rajgarh",
      "City": "khilchipur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ratlam",
      "City": "sailana"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "rewa",
      "City": "teonthar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "satna",
      "City": "chitrkoot"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sagar",
      "City": "khurai"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "satna",
      "City": "amarpatan"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shajapur",
      "City": "susner"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shivpuri",
      "City": "pichhore"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shivpuri",
      "City": "shivpuri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "karjat"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "sheogaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "shrirampur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ambegaon",
      "City": "ambegaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "amravati",
      "City": "badnera"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "amravati",
      "City": "nandgaon khandeshwar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ashthi",
      "City": "ashthi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ausa",
      "City": "ausa"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bhandara",
      "City": "adyar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bhokar",
      "City": "bhokar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "buldhana",
      "City": "malkapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "chandrapur",
      "City": "rajura"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "gandhinglaj",
      "City": "gandhinglaj"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "chopda"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "jalgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jintoor",
      "City": "jintoor"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "karad",
      "City": "karad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kasba peth",
      "City": "kasba peth"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "Ichalkaranji"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "panhale"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "radhanagari"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "shahuwadi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "latur",
      "City": "ausa"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shivpuri",
      "City": "karera"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "latur",
      "City": "her"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sidhi",
      "City": "dhauhani"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "maval",
      "City": "maval"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "borivali"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ujjain",
      "City": "khachrod"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "Santacruz"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nagpur",
      "City": "kamthi"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "vidisha",
      "City": "vidisha"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nanded",
      "City": "hadgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "shrigonda"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nandurbar",
      "City": "akrani"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "akola",
      "City": "karanja"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "dindori"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "akola",
      "City": "mangrulpir"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "nandgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "akola",
      "City": "patur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "sinnar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "amgaon",
      "City": "amgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "baramati",
      "City": "baramati"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "surgana"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "parbhani",
      "City": "gangakhed"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "beed",
      "City": "chausala"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "partur",
      "City": "partur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "buldhana",
      "City": "khamgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "parvati",
      "City": "parvati"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "chandrapur",
      "City": "chandrapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "erandwana"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "chausala",
      "City": "chausala"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "hinjewadi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "chimur",
      "City": "chimur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "indapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "dchiroli",
      "City": "dchiroli"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sehore",
      "City": "ichhawar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "junnar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "dhule",
      "City": "sakri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "sinhagad road"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shahdol",
      "City": "suhagpur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "gadhchiroli",
      "City": "dchiroli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "raigad",
      "City": "khalapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "gadhchiroli",
      "City": "sironcha"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shivpuri",
      "City": "pohri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ratnagiri",
      "City": "lanja"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sidhi",
      "City": "beosar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "georai",
      "City": "georai"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ratnagiri",
      "City": "mandangad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "gondiya",
      "City": "goregaon"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sidhi",
      "City": "gopadbanas"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "satara",
      "City": "patan"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "tikamgarh",
      "City": "jatara"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "goregaon",
      "City": "goregaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "shirur",
      "City": "shirur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "chalisgaon"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ujjain",
      "City": "badnagar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sindhudurg",
      "City": "chiplun"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ujjain",
      "City": "tarana"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "parola"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sindhudurg",
      "City": "rajapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalna",
      "City": "jalna"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "ahemadnagar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sindhudurg",
      "City": "ratnagiri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "rahuri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kagal",
      "City": "kagal"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "ambarnath"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "karvir",
      "City": "karvir"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "akola",
      "City": "balapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "jawhar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "akola",
      "City": "medshi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "kagal"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "thane"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "latur",
      "City": "ahmedpur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "aurangabad",
      "City": "vaijapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "tirora",
      "City": "tirora"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "beed",
      "City": "ashthi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mohol",
      "City": "mohol"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "wani",
      "City": "wani"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mudkhed",
      "City": "mudkhed"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "beed",
      "City": "georai"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "wardha",
      "City": "hinganghat"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "beed",
      "City": "renapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mukhed",
      "City": "mukhed"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "yavatmal",
      "City": "digras"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "bhandup"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bhandara",
      "City": "lakhandur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "yavatmal",
      "City": "gaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bhilwadi wangi",
      "City": "bhilwadi wangi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "goregaon"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal east",
      "City": "andro"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "kurla"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bhor",
      "City": "bhor"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "hingoli",
      "City": "hingoli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nanded",
      "City": "nanded"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "indapur",
      "City": "indapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "igatpuri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "amalner"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "osmanabad",
      "City": "omerga"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "junnar",
      "City": "junnar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "patan",
      "City": "patan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kaij",
      "City": "kaij"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "guru nanak nagar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kalamnuri",
      "City": "kalamnuri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "kothrud"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kavathe mahankal",
      "City": "kavathe mahankal"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "shivajinagar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "khatav",
      "City": "khatav"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangli",
      "City": "bhilwadi wangi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "khed alandi",
      "City": "khed alandi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "satara",
      "City": "khatav"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "chandgad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "satara",
      "City": "koregaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "koregaon",
      "City": "koregaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "satara",
      "City": "satara"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mangalwedha",
      "City": "mangalwedha"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sheogaon",
      "City": "sheogaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "manjilegaon",
      "City": "manjilegaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sindhudurg",
      "City": "sangameshwar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "ghatkopar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sironcha",
      "City": "sironcha"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "malad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "solapur",
      "City": "mohol"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nandurbar",
      "City": "nandurbar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "solapur",
      "City": "sangole"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "nashik"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "solapur",
      "City": "solapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "omerga",
      "City": "omerga"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "tasgaon",
      "City": "tasgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "osmanabad",
      "City": "kalamb"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "bhivandi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "osmanabad",
      "City": "tulijapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "tuljapur",
      "City": "tuljapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pathri",
      "City": "pathri"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal east",
      "City": "keirao"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "aundh"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "baner"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "chakan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "daund"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "dhanori"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "ganesh peth"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "gokhale nagar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "gultekdi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "hadapser"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "havelli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "kalewadi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "kondhwa"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "shirur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "raigad",
      "City": "mangaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "raigad",
      "City": "panvel"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "rajura",
      "City": "rajura"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangamner",
      "City": "sangamner"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangli",
      "City": "kavathe mahankal"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangli",
      "City": "sangli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "satara",
      "City": "man"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "satara",
      "City": "wai"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "shirol",
      "City": "shirol"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "shrigonda",
      "City": "shrigonda"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sindhudurg",
      "City": "vengurla"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "vaijapur",
      "City": "vaijapur"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "bishnupur",
      "City": "bishnupur"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "bishnupur",
      "City": "kumbi"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "chandel",
      "City": "tengnoupal"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "churachandpur",
      "City": "henglep"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "churachandpur",
      "City": "singhat"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sagar",
      "City": "rehli"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sagar",
      "City": "surkhi"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "satna",
      "City": "raigaon"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sehore",
      "City": "budhni"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "seoni",
      "City": "barghat"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shahdol",
      "City": "annupur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shahdol",
      "City": "jai singh nagar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sidhi",
      "City": "hurahat"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "tikamgarh",
      "City": "niwari"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ujjain",
      "City": "ujjain north"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "pathardi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "akola",
      "City": "akola"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "akola",
      "City": "borgaon  manju"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "akola",
      "City": "murtizapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "armori",
      "City": "armori"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "aurangabad",
      "City": "aurangabad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "aurangabad",
      "City": "gangapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bhavani peth",
      "City": "bhavani peth"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "buldhana",
      "City": "sindkhed raja"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "chandrapur",
      "City": "bhadravati"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "darwha",
      "City": "darwha"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "Haveli",
      "City": "Haveli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "her",
      "City": "her"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "hingoli",
      "City": "kalamnuri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "bhusaval"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "bhadgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kandhar",
      "City": "kandhar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "madha",
      "City": "madha"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mulshi",
      "City": "mulshi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "amboli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "vile parle"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nagpur",
      "City": "savner"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nandurbar",
      "City": "navapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nandurbar",
      "City": "talode"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "baglan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "devlali"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "kalyan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "malegaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "north solapur",
      "City": "north solapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "parner",
      "City": "parner"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "cavthan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "dange chawk"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "kasba peth"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "khed alandi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "wanowrie"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "rahuri",
      "City": "rahuri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "raigad",
      "City": "alibag"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sakoli",
      "City": "sakoli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangrul",
      "City": "sangrul"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "satara",
      "City": "phaltan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sillod",
      "City": "sillod"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sindhudurg",
      "City": "guhagar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "solapur",
      "City": "karmala"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "belapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "palghar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "tumsar",
      "City": "tumsar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "wardha",
      "City": "arvi"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "churachandpur",
      "City": "tipaimukh"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "seoni",
      "City": "seoni"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shahdol",
      "City": "beohari"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shajapur",
      "City": "agar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sidhi",
      "City": "sidhi"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ujjain",
      "City": "mahidpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "vidisha",
      "City": "kurwai"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "parner"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "sangamner"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahmedpur",
      "City": "ahmedpur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "akola",
      "City": "washim"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "amravati",
      "City": "achalpur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "basmath",
      "City": "basmath"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "beed",
      "City": "manjlegaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bhokardan",
      "City": "bhokardan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "biloli",
      "City": "biloli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "dhule",
      "City": "sindkheda"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "gondiya",
      "City": "tirora"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "hingoli",
      "City": "basmath"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "edlabad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kalamb",
      "City": "kalamb"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kelapur",
      "City": "kelapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kinwat",
      "City": "kinwat"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "gandhinglaj"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "karvir"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "kolhapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "sangrul"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "shirol"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kolhapur",
      "City": "vadgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "kandivali"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "vandre"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nagpur",
      "City": "katol"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nagpur",
      "City": "ramtek"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nanded",
      "City": "mudkhed"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "chandwad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "yewla"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "panhale",
      "City": "panhale"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "parbhani",
      "City": "parbhani"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "parbhani",
      "City": "singnapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pathardi",
      "City": "pathardi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "ambegaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "baramati"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "bopodi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "maval"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "narayan peth"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "pimpri chinchwad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "purandhar",
      "City": "purandhar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "raigad",
      "City": "shrivardhan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ratnagiri",
      "City": "chiplun"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ratnagiri",
      "City": "guhagar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ratnagiri",
      "City": "ratnagiri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "shivajinagar",
      "City": "shivajinagar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sindhudurg",
      "City": "dapoli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sindhudurg",
      "City": "devgad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "solapur",
      "City": "pandharpur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "kalyan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "wai",
      "City": "wai"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "yavatmal",
      "City": "darwha"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "bishnupur",
      "City": "moirang"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "bishnupur",
      "City": "oinam"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "churachandpur",
      "City": "saikot"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sagar",
      "City": "bina"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sagar",
      "City": "sagar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "satna",
      "City": "rampur baghelan"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sehore",
      "City": "ashta"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shahdol",
      "City": "pushprajgarh"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "tikamgarh",
      "City": "khargapur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "vidisha",
      "City": "basoda"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "vidisha",
      "City": "shanshbad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "adyar",
      "City": "adyar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "shirdi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ambad",
      "City": "ambad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "amravati",
      "City": "amravathi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "amravati",
      "City": "chandur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "amravati",
      "City": "walgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "aurangabad",
      "City": "kannad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "aurangabad",
      "City": "sillod"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "barshi",
      "City": "barshi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "beed",
      "City": "kaij"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bhandara",
      "City": "sakoli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "buldhana",
      "City": "jalamb"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "buldhana",
      "City": "mehkar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "chandrapur",
      "City": "saoli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "dhule",
      "City": "shirpur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "digras",
      "City": "digras"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "gaon",
      "City": "gaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "erandol"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "pachora"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "raver"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jaoli",
      "City": "jaoli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kannad",
      "City": "kannad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "karmala",
      "City": "karmala"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "latur",
      "City": "latur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "miraj",
      "City": "miraj"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "Andheri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nanded",
      "City": "bhoker"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nanded",
      "City": "biloli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nanded",
      "City": "kandhar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nanded",
      "City": "kinwat"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nilanga",
      "City": "nilanga"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pandharpur",
      "City": "pandharpur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "parbhani",
      "City": "jintoor"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "parbhani",
      "City": "pathri"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "bhavani peth"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "dhankawadi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "mulshi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "parvati"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "pune cantonment"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "purandhar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "wakad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "radhanagari",
      "City": "radhanagari"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ratnagiri",
      "City": "dapoli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ratnagiri",
      "City": "sangameshwar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangli",
      "City": "shirala"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "saoli",
      "City": "saoli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "satara",
      "City": "jaoli"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "shahuwadi",
      "City": "shahuwadi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "shrirampur",
      "City": "shrirampur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sindhudurg",
      "City": "sawantwadi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "singnapur",
      "City": "singnapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "solapur",
      "City": "akkalkot"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "solapur",
      "City": "barshi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "solapur",
      "City": "madha"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "solapur",
      "City": "malsiras"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "solapur",
      "City": "mangalwedha"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "ulhasnagar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sagar",
      "City": "bandha"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sagar",
      "City": "naryaoli"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sagar",
      "City": "deori"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "satna",
      "City": "maihar"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "satna",
      "City": "nagod"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sehore",
      "City": "sehore"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "seoni",
      "City": "ghansor"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shajapur",
      "City": "gulana"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shajapur",
      "City": "shajapur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shajapur",
      "City": "shujalpur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sheopur",
      "City": "bijeypur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shivpuri",
      "City": "kolaras"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "tikamgarh",
      "City": "tikamgarh"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ujjain",
      "City": "ghatiya"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "ujjain",
      "City": "ujjain south"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "umaria",
      "City": "nowrozavad"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "vidisha",
      "City": "sironj"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "nagar akola"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "amravati",
      "City": "morshi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "amravati",
      "City": "teosa"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "badnapur",
      "City": "badnapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "beed",
      "City": "beed"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bopodi",
      "City": "bopodi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "buldhana",
      "City": "buldhana"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "buldhana",
      "City": "nandura city"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "daund",
      "City": "daund"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "dhule",
      "City": "dhule"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "dhule",
      "City": "kusumba"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "gadhchiroli",
      "City": "armori"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "gangapur",
      "City": "gangapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "gondiya",
      "City": "amagaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "hadgaon",
      "City": "hadgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "jamner"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jat",
      "City": "jat"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "karjat",
      "City": "karjat"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "khanapur atpadi",
      "City": "khanapur atpadi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "latur",
      "City": "nilanga"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "malsiras",
      "City": "malsiras"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "chembur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "mulund"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "trombay"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nagar akola",
      "City": "nagar akola"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nagpur",
      "City": "nagpur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nandurbar",
      "City": "shahade"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "dabhadi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nashik",
      "City": "niphad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "paithan",
      "City": "paithan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "phaltan",
      "City": "phaltan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "bhor"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "fathima nagar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "pimple"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "shastri nagar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "raigad",
      "City": "pen"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "raigad",
      "City": "uran"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ratnagiri",
      "City": "rajapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "renapur",
      "City": "renapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangli",
      "City": "khanapur atpadi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangli",
      "City": "miraj"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangli",
      "City": "walwa"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "shirala",
      "City": "shirala"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sindhudurg",
      "City": "malvan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "dahanu"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "vasai"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "udgir",
      "City": "udgir"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "vadgaon",
      "City": "vadgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "yavatmal",
      "City": "pusad"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "satna",
      "City": "satna"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "seoni",
      "City": "keolari"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "seoni",
      "City": "lakhanadon"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "shahdol",
      "City": "kotma"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sheopur",
      "City": "sheopur"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "sidhi",
      "City": "singrauli"
    },
    {
      "Country": "INDIA",
      "State": "Madhya Pradesh",
      "District": "umaria",
      "City": "umaria"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ahemadnagar",
      "City": "koargaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "akkalkot",
      "City": "akkalkot"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "amravati",
      "City": "melghat"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "aurangabad",
      "City": "paithan"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bhadravati",
      "City": "bhadravati"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bhandara",
      "City": "bhandara"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "bhandara",
      "City": "tumsar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "chandgad",
      "City": "chandgad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "chandrapur",
      "City": "chimur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "gangakhed",
      "City": "gangakhed"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "gondiya",
      "City": "gondiya"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "Ichalkaranji",
      "City": "Ichalkaranji"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "jalgaon",
      "City": "yaval"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "kopargaon",
      "City": "kopargaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "lakhaandur",
      "City": "lakhaandur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "latur",
      "City": "udgir"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "man",
      "City": "man"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai city",
      "City": "mumbai"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "mumbai suburban",
      "City": "nehrunagar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "nagpur",
      "City": "umred"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "osmanabad",
      "City": "osmanabad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "osmanabad",
      "City": "paranda"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "paranda",
      "City": "paranda"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "Bs dhole patil path"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "balewadi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pune",
      "City": "nana peth"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "pusad",
      "City": "pusad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "raigad",
      "City": "mahad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "ratnagiri",
      "City": "khed"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangli",
      "City": "jat"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangli",
      "City": "tasgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sangole",
      "City": "sangole"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "satara",
      "City": "karad"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "shirdi",
      "City": "shirdi"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "sindhudurg",
      "City": "khed"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "bhayandar"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "shahapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "thane",
      "City": "wada"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "umarkhed",
      "City": "umarkhed"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "walwa",
      "City": "walwa"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "wardha",
      "City": "pulgaon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "wardha",
      "City": "wardha"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "yavatmal",
      "City": "kelapur"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "yavatmal",
      "City": "umarkhed"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "bishnupur",
      "City": "thanga"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "churachandpur",
      "City": "churachandpur"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "churachandpur",
      "City": "thanlon"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "yavatmal",
      "City": "wani"
    },
    {
      "Country": "INDIA",
      "State": "maharashtra",
      "District": "yavatmal",
      "City": "yavatmal"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "bishnupur",
      "City": "nambol"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "chandel",
      "City": "chandel"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal east",
      "City": "heingang"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal east",
      "City": "jiribam"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "keisamthong"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "naoriya pakhanglakpa"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "sagolband"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "thangmeiband"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "wangoi"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "thoubal",
      "City": "khangabok"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "thoubal",
      "City": "wabgai"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "bajengdoba"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "kharkutta"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "salmanpara"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East kasi Hills",
      "City": "nongkrem"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "nongbah-wahiajer"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "sutnga-shangpung"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west Garo Hills",
      "City": "ckhopot"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west Garo Hills",
      "City": "phulbari"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west Garo Hills",
      "City": "rongchugiri"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west Garo Hills",
      "City": "rongram"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "aizawl",
      "City": "saitual"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "lawngtai",
      "City": "chawngte"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "saiha",
      "City": "sangau"
    },
    {
      "Country": "INDIA",
      "State": "nagaland",
      "District": "mon",
      "City": "mon"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "boudh",
      "City": "boudhnagar"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "cuttak",
      "City": "cuttak"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "jharsuguda",
      "City": "brajarajnagar"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sambalpur",
      "City": "jamankira"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sonapur",
      "City": "sonapur"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "karaikal",
      "City": "karaikal"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "karaikal",
      "City": "yanam"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "bahour"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "cassicade"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "freozepur",
      "City": "jalalabad"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "gurdaspur",
      "City": "fatehgarh"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "gurdaspur",
      "City": "narot mehra"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "gurdaspur",
      "City": "pathankot"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "gurdaspur",
      "City": "srihargobindpur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "mahilpur",
      "City": "mahilpur"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "uripok"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ludhiana",
      "City": "dakha"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "Senapati",
      "City": "saitu"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ludhiana",
      "City": "qila raipur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "moga",
      "City": "dharamkot"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "Tamenglong",
      "City": "Tamenglong"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "thoubal",
      "City": "thoubal"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "muktsar",
      "City": "muktsar"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "patiala",
      "City": "rajpura"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "resubelpara"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "nartiang"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "sangrur",
      "City": "barnala"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "sangrur",
      "City": "dhanaula"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "nongspung"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "war jaintiai"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "sangrur",
      "City": "malerkotla"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "ajmer",
      "City": "beawar"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west Garo Hills",
      "City": "bagmara"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "aizawl",
      "City": "azl"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "ajmer",
      "City": "kishangarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "alwar",
      "City": "khairthal"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "aizawl",
      "City": "ratu"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "champhai",
      "City": "khawzawl"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "alwar",
      "City": "laxmangarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "banswara",
      "City": "banswara"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "lunglei",
      "City": "lunglei"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "serchhip",
      "City": "serchhip"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "banswara",
      "City": "da npur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bharatpir",
      "City": "bharatpir"
    },
    {
      "Country": "INDIA",
      "State": "nagaland",
      "District": "dimapur",
      "City": "dimapur"
    },
    {
      "Country": "INDIA",
      "State": "nagaland",
      "District": "kohima",
      "City": "kohima"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bhilwara",
      "City": "sahada"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bikaner",
      "City": "nokha"
    },
    {
      "Country": "INDIA",
      "State": "nagaland",
      "District": "zunheboto",
      "City": "zunheboto"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "bargarh",
      "City": "bargarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bundi",
      "City": "bundi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bundi",
      "City": "hindoli"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "bhadrak",
      "City": "dhamanagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dausa",
      "City": "mahuwa"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "bhubaneshwar",
      "City": "bhubaneshwar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "hanumangarh",
      "City": "rawaster tehsil"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "cuttak",
      "City": "ahagad"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "hanumangarh",
      "City": "sangaria"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "cuttak",
      "City": "banki"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "banipark"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "dhenkanal",
      "City": "kamakshyanagar"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "jagatsinghapur",
      "City": "paradip"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "fagi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "kishanpole"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "kalahandi",
      "City": "kesinga"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "mayurbhanj",
      "City": "baripada"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "snaganer"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jodhpur",
      "City": "bilara"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "mayurbhanj",
      "City": "rairangpur"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sambalpur",
      "City": "rairakhol"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sundergarh",
      "City": "sundergarh"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "karaikal",
      "City": "karaikal south"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "ariankuppam"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "bussy"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "modeliarpeth"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "raj bhavan"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "tirubuvanai"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "ajnala"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "attari"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "bhatinda",
      "City": "rampura phul"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "freozepur",
      "City": "firozepur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "gurdaspur",
      "City": "batala"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "hoshiarpur",
      "City": "hoshiarpur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "jullundur",
      "City": "nur mahal"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "kapurthala",
      "City": "phagwara"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ludhiana",
      "City": "jagraon"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "mansa",
      "City": "mansa"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "mansa",
      "City": "sardulgarh"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "patiala",
      "City": "nabha"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "patiala",
      "City": "patiala town"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ropar",
      "City": "morinda"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "sangrur",
      "City": "bhadaur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "sangrur",
      "City": "lehra"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "tarn taran",
      "City": "tarn taran"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "alwar",
      "City": "mundawar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "banswara",
      "City": "ghatol"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "barmer",
      "City": "siwana"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bharatpir",
      "City": "bayana"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bharatpir",
      "City": "roopbas"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bharatpir",
      "City": "weir"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bhilwara",
      "City": "asind"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bhilwara",
      "City": "mandal"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bikaner",
      "City": "bikaner"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "chittorgarh",
      "City": "begun"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "chittorgarh",
      "City": "nimbahera"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dausa",
      "City": "dausa"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dholpur",
      "City": "rajakhera"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jalore",
      "City": "raniwara"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhalawar",
      "City": "jhalrapatan"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhunjhunu",
      "City": "jhunjhunu"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhunjhunu",
      "City": "nawalgarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhunjhunu",
      "City": "surajgarh"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "konthoujam"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal east",
      "City": "thongju"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "singiamei"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "thoubal",
      "City": "wangkhem"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "ukhrul",
      "City": "kupwara"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "rongjeng"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "nongph"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "raliang"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "ribhoi"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "upper shillong",
      "City": "mawlai"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west Garo Hills",
      "City": "dadenggiri"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west kasi hills",
      "City": "mawthengkut"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "kolasib",
      "City": "kolasib"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "lunglei",
      "City": "buarpui"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "lunglei",
      "City": "hnahthial"
    },
    {
      "Country": "INDIA",
      "State": "nagaland",
      "District": "phek",
      "City": "phek"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "baleswar",
      "City": "azimabad"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "bargarh",
      "City": "khaliapali"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "boudh",
      "City": "malisahi"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "gajapati",
      "City": "parlakhemundi"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "kalahandi",
      "City": "junagarh"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "khordha",
      "City": "khordha"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "koraput",
      "City": "koraput"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "nabarangapur",
      "City": "nabarangapur"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sambalpur",
      "City": "naktideul"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sambalpur",
      "City": "sambalpur"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "embalom"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "orleampeth"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "ossudu"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "thattanchavady"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "tarn taran"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "valtoha"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "FARIDKOT",
      "City": "FARIDKOT"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "FARIDKOT",
      "City": "panjgrain"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "freozepur",
      "City": "balluana"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "freozepur",
      "City": "guru har sahai"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "gurdaspur",
      "City": "dhariwal"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "jullundur",
      "City": "adampur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "kapurthala",
      "City": "sultanpur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ludhiana",
      "City": "raikot"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "moga",
      "City": "nihal singh wala"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "muktsar",
      "City": "giddar baha"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ropar",
      "City": "anandpur sahib"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "ajmer",
      "City": "ajmer"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "ajmer",
      "City": "bhinai"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "ajmer",
      "City": "masuda"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "baran",
      "City": "baran"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "barmer",
      "City": "barmer"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bharatpir",
      "City": "deeg"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "churu",
      "City": "sardarshahar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dungarpur",
      "City": "aspur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dungarpur",
      "City": "chorasi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dungarpur",
      "City": "dungarpur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "hanumangarh",
      "City": "hanumangarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "jagatpura"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "jaipur gramin"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "kotputli"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "malviya nagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "phulera"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "patsoi"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "Senapati",
      "City": "karong"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "Senapati",
      "City": "saikul"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "Tamenglong",
      "City": "nungba"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "ukhrul",
      "City": "handwara"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "ukhrul",
      "City": "lolab"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East kasi Hills",
      "City": "dienglieng"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East kasi Hills",
      "City": "nonshken"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "Jowai"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "umroi"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "upper shillong",
      "City": "jaiaw"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "aizawl",
      "City": "tlungvel"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "lawngtai",
      "City": "lawngtai"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "mamit",
      "City": "lokicherra"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "serchhip",
      "City": "n.vanlaiphai"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "balangir",
      "City": "titlagarh"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "bhadrak",
      "City": "bhadrak"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "cuttak",
      "City": "choudwar"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "debagarh",
      "City": "debagarh"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "ganjam",
      "City": "ganjam"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "jagatsinghapur",
      "City": "jagatsinghapur"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "kandhamal",
      "City": "g.udayagiri"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "kandhamal",
      "City": "phulabani"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "kendujhar",
      "City": "kendujhar"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "mayurbhanj",
      "City": "udala"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sambalpur",
      "City": "kuchinda"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sambalpur",
      "City": "maneswar"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sundergarh",
      "City": "rourkela"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "ozhukarai"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "raja sansi"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "verka"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "fatehgarh  sahib",
      "City": "amloh"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "gurdaspur",
      "City": "qadian"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "gurdaspur",
      "City": "sujanpur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "hoshiarpur",
      "City": "garhshankar"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "hoshiarpur",
      "City": "mukerian"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "kapurthala",
      "City": "bhokath"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ludhiana",
      "City": "khanna"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ludhiana",
      "City": "kum kalan"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "mansa",
      "City": "joga"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "mohali",
      "City": "mohali"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "patiala",
      "City": "banur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "sangrur",
      "City": "sunam"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "ajmer",
      "City": "nasirabad"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "alwar",
      "City": "kathumar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "alwar",
      "City": "tijara"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bharatpir",
      "City": "kumher"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bharatpir",
      "City": "nadbai"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bhilwara",
      "City": "shahpura"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bikaner",
      "City": "kolayat"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "sodala"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "vaishali"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jalore",
      "City": "ahore"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jalore",
      "City": "jalore"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhalawar",
      "City": "khanpur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhunjhunu",
      "City": "khetri"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jodhpur",
      "City": "jodhpur"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal east",
      "City": "yaiskul"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal east",
      "City": "khurai"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "Senapati",
      "City": "tadubi"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "ukhrul",
      "City": "karnah"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "dalamgiri"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "rangsakona"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "songsak"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East kasi Hills",
      "City": "langkyrdem"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East kasi Hills",
      "City": "Mawkyrwat"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East kasi Hills",
      "City": "Mawsynram"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East kasi Hills",
      "City": "sohra"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "mawhati"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "rymbai"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west Garo Hills",
      "City": "selsalla"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west Garo Hills",
      "City": "tikrikilla"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "aizawl",
      "City": "suangpuilwn"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "champhai",
      "City": "champhai"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "kolasib",
      "City": "kawnpui"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "lunglei",
      "City": "tlabung"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "baleswar",
      "City": "baleswar"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "bargarh",
      "City": "barapali"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "bhadrak",
      "City": "basudebpur"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "cuttak",
      "City": "charibatia"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "dhenkanal",
      "City": "bhuban"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "dhenkanal",
      "City": "dhenkanal"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "jajapur",
      "City": "byasanagar"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "kendrapara",
      "City": "kendrapara"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "kendrapara",
      "City": "pattamundai"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "naupada",
      "City": "naupada"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "puri",
      "City": "puri"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sambalpur",
      "City": "bamra"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "karaikal",
      "City": "tirunallar"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "lawspet"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "muthialpet"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "nellithope"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "beas"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "majitha"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "gurdaspur",
      "City": "dina nagar"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "hoshiarpur",
      "City": "garhdiwala"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "hoshiarpur",
      "City": "tanda"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "kapurthala",
      "City": "kapurthala"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ludhiana",
      "City": "payal"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "moga",
      "City": "bagha purana"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "patiala",
      "City": "shutrana"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ropar",
      "City": "kharar"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "sangrur",
      "City": "dirbha"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "ajmer",
      "City": "kekri"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "ajmer",
      "City": "pushkar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "alwar",
      "City": "ramgarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "alwar",
      "City": "thanagazi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "barmer",
      "City": "chouhtan"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bikaner",
      "City": "lunkaransar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "chittorgarh",
      "City": "gangrar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "churu",
      "City": "sadulpur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "churu",
      "City": "sujangarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dausa",
      "City": "lalsot"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dausa",
      "City": "sikrai"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "hanumangarh",
      "City": "bhadra"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "chomu"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "jamwa ramgarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "jhotwara"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "johri bazar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhalawar",
      "City": "manoharthana"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jodhpur",
      "City": "luni"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "mayang imphal"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "sekmai"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "Tamenglong",
      "City": "tamei"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East kasi Hills",
      "City": "shella"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "jirang"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "upper shillong",
      "City": "mawkhar"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "upper shillong",
      "City": "mylliem"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "upper shillong",
      "City": "sohryngkham"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west Garo Hills",
      "City": "kherapara"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west kasi hills",
      "City": "langrin"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "champhai",
      "City": "khawbung"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "champhai",
      "City": "ngopa"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "lunglei",
      "City": "vanva"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "mamit",
      "City": "kawrthah"
    },
    {
      "Country": "INDIA",
      "State": "nagaland",
      "District": "dimapur",
      "City": "chumukedima"
    },
    {
      "Country": "INDIA",
      "State": "nagaland",
      "District": "tuensang",
      "City": "tuensang"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "angul",
      "City": "angul"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "balangir",
      "City": "patnagarh"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "baleswar",
      "City": "issannagar"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "ganjam",
      "City": "brahmapur"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "jajapur",
      "City": "jajapur"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "kalahandi",
      "City": "bhawanipatna"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "mayurbhanj",
      "City": "mayurbhanj"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "rayagada",
      "City": "rayagada"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "karaikal",
      "City": "palloor"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "villenour"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "Jandiala"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "bhatinda",
      "City": "bhatinda"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "FARIDKOT",
      "City": "kot kapura"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "freozepur",
      "City": "fazilka"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "freozepur",
      "City": "zira"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "jullundur",
      "City": "jullundur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "mansa",
      "City": "budhlada"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "moga",
      "City": "moga"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "mohali",
      "City": "zirakpur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "muktsar",
      "City": "lambi"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "patiala",
      "City": "dakala"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "patiala",
      "City": "ghanaur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "patiala",
      "City": "samana"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "banswara",
      "City": "bagidora"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "banswara",
      "City": "kushalgarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "baran",
      "City": "chhabra"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "baran",
      "City": "krishnganj"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "barmer",
      "City": "gudamalani"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "barmer",
      "City": "sheo"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bharatpir",
      "City": "kaman"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bhilwara",
      "City": "jahazpur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dholpur",
      "City": "bari"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dholpur",
      "City": "dholpur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dungarpur",
      "City": "sagwara"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "adarsh nagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "amber"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "bassi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "jaipur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "jawahar nagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "pratap nagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhalawar",
      "City": "dag"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhunjhunu",
      "City": "pilani"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jodhpur",
      "City": "bhopalgarh"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "Langthabal"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "Senapati",
      "City": "mao"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "ukhrul",
      "City": "langate"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "ampatigiri"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "rongrenggiri"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East kasi Hills",
      "City": "pariong"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "sohiong"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "south Garo Hills",
      "City": "dalu"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "upper shillong",
      "City": "laban"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "upper shillong",
      "City": "malki-nongthymmai"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "upper shillong",
      "City": "mawprem ribong"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west Garo Hills",
      "City": "rajabala"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west kasi hills",
      "City": "nongstoin"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "kolasib",
      "City": "bilkhawthlir"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "lunglei",
      "City": "tawipu"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "saiha",
      "City": "saiha"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "serchhip",
      "City": "lungpho"
    },
    {
      "Country": "INDIA",
      "State": "nagaland",
      "District": "kohima",
      "City": "peren"
    },
    {
      "Country": "INDIA",
      "State": "nagaland",
      "District": "wokha",
      "City": "wokha"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "balangir",
      "City": "balangir"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "baleswar",
      "City": "sovarampur"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "cuttak",
      "City": "belagachhia"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "jharsuguda",
      "City": "belpahar"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "jharsuguda",
      "City": "jharsuguda"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sambalpur",
      "City": "jujumora"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sambalpur",
      "City": "rengali"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "karaikal",
      "City": "cotchery"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "karaikal",
      "City": "mahe"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "nettapakkam"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "reddiyarpalayam"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "khadoor sahib"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "naushahra panwan"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "bhatinda",
      "City": "nathana"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "bhatinda",
      "City": "pakka kalan"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "gurdaspur",
      "City": "gurdaspur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "gurdaspur",
      "City": "kahnuwan"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "hoshiarpur",
      "City": "dasuya"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "jullundur",
      "City": "kartarpur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "jullundur",
      "City": "phillaur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "nawan shahr",
      "City": "balachaur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "nawan shahr",
      "City": "banga"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "nawan shahr",
      "City": "nawan shahr"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ropar",
      "City": "nangal"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "sangrur",
      "City": "sangrur"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "sangrur",
      "City": "sherpur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "alwar",
      "City": "bansur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "alwar",
      "City": "behror"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "alwar",
      "City": "rajgarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "barmer",
      "City": "pachpada"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bharatpir",
      "City": "nagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bhilwara",
      "City": "mandalgarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bikaner",
      "City": "dungargarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bundi",
      "City": "nainwa"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bundi",
      "City": "patan"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "chittorgarh",
      "City": "kapasan"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "churu",
      "City": "churu"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "churu",
      "City": "ratangarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "hanumangarh",
      "City": "nohar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "hanumangarh",
      "City": "pilibanga"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "bairath"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "dudu"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jalore",
      "City": "bhinmal"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jalore",
      "City": "sanchore"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhunjhunu",
      "City": "mandwa"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal east",
      "City": "khetrigao"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal east",
      "City": "khundrakpam"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal east",
      "City": "lamlai"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal east",
      "City": "wangkhei"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "imphal west",
      "City": "Lamsang"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "Senapati",
      "City": "Kangpokpi"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "thoubal",
      "City": "heirok"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "thoubal",
      "City": "hiyanglam"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "thoubal",
      "City": "kakching"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "thoubal",
      "City": "lilong"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "thoubal",
      "City": "sugnoo"
    },
    {
      "Country": "INDIA",
      "State": "manipur",
      "District": "thoubal",
      "City": "wangjing tentha"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "mahendraganj"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "East Garo Hills",
      "City": "mendipathar"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "jaintia hills",
      "City": "mairang"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "upper shillong",
      "City": "laitumkhrah"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "upper shillong",
      "City": "pynthorumkhrah  polo hills"
    },
    {
      "Country": "INDIA",
      "State": "Meghalaya",
      "District": "west Garo Hills",
      "City": "tura"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "aizawl",
      "City": "sateek"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "champhai",
      "City": "khawhai"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "mamit",
      "City": "phuldungsei"
    },
    {
      "Country": "INDIA",
      "State": "mizoram",
      "District": "saiha",
      "City": "tuipang"
    },
    {
      "Country": "INDIA",
      "State": "nagaland",
      "District": "mokokchung",
      "City": "mokokchung"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "balangir",
      "City": "kantabanji"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "baleswar",
      "City": "srikanthpur"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "bargarh",
      "City": "padmapur"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "gajapati",
      "City": "kashinagara"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "malkangiri",
      "City": "malkangiri"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "mayurbhanj",
      "City": "karanjia"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "nayagarh",
      "City": "nayagarh"
    },
    {
      "Country": "INDIA",
      "State": "odisha",
      "District": "sambalpur",
      "City": "dhankauda"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "karaikal",
      "City": "neduncadu"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "karaikal",
      "City": "neravy-grand aldee"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "kuruvinatham"
    },
    {
      "Country": "INDIA",
      "State": "pondicherry",
      "District": "pondicherry",
      "City": "oupalam"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "Amritsar"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "Amritsar",
      "City": "patti"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "bhatinda",
      "City": "talwandi sabo"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "fatehgarh  sahib",
      "City": "sirhind"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "freozepur",
      "City": "abohar"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "freozepur",
      "City": "firozepur cantonment"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "hoshiarpur",
      "City": "sham chaurasi"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "jullundur",
      "City": "lohian"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "jullundur",
      "City": "nakodar"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ludhiana",
      "City": "ludhiana"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "ludhiana",
      "City": "samrala"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "muktsar",
      "City": "malout"
    },
    {
      "Country": "INDIA",
      "State": "punjab",
      "District": "sangrur",
      "City": "dhuri"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "alwar",
      "City": "alwar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "baran",
      "City": "atru"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bhilwara",
      "City": "banera"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "bhilwara",
      "City": "bhilwara"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "chittorgarh",
      "City": "barisadri"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "chittorgarh",
      "City": "chittorgarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "churu",
      "City": "taranagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "dausa",
      "City": "bandikui"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "hanumangarh",
      "City": "tibi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "hawamahal"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "shastri nagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaipur",
      "City": "vidyadhar nagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jaisalmer",
      "City": "jaisalmer"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhalawar",
      "City": "pirawa"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jhunjhunu",
      "City": "gudha"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jodhpur",
      "City": "osiyan"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jodhpur",
      "City": "phalodi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pratapgarh",
      "City": "dalot"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jodhpur",
      "City": "sardarpura"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sawai madhopur",
      "City": "bamanwas"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sawai madhopur",
      "City": "gangapur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sikar",
      "City": "khandela"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sri ganganagar",
      "City": "kishansinghpur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sri ganganagar",
      "City": "raisinghnagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "udaipur",
      "City": "mavli"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "udaipur",
      "City": "sarada"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "loosing pachekhani"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "ranka"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "south",
      "City": "wak"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "ariyalur",
      "City": "ariyalur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Madipakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Perambur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Saidapet"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Tirusulam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "villiwakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "virugambakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "perur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "Thondamuthur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dharmapuri",
      "City": "Morappur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dharmapuri",
      "City": "Pennagaram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dindigul",
      "City": "Natham"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dindigul",
      "City": "Palani"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dindigul",
      "City": "Vedasandur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Erode",
      "City": "Bhavani"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Erode",
      "City": "Gobichettipalayam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "nagapattinam",
      "City": "mayiladuthurai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Perambalur",
      "City": "Alathur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Perambalur",
      "City": "kunnam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "puthukkottai",
      "City": "aranthangi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "puthukkottai",
      "City": "puthukkottai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "salem",
      "City": "athur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "salem",
      "City": "talavasal"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Thanjavur",
      "City": "papanasam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Thanjavur",
      "City": "thiruvidaimaruthur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruchirappalli",
      "City": "marungapuri"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "Vasudevanallur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tirupur",
      "City": "vellakoil"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tiruvannamalai",
      "City": "Thandarampattu"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tiruvannamalai",
      "City": "Vandavasi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tuticorin",
      "City": "srivaikuntam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "Gingee"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "kandamangalam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "rishivandiam"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "adilabad",
      "City": "adilabad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "bolarum"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "madhapur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "malkajgiri"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "maredpally"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "tirmulgherry"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "uppal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "yousufguda"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "indurthi"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "medak",
      "City": "zahirabad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "Achampet"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "amarchinta"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jodhpur",
      "City": "soorsagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "kota",
      "City": "ramganjmandi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "nagaur",
      "City": "jayal"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "nagaur",
      "City": "makrana"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "nagaur",
      "City": "nawa"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pali",
      "City": "jaitaran"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pratapgarh",
      "City": "chhoti sadari"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sawai madhopur",
      "City": "khandar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sikar",
      "City": "srimadhopur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sirohi",
      "City": "sirohi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sri ganganagar",
      "City": "suratgarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "tonk",
      "City": "toda raysingh"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "central pendam"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "khamdong"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "pathing"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "west",
      "City": "tashiding"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "alwarpet"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "kamaraj nagar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "kilpauk"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Thiruvottiyur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Vadapalani"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Vandalur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Cuddalore",
      "City": "Kattumannarkoil"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dharmapuri",
      "City": "Palacode"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dindigul",
      "City": "athoor"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Erode",
      "City": "Modakkurichi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kancheepuram",
      "City": "Tambaram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kancheepuram",
      "City": "Uthiramerur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kanniyakumari",
      "City": "Thiruvattar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "krishnagiri",
      "City": "Thalli"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Madurai",
      "City": "usilampatti"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "nagapattinam",
      "City": "nagapattinam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "nagapattinam",
      "City": "sirkali"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "nilgiris",
      "City": "gudalur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "ramanathapuram",
      "City": "madukulathur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "ramanathapuram",
      "City": "paramakudi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "ramanathapuram",
      "City": "thiruvadanai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "salem",
      "City": "mettur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "salem",
      "City": "salem"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "theni",
      "City": "andipatti"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruvarur",
      "City": "tiruvarur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tiruvallur",
      "City": "Tiruttani"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tiruvallur",
      "City": "villivakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tuticorin",
      "City": "Kayalpatnam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "vanur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "a.s rao nagar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "begumpet"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "chandrayanagutta"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "Hyderabad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "jeedimetla"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "kachiguda"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "karkhana"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "kukatpally"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "LB nagar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "motinagar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "tarnaka"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "karimnagar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Khammam",
      "City": "sathupalli"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "medak",
      "City": "medak"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "medak",
      "City": "narsapur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "medak",
      "City": "siddipet"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "Alampur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "Makthal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "Narayanpet"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "kota",
      "City": "pipalda"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "nagaur",
      "City": "merta"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pali",
      "City": "desuri"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pali",
      "City": "raipur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pratapgarh",
      "City": "arnod"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "tonk",
      "City": "malpura"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "tonk",
      "City": "newai"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "tonk",
      "City": "tonk"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "assam-lingjey"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "gangtok"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "west",
      "City": "dentam"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "west",
      "City": "rinchenpong"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Adyar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "anna salai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "kolathur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "manali"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Nungambakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "padi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "ramapuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "royapuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Tondiarpet"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "velacherry"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "singanallur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "Valparai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Cuddalore",
      "City": "Kurinjipadi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Cuddalore",
      "City": "mangalore"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dharmapuri",
      "City": "Harur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Erode",
      "City": "Bhavanisagar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Erode",
      "City": "Perundurai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kancheepuram",
      "City": "alandur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kancheepuram",
      "City": "chengalpattu"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kancheepuram",
      "City": "Sriperumbadar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "karur",
      "City": "karur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "karur",
      "City": "Kulithalai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "krishnagiri",
      "City": "Kaveripattinam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "krishnagiri",
      "City": "krishnagiri"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Madurai",
      "City": "samayanallur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Namakkal",
      "City": "rasipuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Namakkal",
      "City": "sendamangalam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "puthukkottai",
      "City": "thirumayam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "ramanathapuram",
      "City": "ramanathapuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "sivaganga",
      "City": "manamadurai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "sivaganga",
      "City": "tiruppathur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Thanjavur",
      "City": "thiruvaiyaru"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "theni",
      "City": "periyakulam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruchirappalli",
      "City": "Thottiyam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "Ambasamudram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "Kadayanallur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "Sankaranayinarkoil"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tirupur",
      "City": "Udumalaipettai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tiruvallur",
      "City": "pakkipet"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tiruvallur",
      "City": "Thiruvallur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tiruvannamalai",
      "City": "arni"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tuticorin",
      "City": "Tiruchendur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "tirukoilur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "ulundurpet"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "virudhunagar",
      "City": "Srivillithur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "adilabad",
      "City": "Luxettipet"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "cherlapally"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "jublihills"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "mehdipatnam"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "nacharam"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "sainikpuri"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "choppadandi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "karauli",
      "City": "todabhim"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "pedapalli"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "sircilla"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Khammam",
      "City": "burgampahad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Khammam",
      "City": "madhira"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Khammam",
      "City": "shujatnagar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "Kollapur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "kota",
      "City": "digod"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "kota",
      "City": "ladpura"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "nagaur",
      "City": "ladnun"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pratapgarh",
      "City": "pratapgarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "rajsamand",
      "City": "kumbhalgarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "rajsamand",
      "City": "nathdwara"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "udaipur",
      "City": "phalasia"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "udaipur",
      "City": "udaipur rural"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "rakdong  tentek"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "rhenock"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "south",
      "City": "damthang"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "south",
      "City": "jorthang-nayabazar"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "south",
      "City": "rateypani"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "ariyalur",
      "City": "udayarpalayam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Ayanavaram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "choolaimedu"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Chromepet"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "jaffarkhanpet"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Kodambakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "kottupuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Minambakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "nandanam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "tambaram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "palladam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dharmapuri",
      "City": "dharmapuri"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dindigul",
      "City": "Nilakkottai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kancheepuram",
      "City": "maduranthakam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kanniyakumari",
      "City": "vilavancode"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kiliyur",
      "City": "kiliyur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "nagapattinam",
      "City": "kuttalam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "nilgiris",
      "City": "ooatacamund"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Perambalur",
      "City": "veppanthattai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "puthukkottai",
      "City": "kolathur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "ramanathapuram",
      "City": "kadaladi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "salem",
      "City": "omalur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "salem",
      "City": "panamarathupatty"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Thanjavur",
      "City": "Orathanadu"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "theni",
      "City": "theni"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruchirappalli",
      "City": "srirangam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruchirappalli",
      "City": "uppiliapuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "Nanguneri"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "Palayamkottai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "radhapuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tirupur",
      "City": "kangeyam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tirupur",
      "City": "tirupur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tuticorin",
      "City": "Ottapidaram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tuticorin",
      "City": "Sattankulam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "mugaiyur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "sankarapuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "virudhunagar",
      "City": "Aruppukottai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "virudhunagar",
      "City": "virudhunagar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "adilabad",
      "City": "Asifabad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "BHEL"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "Banjarahills"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "ECIL"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "rajendra nagar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "metpalli"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "Jadcherla"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "Wanaparthy"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "jodhpur",
      "City": "shergarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "kota",
      "City": "kota"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "nagaur",
      "City": "degana"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "nagaur",
      "City": "mundwa"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pali",
      "City": "pali"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sikar",
      "City": "dantaramgarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sikar",
      "City": "dhod"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sikar",
      "City": "sikar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sri ganganagar",
      "City": "ganganagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "udaipur",
      "City": "gogunda"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "martam"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "regu"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "north",
      "City": "djongu"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "north",
      "City": "lachen-mangshila"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "west",
      "City": "chakung"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "broadway"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "ennore"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "harbour"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Madhavaram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Mambalam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Park Town"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "thiruvanmiyur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "Kinathukadavu"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Cuddalore",
      "City": "Chidambaram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Cuddalore",
      "City": "Neyveli"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Erode",
      "City": "Andhiyur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Erode",
      "City": "Erode"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Erode",
      "City": "Sathyamangalam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kanniyakumari",
      "City": "Colachel"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kanniyakumari",
      "City": "kanniyakumari"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kanniyakumari",
      "City": "Nagercoil"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "krishnagiri",
      "City": "Bargur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Madurai",
      "City": "sholavandan"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Madurai",
      "City": "Tirumangalam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Madurai",
      "City": "tiruparankundram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "nagapattinam",
      "City": "poompuhar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Thanjavur",
      "City": "Kumbakonam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruchirappalli",
      "City": "Tiruchirappalli"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruvarur",
      "City": "nannilam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "alangulam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tirupur",
      "City": "nallur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tirupur",
      "City": "Palladam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tirupur",
      "City": "velampalayam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tiruvannamalai",
      "City": "Tiruvannamalai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "thirunavalur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "virudhunagar",
      "City": "Sattur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "adilabad",
      "City": "khanapur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "adilabad",
      "City": "Mancherial"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "adilabad",
      "City": "Nirmal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "Bowenpally"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "dilsukhnagar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "kphb"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "kondapur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "malakpet"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "panjagutta"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "toli chowki"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "vanasthalipuram"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "MANTHANI"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "narella"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Khammam",
      "City": "Khammam"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Khammam",
      "City": "yellandu"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "medak",
      "City": "andole"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "Mahbubunagar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "Nagarkurnool"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "Bhongir"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "chalakurthi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "karauli",
      "City": "sapotra"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "nagaur",
      "City": "didwana"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "nagaur",
      "City": "nagaur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "rajsamand",
      "City": "rajsamand"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sikar",
      "City": "fatehpur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sirohi",
      "City": "pindwara abu"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sri ganganagar",
      "City": "karanpur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "udaipur",
      "City": "lasadia"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "udaipur",
      "City": "udaipur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "udaipur",
      "City": "vallabhanagar"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "sangha"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "south",
      "City": "melli"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "south",
      "City": "temi-tarku"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "west",
      "City": "barmiok"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "west",
      "City": "geyzing"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "ariyalur",
      "City": "andimadam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Anna Nagar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Chetput"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "chitlepakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "jawahar nagar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Mylapore"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "nandambakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "nanganallur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "pallavaram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "dharapuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "Pollachi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Cuddalore",
      "City": "Nellikuppam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Cuddalore",
      "City": "Virudhachalam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dindigul",
      "City": "dindigul"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Erode",
      "City": "vellakoil"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "karur",
      "City": "Aravakurichi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Madurai",
      "City": "sedapatti"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Namakkal",
      "City": "sankari"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Perambalur",
      "City": "Perambalur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Perambalur",
      "City": "Varahur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "sivaganga",
      "City": "sivaganga"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Thanjavur",
      "City": "Thanjavur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "theni",
      "City": "cumbum"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "Tirunelveli"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tirupur",
      "City": "Dharapuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tiruvallur",
      "City": "Gummindipoondi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tiruvallur",
      "City": "Ponneri"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tiruvallur",
      "City": "Thiruvottiyur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tiruvannamalai",
      "City": "Chengam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tiruvannamalai",
      "City": "Kalasapakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "vellore",
      "City": "vellore"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "chinnsalem"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "virudhunagar",
      "City": "Sivakasi"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "adilabad",
      "City": "sirpur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "alwal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "ameerpet"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "charminar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "lakdikapol"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "miyapur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Khammam",
      "City": "bhadrachalam"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Khammam",
      "City": "palair"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "medak",
      "City": "narayankhed"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "Kalwakurthi"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "shadnagar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "karauli",
      "City": "hindaun"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "karauli",
      "City": "karauli"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pali",
      "City": "kharchi"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pali",
      "City": "sumerpur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pratapgarh",
      "City": "dhariavad"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "rajsamand",
      "City": "bhim"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sikar",
      "City": "laxmangarh"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sikar",
      "City": "neem ka thana"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "udaipur",
      "City": "kherwara"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "east",
      "City": "rumtek"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "west",
      "City": "soreong"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "west",
      "City": "yoksam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Anna Nagar arcot"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Ashok Nagar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "avadi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "besant nagar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "chepauk"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Dr.radhakrushnan nagar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Egmore"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "manapakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "porur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "teagaraya nagar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "avinashi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "pongalur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "tiruppur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "udumalipet"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Cuddalore",
      "City": "Cuddalore"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Cuddalore",
      "City": "Panruti"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kancheepuram",
      "City": "kancheepuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kancheepuram",
      "City": "tiruporur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kanniyakumari",
      "City": "Padmanabhapuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "karur",
      "City": "Krishnarayapuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Madurai",
      "City": "melur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "nagapattinam",
      "City": "vedaranyam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Namakkal",
      "City": "Namakkal"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Perambalur",
      "City": "jayankondam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "puthukkottai",
      "City": "alangudi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "salem",
      "City": "edapadi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "salem",
      "City": "taramangalam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "salem",
      "City": "yercaud"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "sivaganga",
      "City": "ilayangudi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruvarur",
      "City": "mannargudi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruvarur",
      "City": "tiruthuraipoondi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "Cheranmahadevi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "tenkasi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tiruvallur",
      "City": "Poonamallee"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tiruvannamalai",
      "City": "Polur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tuticorin",
      "City": "Koilpatti"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tuticorin",
      "City": "tuticorin"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "adilabad",
      "City": "mudhole"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "abids"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "khairatabad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "nampally"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "buggaram"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "jagital"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "kamalapur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Khammam",
      "City": "kothagudem"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "medak",
      "City": "ramayampet"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "medak",
      "City": "sangareddy"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "gadwal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "mehboobnagar",
      "City": "Kodangal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "Alair"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pali",
      "City": "bali"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pali",
      "City": "sojat"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "pratapgarh",
      "City": "pipalkhut"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sawai madhopur",
      "City": "sawai madhopur"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "sirohi",
      "City": "reodar"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "tonk",
      "City": "uniara"
    },
    {
      "Country": "INDIA",
      "State": "rajasthan",
      "District": "udaipur",
      "City": "salumber"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "north",
      "City": "kabi tingda"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "south",
      "City": "ralong"
    },
    {
      "Country": "INDIA",
      "State": "sikkim",
      "District": "west",
      "City": "daramdin"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Adambakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Ambattur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Guindy"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "korattur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Pammal"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Purasawalkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "thousand lights"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "Triplicane"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "chennai",
      "City": "washermanpet"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "coimbatore"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "coimbatore",
      "City": "Mettupalayam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Cuddalore",
      "City": "Bhuvanagiri"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "dindigul",
      "City": "Oddanchatram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Erode",
      "City": "kangeyam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kancheepuram",
      "City": "acharapakkam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "kanniyakumari",
      "City": "Killiyur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "krishnagiri",
      "City": "Hosur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Madurai",
      "City": "Madurai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Namakkal",
      "City": "kapilamalai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Namakkal",
      "City": "tiruchengode"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "nilgiris",
      "City": "coonoor"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "salem",
      "City": "veerapandi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "sivaganga",
      "City": "karaikudi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Thanjavur",
      "City": "pattukottai"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Thanjavur",
      "City": "thiruvonam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "theni",
      "City": "bodinayakkanur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruchirappalli",
      "City": "Lalkudi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruchirappalli",
      "City": "Musiri"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruchirappalli",
      "City": "thiruverumbur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "thiruvarur",
      "City": "valangiman"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tirunelveli",
      "City": "Puliyankudi"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tiruvannamalai",
      "City": "Cheyyar"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "Tiruvannamalai",
      "City": "Peranamallur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "tuticorin",
      "City": "vilathikulam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "Melmalayanur"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "Tindivanam"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "villupuram",
      "City": "villupuram"
    },
    {
      "Country": "INDIA",
      "State": "Tamilnadu",
      "District": "virudhunagar",
      "City": "Rajapalayam"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "adilabad",
      "City": "Bhainsa"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "adilabad",
      "City": "boath"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "adilabad",
      "City": "chinnur"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "GACHIBOWLI"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "himayatnagar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "manikonda"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "musheerabad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Hyderabad",
      "City": "secunderabad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "godavarikhani"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "huzurabad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "karimnagar",
      "City": "mydaram"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "medak",
      "City": "dommat"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "rangareddy",
      "City": "pargi"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "Devarakonda"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "rangareddy",
      "City": "chevella"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "nalgonda"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Nizamabad",
      "City": "Dichpalli"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Nizamabad",
      "City": "Nizamabad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "rangareddy",
      "City": "ibrahimpatnam"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "hanamkonda"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "mulug"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "wardhannapet"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "dhalai district",
      "City": "kulai"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "north tripura district",
      "City": "kailasahar"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "barjala"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "nalchar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "Fatehabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "Jatari"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "Jhusi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Phulpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "gunnapur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Islamnagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Usawan"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Usehat"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ballia",
      "City": "Reoti"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "banda",
      "City": "Mataundh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Dariyabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Tikait Nagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Zaidpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "bareilly"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Faridpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Fateh Purvi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Kakgaina"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Jhalu"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Sherkot"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Warhapur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Khurja"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ghaziabad",
      "City": "ghaziabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "jhansi",
      "City": "jhansi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "kanpur dehat",
      "City": "kanpur dehat"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "sultanpur",
      "City": "sultanpur"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Chamoli‎",
      "City": "Badrinath"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Dehradun",
      "City": "Dehradun"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Haridwar",
      "City": "bahadrabad"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pauri garhwal",
      "City": "kotdwar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pauri garhwal",
      "City": "pauri"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pauri garhwal",
      "City": "srinagar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "rudraprayag",
      "City": "kedarnath"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "rudraprayag",
      "City": "rudraprayag"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "tehri  garhwal",
      "City": "deoprayag"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "uttarkashi",
      "City": "purola"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "bankura"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "taldangra"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "coochbehar",
      "City": "Dinhata"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "coochbehar",
      "City": "tufanganj"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "dakshin dinajpur",
      "City": "Gangarampur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "Chandarnagor"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "Chinsurah"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "haripal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "dornakal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "shyampet"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "dhalai district",
      "City": "raima vally"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "north tripura district",
      "City": "pencharthal"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "belonia"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "sabroom"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "santirbazar"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "Agartala"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "mohanpur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "pratapgarh"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "promodenagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "azizpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "nainana jat"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "Vijaigarh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "Mau Aima"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "auraiya",
      "City": "Phaphund"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "azamgarh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Sarai Mir"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Kunwargaon"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bagpat",
      "City": "bagpat"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ballia",
      "City": "Bansdih."
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ballia",
      "City": "Sikanderpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Siddhaur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Chandpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Dhampur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Kiratpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Mukrampur Khema"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Gulaothi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Shikarpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "chandauli",
      "City": "chandauli"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "deoria",
      "City": "deoria"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "gautham budda nagar",
      "City": "greater noida"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "gautham budda nagar",
      "City": "greater noida-west"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "gautham budda nagar",
      "City": "jewar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "gorakhpur",
      "City": "gorakhpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "hamirpur",
      "City": "hamirpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "jaunapur",
      "City": "jaunapur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "jyotiba phule nagar",
      "City": "jyotiba phule nagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "kannauj",
      "City": "kannauj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "kanpur nagar",
      "City": "kanpur nagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "lakhimpur kheri",
      "City": "kheri"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "rampur",
      "City": "rampur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "sitapur",
      "City": "sitapur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "unnao",
      "City": "unnao"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "varanasi",
      "City": "varanasi"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Almora",
      "City": "ranjkhet"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Dehradun",
      "City": "Doiwala"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Haridwar",
      "City": "Bhagwanpur"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "nainital",
      "City": "dhari"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "tehri  garhwal",
      "City": "ghansali"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "tehri  garhwal",
      "City": "narendranagar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "udham singh nagar",
      "City": "sitarganj"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "raipur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "ranibandh"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "Suri"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "burdwan"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "Munugode"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "darjeeling",
      "City": "kalimpong"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "darjeeling",
      "City": "Siliguri"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "balagarh"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "Bansberia"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "chanditala"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "Singur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "Amta"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "Ramannapet"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Nizamabad",
      "City": "Armoor"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Nizamabad",
      "City": "banswada"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Nizamabad",
      "City": "bodhan"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Nizamabad",
      "City": "yellareddy"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "cheriyal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "jangaon"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "mandi gobindgarh"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "parkal"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "hrishyamukh"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "jolaibari"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "rajnagar"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "bamutia"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "mandaibazar"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "ramnagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "dhanuali"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "Fatehpur Sikri"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "Shamshabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "Qasimpur Power House"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "Jhusi Kohna"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "Koraon"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "auraiya",
      "City": "Babarpur Ajitmal"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Jiyanpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Faizganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Kachhla"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Mundai"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bahraich",
      "City": "bahraich"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ballia",
      "City": "Belthara Road."
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "banda",
      "City": "Atarra"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "banda",
      "City": "Baberu"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "banda",
      "City": "tindwari"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Fatehganj Pashchimi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Rithora"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "sainthal"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Nagina"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Noorpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Seohara"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Anupshahr"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "hathras",
      "City": "hathras"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "kushinagar",
      "City": "kushinagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "lalitpur",
      "City": "lalitpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "mau",
      "City": "mau"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "moradabad",
      "City": "moradabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "pilibhit",
      "City": "pilibhit"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "saharanpur",
      "City": "saharanpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "sant ravidas nagar",
      "City": "sant ravidas nagar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Almora",
      "City": "dwarahat"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Almora",
      "City": "jageshwar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "champawat",
      "City": "lohaghat"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Dehradun",
      "City": "Chakrata"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "nainital",
      "City": "haldwani"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pithoragarh",
      "City": "didihat"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pithoragarh",
      "City": "kanalichhina"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "uttarkashi",
      "City": "gangotri"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "gangajalghati"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "indpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "nanur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "barabani"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "jamalpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "katwa"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "jangipara"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "pursurah"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "Tarakeswar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "Kodad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "Nakrekal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "warangal"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "dhalai district",
      "City": "chhawmanu"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "dhalai district",
      "City": "surma"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "north tripura district",
      "City": "Dharmanagar"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "north tripura district",
      "City": "fatikroy"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "golaghati"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "khayerpur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "krishnapur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "ramchandraghat"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "takarjala"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "Pinahat"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Amilo"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Atraulia"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Azmatgarh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Bilariaganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Hafizpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Kakrala"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Saidpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Sakhanu"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bahraich",
      "City": "jarwal"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bahraich",
      "City": "risia bazar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "banda",
      "City": "banda"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Satrikh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Aonla"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Mirganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Richha"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Bhawan Bahadur Nagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Dibai"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Khanpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Naruara"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "chandauli",
      "City": "dulhipur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "chandauli",
      "City": "saiyad raja"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "chitrakoot",
      "City": "chitrakoot"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "fatehpur",
      "City": "fatehpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "gautham budda nagar",
      "City": "noida"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "gonda",
      "City": "gonda"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "lucknow",
      "City": "lucknow"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "mahoba",
      "City": "mahoba"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Almora",
      "City": "bhikiasain"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Dehradun",
      "City": "laxman chowk"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Haridwar",
      "City": "iqbalpur"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pauri garhwal",
      "City": "lansdowne"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pauri garhwal",
      "City": "yamkeshwar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pithoragarh",
      "City": "dharchula"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "tehri  garhwal",
      "City": "pratapnagar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "udham singh nagar",
      "City": "kashipur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "onda"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "Nalhati"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "Rampurhat"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "asansol"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "hirapur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "manteswar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "nadanghat"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "purbasthali"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "coochbehar",
      "City": "coochbehar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "dhaniakhali"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "Uttarpara"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Nizamabad",
      "City": "Jukkal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "Suryapet"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "rangareddy",
      "City": "medchal"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "rangareddy",
      "City": "vikarabad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "ghanpur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "north tripura district",
      "City": "panisagar"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "matarbari"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "salgarh"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "dhanpur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "sonamura"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "agra"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "jagner"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "Jalali"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ambedkar nagar",
      "City": "ambedkar nagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "auraiya",
      "City": "Achhalda"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "auraiya",
      "City": "Atasu"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "auraiya",
      "City": "auraiya"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Mehanagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "baduan"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "gawan"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Rudayan"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Sahaswan"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ballia",
      "City": "Rasra"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Banki"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Dewa"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Bisharatganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Pipalsana Chaudhari"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "basti",
      "City": "harraiyi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Afzalgarh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Jalalabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Sahanpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Siana"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "faizabad",
      "City": "faizabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "gautham budda nagar",
      "City": "dankaur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "jalaun",
      "City": "jalaun"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "muzaffarnagar",
      "City": "muzaffarnagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "raebareli",
      "City": "raebareli"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "sant kabir nagar",
      "City": "sant kabir nagar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "bageshwar",
      "City": "kanda"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Dehradun",
      "City": "Mussoorie"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Dehradun",
      "City": "Rishikesh"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Haridwar",
      "City": "Laksar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Haridwar",
      "City": "Landhaura"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "nainital",
      "City": "ramnagar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pithoragarh",
      "City": "gangolihat"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "udham singh nagar",
      "City": "khatima"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "Bolpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "Rajnagar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "bhatar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "jamuria"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "ketugram"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "kulti"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "coochbehar",
      "City": "sitai"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "coochbehar",
      "City": "sitalkuchi"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "polba"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Nizamabad",
      "City": "balkonda"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "Nizamabad",
      "City": "Kamareddy"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "rangareddy",
      "City": "tandur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "north tripura district",
      "City": "chandipur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "north tripura district",
      "City": "jubarainagar"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "north tripura district",
      "City": "kurthi"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "bagma"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "asharambari"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "banamalipur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "bishalgarh"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "boxanagar"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "teliamura"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "Kheragarh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "Atrauli"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "Harduagunj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "pilkhana"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "Bharatganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "Chak Imam Ali"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Mubarakpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Allapur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Bisauli"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Dataganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Gulariya"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Ujhani"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Wazirganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bahraich",
      "City": "nanpara"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ballia",
      "City": "ballia"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ballia",
      "City": "Sahatwar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "balrampur",
      "City": "tulsipur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "banda",
      "City": "oran"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Nawabganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Baheri"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Nawabganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Mandawar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Nehtaur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Rashidpur Garhi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Bugrasi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "bulandshahr"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "gautham budda nagar",
      "City": "dadri"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ghazipur",
      "City": "ghazipur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "maharajgunj",
      "City": "maharajgunj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "pratapghar",
      "City": "pratapghar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Almora",
      "City": "Almora"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "bageshwar",
      "City": "kapkot"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Dehradun",
      "City": "Rajpur"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Dehradun",
      "City": "sahaspur"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Dehradun",
      "City": "Vikasnagar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Haridwar",
      "City": "Manglour"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Haridwar",
      "City": "Roorkee"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "nainital",
      "City": "mukteshwar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "nainital",
      "City": "nainital"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pauri garhwal",
      "City": "thalisain"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "udham singh nagar",
      "City": "jaspur"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "udham singh nagar",
      "City": "rudrapur-kichha"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "chhatna"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "Dubrajpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "hansan"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "mayureswar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "Murarai"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "durgapur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "Kalna"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "khandaghosh"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "mangalkot"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "coochbehar",
      "City": "natabari"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "Miryalaguda"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "dakshin dinajpur",
      "City": "kumarganj"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "dakshin dinajpur",
      "City": "kushmandi"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "darjeeling",
      "City": "darjeeling"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "khanakul"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "Pandua"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "nalgonda",
      "City": "Tungaturthi"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "chennur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "dhalai district",
      "City": "salema"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "north tripura district",
      "City": "kadamtala"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "ampinagar"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "kakraban"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "manu"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "majishpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "Achhnera"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "dayalbagh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "etmadpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "Kiraoli"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "swamibagh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "Iglas"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "Kauriaganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "Handia"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "Lal Gopalganj Nindaura"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "Shankargarh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "auraiya",
      "City": "Bidhuna"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "ibrahimpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Mahrajganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ballia",
      "City": "Chitbara Gaon"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "ballia",
      "City": "Maniyar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "balrampur",
      "City": "balrampur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "balrampur",
      "City": "pachperwa"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "balrampur",
      "City": "utraula"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "banda",
      "City": "Bisenda buzurg"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "banda",
      "City": "Nariani"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Fatehpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Haidergarh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Dhaura Tanda"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Shahi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Shergarh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Thiriya Nizamat Khan"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "basti",
      "City": "basti"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "bijnor"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Tatarpur Lallu"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Chhatri"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Jahangirabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "chandauli",
      "City": "chakai"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "etah",
      "City": "etah"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "etewah",
      "City": "etewah"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "farrukhabad",
      "City": "farrukhabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "hardoi",
      "City": "hardoi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "mainpur",
      "City": "mainpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "mathura",
      "City": "mathura"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "shahjahanpur",
      "City": "shahjahanpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "siddhartnagar",
      "City": "siddhartnagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "sonbhadra",
      "City": "sonbhadra"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Almora",
      "City": "salt"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Almora",
      "City": "Someshwar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "bageshwar",
      "City": "bageshwar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Chamoli‎",
      "City": "Karnaprayag"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Chamoli‎",
      "City": "pindar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Haridwar",
      "City": "Haridwar"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pauri garhwal",
      "City": "bironkhal"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pauri garhwal",
      "City": "dhumakot"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "tehri  garhwal",
      "City": "tehri"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "udham singh nagar",
      "City": "pantnagar-gadarpur"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "uttarkashi",
      "City": "yamunotri"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "indas"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "Kotulpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "Labhpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "birbhum",
      "City": "mahammaad bazar"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "mahbubabad"
    },
    {
      "Country": "INDIA",
      "State": "Telangana",
      "District": "warangal",
      "City": "narsampet"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "dhalai district",
      "City": "kamalpur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "north tripura district",
      "City": "kanchanpur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "north tripura district",
      "City": "pabiachhara"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "birganj"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "south tripura district",
      "City": "radhakishorepur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "badharghat"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "charilam"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "kalayanpur"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "kamalasagar"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "khowal"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "simna"
    },
    {
      "Country": "INDIA",
      "State": "Tripura",
      "District": "west tripura district",
      "City": "town bardowali"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "agra",
      "City": "bah"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "aligarh"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "Beswan"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "Chhara Rafatpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "aligarh",
      "City": "Khair"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "allahabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "Phulpur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "allahabad",
      "City": "Sirsa"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "auraiya",
      "City": "Dibiyapur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Katghar Lalganj"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "azamgarh",
      "City": "Nizamabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "babrala"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "baduan",
      "City": "Bilsi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Ramnagar"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "barabanki",
      "City": "Rampur Bhawanipur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Deoranian"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bareilly",
      "City": "Sirauli"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Haldaur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bijnor",
      "City": "Najibabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Aurangabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "pahasu"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "bulandshahr",
      "City": "Sikandrabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "chandauli",
      "City": "mughalsarai"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "firozabad",
      "City": "firozabad"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "hapur",
      "City": "hapur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "kaushambi",
      "City": "kaushambi"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "meerut",
      "City": "meerut"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "mirzapur",
      "City": "mirzapur"
    },
    {
      "Country": "INDIA",
      "State": "uttar pradesh",
      "District": "shrawasti",
      "City": "shrawasti"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Chamoli‎",
      "City": "Nandaprayag"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "champawat",
      "City": "champawat"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "Haridwar",
      "City": "Laldhang"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "pithoragarh",
      "City": "pithoragarh"
    },
    {
      "Country": "INDIA",
      "State": "uttarakhand",
      "District": "udham singh nagar",
      "City": "bajpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "Barjora"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "Sonamukhi"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "bankura",
      "City": "vishnupur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "kanksa"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "memari"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "ukhra"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "coochbehar",
      "City": "Mekhliganj"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "darjeeling",
      "City": "Kurseong"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "Champdani"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "goghat"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "Serampore"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "ausgram"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "galsi"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "raina"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "burdwan",
      "City": "raniganj"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "dakshin dinajpur",
      "City": "Balurghat‎"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "dakshin dinajpur",
      "City": "tapan"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "darjeeling",
      "City": "phansidewa"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "hooghly",
      "City": "Arambagh"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "Bagnan"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "uluberia"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "Bally"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "Panchla"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "alipore"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "Bowbazar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "Jorasanko"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "vidyasagar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "malda",
      "City": "suzapur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "Aurangabad"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "Farakka"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "Naoda"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "Chakdaha"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "hanskhali"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "kaliganj"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "krishnaganj"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "Nabadwip"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Bijpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "bongaon"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Dum Dum"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "haroa"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "swarupnagar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "keshpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "moyna"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "nandigram"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "Panskura"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purulia",
      "City": "hura"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "behala west"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Diamond Harbour"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "gosaba"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "uttar dinajpur",
      "City": "chopra"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "howrah"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "kalyanpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "Rash Behari Avenue"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "sealdah"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "Tollygunge"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "malda",
      "City": "habibpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "malda",
      "City": "manikchak"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "jalanngi"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "Karimpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "Krishnanagar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "Ranaghat"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "santipur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "deganga"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Panihati"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "pashchim medinipu",
      "City": "daspur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "bhagabanpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "Contai"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "debra"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "narayangarh"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Bishnupur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Falta"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "jalpaiguri",
      "City": "madarihat"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Kakdwip"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "Ballygunge"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "kulpi"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "mandirbazar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "shyampukur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Mathurapur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "taltola"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "satgachia"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "malda",
      "City": "harishchandrapur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "lalgola"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Bhatpara"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "pashchim medinipu",
      "City": "Chandrakona"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "pashchim medinipu",
      "City": "sabang"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "garhbeta"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "pingla"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purulia",
      "City": "para"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Baruipur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Budge Budge"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Magrahat"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "uttar dinajpur",
      "City": "dalkhola"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "uttar dinajpur",
      "City": "itahar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "Shibpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "jalpaiguri",
      "City": "jalpaiguri"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "jalpaiguri",
      "City": "kalchini"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "jalpaiguri",
      "City": "rajganj"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "beliaghata"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "Dhakuria"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "kabitirtha"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "malda",
      "City": "englishbazar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "Beldanga"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "harharpara"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "suti"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Ashoknagar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Habra"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Noapara"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "Egra"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "midnapore"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purulia",
      "City": "arsa"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purulia",
      "City": "Raghunathpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Basanti"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "behala east"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "uttar dinajpur",
      "City": "kaliaganj"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "Sankrail"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "Barabazar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "Belgachia west"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "Cossipore"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "Domkal"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "Kandi"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "Sagardighi"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "Haringhata"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "nakashipara"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "bagdaha"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "belgachia east"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "gaighata"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "sandeshkhali"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "pashchim medinipu",
      "City": "Ghatal"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "binpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "jhargram"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "mugberia"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "pataspur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purulia",
      "City": "kashipur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "garden reach"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "kultali"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "sonarpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "jalpaiguri",
      "City": "alipurduars"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "jalpaiguri",
      "City": "falakata"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "jalpaiguri",
      "City": "kranti"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "jalpaiguri",
      "City": "kumargram"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "burtola"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "malda",
      "City": "araidanga"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "malda",
      "City": "malda"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "bhagabangola"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "khargram"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "amdanga"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Barasat"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "hasnabad"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Khardah"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Naihati"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Titagarh"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "mahishadal"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "salbani"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purulia",
      "City": "Jaipur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purulia",
      "City": "Manbazar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Canning"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "jadavpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "uttar dinajpur",
      "City": "islampur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "uttar dinajpur",
      "City": "karandighi"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "Domjur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "shyampur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "udaynarayanpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "jalpaiguri",
      "City": "mal"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "chowringhee"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "entally"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "malda",
      "City": "gajol"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "malda",
      "City": "kharba"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "barwan"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "Berhampore"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "murshidabad",
      "City": "Jangipur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Rajarhat"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "pashchim medinipu",
      "City": "nandanpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "keshiari"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "kharagpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "narghat"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "Tamluk"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purulia",
      "City": "purulia"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Maheshtala"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "uttar dinajpur",
      "City": "goalpokhar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "howrah",
      "City": "Jagatballavpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "jorabagan"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "kolkatta",
      "City": "Manicktala"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "malda",
      "City": "kaliachak"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "malda",
      "City": "ratua"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "nadia",
      "City": "Chapra"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Baduria"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Baranagar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Basirhat"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Hingalganj"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Jagatdal"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "north24-parganas",
      "City": "Kamarhati"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "gopiballavpur"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "khajuri"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purbo medinpur",
      "City": "sutahata"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purulia",
      "City": "Bandwan"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "purulia",
      "City": "Jhalda"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Bhangar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "patharpratima"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "south 24 parganas",
      "City": "Sagar"
    },
    {
      "Country": "INDIA",
      "State": "west bengal",
      "District": "uttar dinajpur",
      "City": "raiganj"
    }
  ];
