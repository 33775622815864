import React, { useState } from "react";
import MidBanner from "../../assets/donation_mid_banner.jpg";
import DonationChart from "../../assets/Donation_chart.jpg";
import MidBanner2 from '../../assets/mid_banner_2.jpg';
import "./HomePage.css";
import { bloodGropups, statesandcities } from "../../Constants/Constants";

const HomePage = () => {
  const [state, setState] = useState('');
  const [distvalues, setDistvalues] = useState([]);
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [cityvalues, setCityValues] = useState('');

  const [dnrstate, setDnrState] = useState('');
  const [dnrdistvalues, setDnrDistvalues] = useState([]);
  const [dnrdistrict, setDnrDistrict] = useState('');
  const [dnrcity, setDnrCity] = useState('');
  const [dnrcityvalues, setDnrCityValues] = useState('');

  const [reqstate, setReqState] = useState('');
  const [reqdistvalues, setReqDistvalues] = useState([]);
  const [reqdistrict, setReqDistrict] = useState('');
  const [reqcity, setReqCity] = useState('');
  const [reqcityvalues, setReqCityValues] = useState('');

  const handleStateSelected = (e) => {
    setState(e.target.value);
    setDistvalues([...new Set(statesandcities.filter(el => el.State === e.target.value).map( el => el.District))]);
  }

  const handleDistrictSelected = (e) => {
    setDistrict(e.target.value);
    setCityValues([...new Set(statesandcities.filter(el => el.District === e.target.value).map( el => el.City))]);
  }
  const handleCitySelected = (e) => {
    setCity(e.target.value)
  }

  const handleDnrStateSelected = (e) => {
    setDnrState(e.target.value);
    setDnrDistvalues([...new Set(statesandcities.filter(el => el.State === e.target.value).map( el => el.District))]);
  }

  const handleDnrDistrictSelected = (e) => {
    setDnrDistrict(e.target.value);
    setDnrCityValues([...new Set(statesandcities.filter(el => el.District === e.target.value).map( el => el.City))]);
  }

  const handleDnrCitySelected = (e) => {
    setDnrCity(e.target.value)
  }

  const handleReqStateSelected = (e) => {
    setReqState(e.target.value);
    setReqDistvalues([...new Set(statesandcities.filter(el => el.State === e.target.value).map( el => el.District))]);
  }

  const handleReqDistrictSelected = (e) => {
    setReqDistrict(e.target.value);
    setReqCityValues([...new Set(statesandcities.filter(el => el.District === e.target.value).map( el => el.City))]);
  }

  const handleReqCitySelected = (e) => {
    setReqCity(e.target.value)
  }

  return (
    <>
      <div className="top-Banner">
        <div className="enquiry-form">
          <div className="form">
            <div className="form-element">
              <select>
                <option value="0">Select Blood Group</option>
                {bloodGropups.map((grp, ind) => (<option key={ind} value={grp}>{grp}</option>) )}
              </select>
            </div>
            <div className="form-element">
              <select onChange={ e => handleStateSelected(e)}>
                <option value="0">Select State</option>
                {[...new Set(statesandcities.map(el => el.State))].map((state, ind) => (<option key={ind} value={state}>{state}</option>) )}
              </select>
            </div>
            <div className="form-element">
              <select onChange={ e => handleDistrictSelected(e)}>
                <option value="0">Select District</option>
                { distvalues && distvalues.length > 0 && distvalues.map((distr, index) => (<option key={index} value={distr}>{distr}</option>))}
              </select>
            </div>
            <div className="form-element">
              <select onChange={e => handleCitySelected(e)}>
                <option value="0">Select City</option>
                { cityvalues && cityvalues.length > 0 && cityvalues.map((city, index) => (<option key={index} value={city}>{city}</option>)) } 
              </select>
            </div>
            <div className="form-element">
              <button className="btn-submit">Search Members</button>
            </div>
          </div>
        </div>
      </div>
      <div className="latest-request">
        <div className="requests">
          <h4>Blood Requests</h4>
        </div>
        <div className="donoruse">
          <h3>Blood Donation</h3>
          <p>
            Thank you for considering becoming a blood donor and making a
            life-saving difference in someone's life. Every drop of blood you
            donate can save up to three lives, making you a hero in the eyes of
            those in need. Join us in our mission to ensure a steady and
            reliable blood supply for patients in hospitals and medical
            facilities.
          </p>
          <p>
            <b>Why Donate Blood?</b>
            <br /> <br />
            <b>Save Lives:</b> Your blood donation could be the difference
            between life and death for someone in need of blood transfusion due
            to injury, surgery, or illness.
            <br />
            <b>Community Impact:</b> By donating blood, you directly contribute
            to the well-being of your community, helping patients of all ages
            and backgrounds.
            <br />
            <b>Health Benefits:</b> Regular blood donation has been shown to
            have health benefits for the donor, including reducing the risk of
            certain illnesses and promoting the production of new blood cells.
            <br />
            <b>Emergency Preparedness:</b> Blood is needed in emergencies, and
            having a robust blood supply ensures that hospitals are prepared to
            handle unforeseen situations.
            <br />
          </p>
        </div>
      </div>
      <img src={MidBanner} alt="Mid Banner" width="100%" height="60%" />
      <div className="form-requests">
        <div className="donorform">
          <h2> Register As Donor</h2>
          <div className="form-request">
            <div className="form-elements">
              <select className="select-item">
                <option value="0">Select Blood Group</option>
                {bloodGropups.map((grp, ind) => (<option key={ind} value={grp}>{grp}</option>) )}
              </select>
            </div>
            <div className="form-elements">
              <select className="select-item" onChange={ e => handleDnrStateSelected(e)}>
                <option value="0">Select State</option>
                {[...new Set(statesandcities.map(el => el.State))].map((state, ind) => (<option key={ind} value={state}>{state}</option>) )}
              </select>
            </div>
            <div className="form-elements">
              <select className="select-item" onChange={ e => handleDnrDistrictSelected(e)}>
                <option value="0">Select District</option>
                { dnrdistvalues && dnrdistvalues.length > 0 && dnrdistvalues.map((distr, index) => (<option key={index} value={distr}>{distr}</option>))}
              </select>
            </div>
            <div className="form-elements">
              <select className="select-item" onChange={e => handleDnrCitySelected(e)}>
                <option value="0">Select City</option>
                { dnrcityvalues && dnrcityvalues.length > 0 && dnrcityvalues.map((city, index) => (<option key={index} value={city}>{city}</option>)) } 
              </select>
            </div>
            <div className="form-elements">
              <input
                className="input-item"
                type="text"
                placeholder="Full Name"
              />
            </div>
            <div className="form-elements">
              <input
                className="input-item"
                type="text"
                placeholder="Phone Number"
              />
            </div>
            <div className="form-elements">
              <input
                className="input-item"
                type="text"
                placeholder="Email Id"
              />
            </div>
            <div className="form-elements">
              <button className="btn-submit">Become A Hero</button>
            </div>
          </div>
        </div>
        <div className="donation-eligibility">
          <h4>Who Can Donate?</h4>
          <p style={{paddingLeft : '20px'}}>
            Almost anyone can donate blood, provided they meet the following
            criteria:
            <ul>
              <li>
                Be at least 17 years old (16-year-olds may donate with parental
                consent in some areas)
              </li>
              <li>Weigh at least 50 kgs</li>
              <li>Be in good general health</li>
              <li>Not have donated blood within the last 56 days</li>
              <li>
                Not have certain medical conditions or infections 
              </li>
            </ul>
            <p>Tips for a Successful Donation</p>
            <ul>
            <li><b>Stay Hydrated:</b> Drink plenty of fluids in the days leading up to your donation.</li>
            <li><b>Eat Well:</b> Have a nutritious meal before donating to keep your energy levels up.</li>
            <li><b>Get Plenty of Rest:</b> Make sure you're well-rested before donating.</li>
            <li><b>Wear Comfortable Clothing:</b> Opt for loose-fitting clothing with sleeves that can be easily rolled up.</li>
            </ul>
          </p>
        </div>
      </div>
      <img src={MidBanner2} alt="Mid Banner" width="100%" height="60%" />
      <div className="form-requests">
        <div className="donorform">
          <img
            src={DonationChart}
            alt="Donation Chart"
            width="90%"
            height="90%"
          />
        </div>
        <div className="requestform">
          <h2> Request for Blood</h2>
          <div className="form-request">
            <div className="form-elements">
              <select className="select-item">
                <option value="0">Select Blood Group</option>
                {bloodGropups.map((grp, ind) => (<option key={ind} value={grp}>{grp}</option>) )}
              </select>
            </div>
            <div className="form-elements">
              <select className="select-item" onChange={ e => handleReqStateSelected(e)}>
                <option value="0">Select State</option>
                {[...new Set(statesandcities.map(el => el.State))].map((state, ind) => (<option key={ind} value={state}>{state}</option>) )}
              </select>
            </div>
            <div className="form-elements">
              <select className="select-item" onChange={ e => handleReqDistrictSelected(e)}>
                <option value="0">Select District</option>
                { reqdistvalues && reqdistvalues.length > 0 && reqdistvalues.map((distr, index) => (<option key={index} value={distr}>{distr}</option>))}
              </select>
            </div>
            <div className="form-elements">
              <select className="select-item" onChange={e => handleReqCitySelected(e)}>
                <option value="0">Select City</option>
                { reqcityvalues && reqcityvalues.length > 0 && reqcityvalues.map((city, index) => (<option key={index} value={city}>{city}</option>)) } 
              </select>
            </div>
            <div className="form-elements">
              <input
                className="input-item"
                type="text"
                placeholder="Full Name"
              />
            </div>
            <div className="form-elements">
              <input
                className="input-item"
                type="text"
                placeholder="Phone Number"
              />
            </div>
            <div className="form-elements">
              <button className="btn-submit">Place Request For Blood</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
