import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
};

  return (
    <header className="header">
      <nav className="nav">
        <div className="logo">
          <Link to="/"><img src="images/blood_logo.png" width="30%" style={{padding: '1rem'}} alt="logo"/></Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          &#9776;
        </div>
        <ul className={`menu ${menuOpen ? 'active' : ''}`}>
            <li><Link className="btn-menu" to="/" onClick={closeMenu}>Home</Link></li>
            <li><Link className="btn-menu" to="/bloodbank" onClick={closeMenu}>Blood Banks</Link></li>
            <li><Link className="btn-menu" to="/organ" onClick={closeMenu}>Organ Donation</Link></li>
            <li><Link className="btn-menu" to="/hair" onClick={closeMenu}>Hair Donation</Link></li>
            <li><Link className="btn-menu" to="/ambulance" onClick={closeMenu}>Ambulance</Link></li>
            <li><Link className="btn-menu" to="/contact" onClick={closeMenu}>Contact Us</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
