import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="container">
      <h1>About Us</h1>
      <p>
        Welcome to our website, a platform dedicated to connecting and
        supporting those in need with compassionate donors. We are committed to
        fostering a community where individuals can make a meaningful impact
        through acts of kindness, such as donating blood, organs, hair, and
        providing critical information about ambulance services.
      </p>

      <div className="mission">
        <h2>Our Mission</h2>
        <p>
          Our mission is to create a reliable and accessible resource for people
          seeking and offering help in critical times. We strive to bridge the
          gap between those who want to donate and those in need by providing
          accurate information, secure registration, and a supportive network
          that promotes life-saving donations.
        </p>
      </div>

      <div className="vision">
        <h2>Our Vision</h2>
        <p>
          We envision a world where no one has to suffer due to a lack of
          accessible healthcare resources. By empowering individuals with the
          knowledge and means to contribute, we aim to build a more connected,
          caring, and responsive community. Our platform aspires to be the
          leading online destination for donation-related services, bringing
          hope and healing to people across the globe.
        </p>
      </div>

      <div className="values">
        <h2>Our Values</h2>
        <p>
          Our core values guide every decision we make and every action we take:
        </p>
        <ul>
          <li>
            <strong>Compassion:</strong> We believe in the power of empathy and
            the importance of helping others in need.
          </li>
          <li>
            <strong>Integrity:</strong> We are committed to honesty,
            transparency, and ethical practices in all our operations.
          </li>
          <li>
            <strong>Inclusivity:</strong> We welcome everyone, regardless of
            their background, and strive to create an inclusive community where
            everyone can contribute and benefit.
          </li>
          <li>
            <strong>Empowerment:</strong> We empower individuals to make
            informed decisions about their health and contributions to society.
          </li>
          <li>
            <strong>Innovation:</strong> We continuously seek innovative
            solutions to improve our services and enhance the user experience.
          </li>
        </ul>
      </div>

      <h2>Our Story</h2>
      <p>
        Founded with a passion for helping others, our platform was created to
        address the growing need for a centralized hub for donation-related
        services. Our team is made up of dedicated professionals with diverse
        backgrounds in healthcare, technology, and community outreach. Together,
        we have built a space where giving and receiving help is simplified and
        secure.
      </p>

      <p>
        As we continue to grow, we remain focused on our mission to provide
        reliable and compassionate support to those in need. We believe that by
        working together, we can create a healthier, more connected world where
        everyone has access to the care and resources they need.
      </p>

      <p>
        Thank you for being a part of our journey. Whether you are here to
        donate, learn, or seek assistance, we are here to support you every step
        of the way.
      </p>

      <p>
        <em>— The Team at [Your Website Name]</em>
      </p>
    </div>
  );
};

export default About;
