import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <nav className="nav-footer">
        <p>Copyrights © 2023 - 2024, All Rights Reserved. Site Designed & Maintained By GPK Techno Solutions</p>
        <ul className="menu">
          <li><Link to='/about' className="btn-menu">About Us</Link></li>
          <li> <Link to='/disclaimer' className="btn-menu">Disclaimer</Link></li>
          <li> <Link to='/privacy' className="btn-menu">Privacy Policy</Link></li>
          <li> <Link to='/faq' className="btn-menu">FAQ's</Link></li>
          <li> <Link to='/contact' className="btn-menu">Contact Us</Link></li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
