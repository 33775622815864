import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
// import HomePage from "./Components/Home/HomePage";
import MarqueeComp from "./Components/Marquee/MarqueeComp";
import { HashRouter as Router } from "react-router-dom";
import RouterPages from "./Routers/RouterPages";

function App() {
  return (
    <Router>
      <Header />
      <MarqueeComp />
      <RouterPages />
      <Footer />
    </Router>
  );
}

export default App;
