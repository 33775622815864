import React from 'react'
import {Routes, Route}  from 'react-router-dom'
import HomePage from '../Components/Home/HomePage'
import BloodBank from '../Components/BloodBank/BloodBank'
import Organ from '../Components/Organ/Organ'
import Hair from '../Components/Hair/Hair'
import Contact from '../Components/Contact/Contact'
import Disclaimer from '../Components/Disclaimer/Disclaimer'
import Faq from '../Components/Faq/Faq'
import Privacy from '../Components/Privacy/Privacy'
import About from '../Components/About/About'
import Ambulance from '../Components/Ambulance/Ambulance'

const RouterPages = () => {
  return (
    <Routes>
        <Route exact path="/" element={<HomePage />}/>
        <Route path='/bloodbank' element={<BloodBank />}/>
        <Route path='/organ' element={<Organ />}/>
        <Route path='/hair' element={<Hair />}/>
        <Route path='/contact' element={<Contact />}/>
        <Route path='/disclaimer' element={<Disclaimer />}/>
        <Route path='/faq' element={<Faq />}/>
        <Route path='/privacy' element={<Privacy />}/>
        <Route path='/about' element={<About />} />
        <Route path='/ambulance' element={<Ambulance />} />
    </Routes>
  )
}

export default RouterPages