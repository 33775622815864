import React from 'react'
import './Faq.css'

const Faq = () => {
  return (
    <div class="container">
        <h1>Frequently Asked Questions (FAQ)</h1>

        <div class="faq-item">
            <h2>1. How can I register as a blood donor?</h2>
            <p>You can register as a blood donor by visiting our registration page, filling out the form with your details, and submitting it. We will then add your information to our donor database.</p>
        </div>

        <div class="faq-item">
            <h2>2. Who can donate blood?</h2>
            <p>Generally, anyone who is healthy, weighs at least 50 kg, and is between 18 and 65 years old can donate blood. However, there are specific eligibility criteria based on health and medical history. Please consult with a medical professional or refer to our blood donation guidelines for more details.</p>
        </div>

        <div class="faq-item">
            <h2>3. How do I request information about organ donation?</h2>
            <p>To request information about organ donation, you can visit our organ donation page or contact us directly through our contact form. We provide detailed information on the process, benefits, and how to become an organ donor.</p>
        </div>

        <div class="faq-item">
            <h2>4. Can I donate hair if it is colored or chemically treated?</h2>
            <p>Hair donation policies vary depending on the organization. Some organizations accept colored or chemically treated hair, while others do not. Please check the specific requirements of the organization you wish to donate to.</p>
        </div>

        <div class="faq-item">
            <h2>5. How do I find an ambulance service in my area?</h2>
            <p>Our website provides information on various ambulance services available in different regions. You can search for ambulance services in your area using our ambulance services page or contact local hospitals and emergency services.</p>
        </div>

        <div class="faq-item">
            <h2>6. How is my personal information protected?</h2>
            <p>We take your privacy seriously and use various security measures to protect your personal information. For more details, please refer to our Privacy Policy page.</p>
        </div>

        <div class="faq-item">
            <h2>7. How can I update or delete my information from your database?</h2>
            <p>If you wish to update or delete your information from our database, please contact us through our contact page, and we will assist you with your request.</p>
        </div>

        <div class="faq-item">
            <h2>8. Can I cancel my blood donor registration?</h2>
            <p>Yes, you can cancel your blood donor registration at any time. Please contact us with your details, and we will remove your information from our database.</p>
        </div>

        <p>If you have any other questions not covered in this FAQ, please contact us through our website's contact page.</p>
    </div>
  )
}

export default Faq