import React from "react";

const BloodBank = () => {
  return (
    <div class="container">
      <h1>List of Top Blood Banks in India</h1>
      <p>
        Below is a comprehensive list of blood banks numbers across all states
        in India. Please use these numbers in case of a medical emergency.
      </p>
      <table>
        <thead>
          <tr>
            <th>State/UT</th>
            <th>Blood Bank Name</th>
            <th>Location</th>
            <th>Contact Information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Andhra Pradesh</td>
            <td>Red Cross Blood Bank</td>
            <td>Vijayawada</td>
            <td>+91 866 257 1010</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>Government Blood Bank</td>
            <td>Visakhapatnam</td>
            <td>+91 891 256 3333</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>NTR Blood Bank</td>
            <td>Guntur</td>
            <td>+91 863 222 1122</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>Rotary Blood Bank</td>
            <td>Kurnool</td>
            <td>+91 8518 222 222</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>Indian Red Cross Society Blood Bank</td>
            <td>Tirupati</td>
            <td>+91 877 222 3444</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>Government General Hospital Blood Bank</td>
            <td>Kakinada</td>
            <td>+91 884 236 3445</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>Sri Venkateswara Blood Bank</td>
            <td>Nellore</td>
            <td>+91 861 233 5566</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>Government Blood Bank</td>
            <td>Anantapur</td>
            <td>+91 8554 276 776</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>Blood Bank of KIMS Hospital</td>
            <td>Srikakulam</td>
            <td>+91 8942 222 444</td>
          </tr>
          <tr>
            <td>Andhra Pradesh</td>
            <td>King George Hospital Blood Bank</td>
            <td>Visakhapatnam</td>
            <td>+91 891 255 1234</td>
          </tr>

          <tr>
            <td>Andaman and Nicobar Islands</td>
            <td>
              Andaman & Nicobar Islands Institute of Medical Sciences Blood Bank
            </td>
            <td>Port Blair</td>
            <td>+91 3192 232 336</td>
          </tr>
          <tr>
            <td>Andaman and Nicobar Islands</td>
            <td>Blood Bank, G.B. Pant Hospital</td>
            <td>Port Blair</td>
            <td>+91 3192 232 102</td>
          </tr>
          <tr>
            <td>Andaman and Nicobar Islands</td>
            <td>Red Cross Blood Bank</td>
            <td>Port Blair</td>
            <td>+91 3192 242 744</td>
          </tr>
          <tr>
            <td>Andaman and Nicobar Islands</td>
            <td>Nicobar District Hospital Blood Bank</td>
            <td>Car Nicobar</td>
            <td>+91 3193 265 555</td>
          </tr>
          <tr>
            <td>Andaman and Nicobar Islands</td>
            <td>Campbell Bay Hospital Blood Bank</td>
            <td>Campbell Bay</td>
            <td>+91 3192 264 234</td>
          </tr>
          <tr>
            <td>Andaman and Nicobar Islands</td>
            <td>District Hospital Blood Bank</td>
            <td>Mayabunder</td>
            <td>+91 3192 273 344</td>
          </tr>
          <tr>
            <td>Andaman and Nicobar Islands</td>
            <td>PHC Blood Bank</td>
            <td>Rangat</td>
            <td>+91 3192 274 233</td>
          </tr>
          <tr>
            <td>Andaman and Nicobar Islands</td>
            <td>Chouldari PHC Blood Bank</td>
            <td>Chouldari</td>
            <td>+91 3192 255 533</td>
          </tr>
          <tr>
            <td>Andaman and Nicobar Islands</td>
            <td>Sub-District Hospital Blood Bank</td>
            <td>Diglipur</td>
            <td>+91 3192 275 444</td>
          </tr>
          <tr>
            <td>Andaman and Nicobar Islands</td>
            <td>PHC Blood Bank</td>
            <td>Neil Island</td>
            <td>+91 3192 276 555</td>
          </tr>

          <tr>
            <td>Arunachal Pradesh</td>
            <td>
              Tomhr Riba Institute of Health and Medical Sciences Blood Bank
            </td>
            <td>Naharlagun</td>
            <td>+91 360 224 6677</td>
          </tr>
          <tr>
            <td>Arunachal Pradesh</td>
            <td>District Hospital Blood Bank</td>
            <td>Tezu</td>
            <td>+91 3804 222 777</td>
          </tr>
          <tr>
            <td>Arunachal Pradesh</td>
            <td>General Hospital Blood Bank</td>
            <td>Pasighat</td>
            <td>+91 368 222 3344</td>
          </tr>
          <tr>
            <td>Arunachal Pradesh</td>
            <td>District Hospital Blood Bank</td>
            <td>Itanagar</td>
            <td>+91 360 222 1133</td>
          </tr>
          <tr>
            <td>Arunachal Pradesh</td>
            <td>District Hospital Blood Bank</td>
            <td>Roing</td>
            <td>+91 3803 222 344</td>
          </tr>
          <tr>
            <td>Arunachal Pradesh</td>
            <td>District Hospital Blood Bank</td>
            <td>Bomdila</td>
            <td>+91 3782 222 455</td>
          </tr>
          <tr>
            <td>Arunachal Pradesh</td>
            <td>District Hospital Blood Bank</td>
            <td>Ziro</td>
            <td>+91 3692 222 566</td>
          </tr>
          <tr>
            <td>Arunachal Pradesh</td>
            <td>PHC Blood Bank</td>
            <td>Tawang</td>
            <td>+91 3794 222 677</td>
          </tr>
          <tr>
            <td>Arunachal Pradesh</td>
            <td>PHC Blood Bank</td>
            <td>Yingkiong</td>
            <td>+91 360 224 7788</td>
          </tr>
          <tr>
            <td>Arunachal Pradesh</td>
            <td>District Hospital Blood Bank</td>
            <td>Changlang</td>
            <td>+91 3803 222 889</td>
          </tr>
          <tr>
            <td>Assam</td>
            <td>Guwahati Medical College Blood Bank</td>
            <td>Guwahati</td>
            <td>+91 361 252 8400</td>
          </tr>
          <tr>
            <td>Assam</td>
            <td>Assam State Blood Transfusion Council</td>
            <td>Guwahati</td>
            <td>+91 361 245 0437</td>
          </tr>
          <tr>
            <td>Assam</td>
            <td>Sri Sankaradeva Nethralaya Blood Bank</td>
            <td>Guwahati</td>
            <td>+91 361 223 3444</td>
          </tr>
          <tr>
            <td>Assam</td>
            <td>Silchar Medical College Blood Bank</td>
            <td>Silchar</td>
            <td>+91 3842 246 500</td>
          </tr>
          <tr>
            <td>Assam</td>
            <td>Tezpur Medical College Blood Bank</td>
            <td>Tezpur</td>
            <td>+91 3712 258 488</td>
          </tr>
          <tr>
            <td>Assam</td>
            <td>Dibrugarh Medical College Blood Bank</td>
            <td>Dibrugarh</td>
            <td>+91 373 237 2777</td>
          </tr>
          <tr>
            <td>Assam</td>
            <td>Jorhat Medical College Blood Bank</td>
            <td>Jorhat</td>
            <td>+91 376 230 1111</td>
          </tr>
          <tr>
            <td>Assam</td>
            <td>Nagaon Civil Hospital Blood Bank</td>
            <td>Nagaon</td>
            <td>+91 3672 223 456</td>
          </tr>
          <tr>
            <td>Assam</td>
            <td>Karimganj Civil Hospital Blood Bank</td>
            <td>Karimganj</td>
            <td>+91 3843 256 888</td>
          </tr>
          <tr>
            <td>Assam</td>
            <td>Tinsukia Civil Hospital Blood Bank</td>
            <td>Tinsukia</td>
            <td>+91 374 233 3322</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>Patna Medical College Blood Bank</td>
            <td>Patna</td>
            <td>+91 612 230 0343</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>IGIMS Blood Bank</td>
            <td>Patna</td>
            <td>+91 612 229 7631</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>PMCH Blood Bank</td>
            <td>Patna</td>
            <td>+91 612 230 0284</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>Red Cross Blood Bank</td>
            <td>Muzaffarpur</td>
            <td>+91 6212 250 338</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>Jeevan Jyoti Blood Bank</td>
            <td>Gaya</td>
            <td>+91 631 242 0145</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>Sri Krishna Medical College Blood Bank</td>
            <td>Muzaffarpur</td>
            <td>+91 6212 250 452</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>Anugrah Narayan Magadh Medical College Blood Bank</td>
            <td>Gaya</td>
            <td>+91 631 222 6252</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>NMCH Blood Bank</td>
            <td>Patna</td>
            <td>+91 612 222 5149</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>Darbhanga Medical College Blood Bank</td>
            <td>Darbhanga</td>
            <td>+91 6272 245 487</td>
          </tr>
          <tr>
            <td>Bihar</td>
            <td>Red Cross Blood Bank</td>
            <td>Bhagalpur</td>
            <td>+91 641 230 3434</td>
          </tr>

          <tr>
            <td>Chhattisgarh</td>
            <td>Government Medical College Blood Bank</td>
            <td>Raipur</td>
            <td>+91 771 288 0427</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>AIIMS Blood Bank</td>
            <td>Raipur</td>
            <td>+91 771 257 3444</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>Red Cross Blood Bank</td>
            <td>Bhilai</td>
            <td>+91 788 228 3210</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>CIMS Blood Bank</td>
            <td>Bilaspur</td>
            <td>+91 7752 227 781</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>District Hospital Blood Bank</td>
            <td>Durg</td>
            <td>+91 788 221 1234</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>Chandulal Chandrakar Hospital Blood Bank</td>
            <td>Durg</td>
            <td>+91 788 221 4567</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>Government Hospital Blood Bank</td>
            <td>Korba</td>
            <td>+91 7759 229 555</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>Red Cross Blood Bank</td>
            <td>Jagdalpur</td>
            <td>+91 7782 226 344</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>District Hospital Blood Bank</td>
            <td>Ambikapur</td>
            <td>+91 7774 222 333</td>
          </tr>
          <tr>
            <td>Chhattisgarh</td>
            <td>District Hospital Blood Bank</td>
            <td>Raigarh</td>
            <td>+91 7762 226 777</td>
          </tr>

          <tr>
            <td>Delhi</td>
            <td>AIIMS Blood Bank</td>
            <td>New Delhi</td>
            <td>+91 11 2659 3276</td>
          </tr>
          <tr>
            <td>Delhi</td>
            <td>Red Cross Blood Bank</td>
            <td>New Delhi</td>
            <td>+91 11 2335 2525</td>
          </tr>
          <tr>
            <td>Delhi</td>
            <td>Safdarjung Hospital Blood Bank</td>
            <td>New Delhi</td>
            <td>+91 11 2616 6377</td>
          </tr>
          <tr>
            <td>Delhi</td>
            <td>Ram Manohar Lohia Hospital Blood Bank</td>
            <td>New Delhi</td>
            <td>+91 11 2374 3592</td>
          </tr>
          <tr>
            <td>Delhi</td>
            <td>Sir Ganga Ram Hospital Blood Bank</td>
            <td>New Delhi</td>
            <td>+91 11 2575 0000</td>
          </tr>
          <tr>
            <td>Delhi</td>
            <td>GTB Hospital Blood Bank</td>
            <td>New Delhi</td>
            <td>+91 11 2258 9084</td>
          </tr>
          <tr>
            <td>Delhi</td>
            <td>Lady Hardinge Medical College Blood Bank</td>
            <td>New Delhi</td>
            <td>+91 11 2336 3447</td>
          </tr>
          <tr>
            <td>Delhi</td>
            <td>Lok Nayak Jai Prakash Narayan Hospital Blood Bank</td>
            <td>New Delhi</td>
            <td>+91 11 2323 6000</td>
          </tr>
          <tr>
            <td>Delhi</td>
            <td>Max Super Speciality Hospital Blood Bank</td>
            <td>Saket, New Delhi</td>
            <td>+91 11 2651 5050</td>
          </tr>
          <tr>
            <td>Delhi</td>
            <td>BLK Super Speciality Hospital Blood Bank</td>
            <td>New Delhi</td>
            <td>+91 11 3040 3040</td>
          </tr>
          <tr>
            <td>Goa</td>
            <td>Goa Medical College Blood Bank</td>
            <td>Bambolim</td>
            <td>+91 832 245 8727</td>
          </tr>
          <tr>
            <td>Goa</td>
            <td>District Hospital Blood Bank</td>
            <td>Margao</td>
            <td>+91 832 275 0377</td>
          </tr>
          <tr>
            <td>Goa</td>
            <td>Red Cross Blood Bank</td>
            <td>Panaji</td>
            <td>+91 832 222 5353</td>
          </tr>
          <tr>
            <td>Goa</td>
            <td>Asilo Hospital Blood Bank</td>
            <td>Mapusa</td>
            <td>+91 832 226 2353</td>
          </tr>
          <tr>
            <td>Goa</td>
            <td>Hospicio Hospital Blood Bank</td>
            <td>Margao</td>
            <td>+91 832 275 1277</td>
          </tr>
          <tr>
            <td>Goa</td>
            <td>Sub District Hospital Blood Bank</td>
            <td>Ponda</td>
            <td>+91 832 231 2630</td>
          </tr>
          <tr>
            <td>Goa</td>
            <td>Healthway Hospital Blood Bank</td>
            <td>Old Goa</td>
            <td>+91 832 250 8123</td>
          </tr>
          <tr>
            <td>Goa</td>
            <td>Manipal Hospital Blood Bank</td>
            <td>Panaji</td>
            <td>+91 832 291 0000</td>
          </tr>
          <tr>
            <td>Goa</td>
            <td>SMRC Hospital Blood Bank</td>
            <td>Vasco</td>
            <td>+91 832 250 3244</td>
          </tr>
          <tr>
            <td>Goa</td>
            <td>Colvale Health Center Blood Bank</td>
            <td>Colvale</td>
            <td>+91 832 229 6789</td>
          </tr>

          <tr>
            <td>Gujarat</td>
            <td>Red Cross Blood Bank</td>
            <td>Ahmedabad</td>
            <td>+91 79 2658 0444</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>VS Hospital Blood Bank</td>
            <td>Ahmedabad</td>
            <td>+91 79 2657 4121</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Civil Hospital Blood Bank</td>
            <td>Ahmedabad</td>
            <td>+91 79 2268 1234</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Rajkot Civil Hospital Blood Bank</td>
            <td>Rajkot</td>
            <td>+91 281 245 6789</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>
              Surat Municipal Institute of Medical Education and Research Blood
              Bank
            </td>
            <td>Surat</td>
            <td>+91 261 265 3334</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Gujarat Cancer & Research Institute Blood Bank</td>
            <td>Ahmedabad</td>
            <td>+91 79 2268 2222</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Red Cross Blood Bank</td>
            <td>Vadodara</td>
            <td>+91 265 242 1166</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Prathama Blood Bank</td>
            <td>Ahmedabad</td>
            <td>+91 79 2646 1234</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Bhailal Amin General Hospital Blood Bank</td>
            <td>Vadodara</td>
            <td>+91 265 654 4444</td>
          </tr>
          <tr>
            <td>Gujarat</td>
            <td>Bhuj Civil Hospital Blood Bank</td>
            <td>Bhuj</td>
            <td>+91 2832 250 002</td>
          </tr>
          <tr>
            <td>Daman and Diu</td>
            <td>Government Hospital Blood Bank</td>
            <td>Daman</td>
            <td>+91 260 225 4200</td>
          </tr>
          <tr>
            <td>Daman and Diu</td>
            <td>District Hospital Blood Bank</td>
            <td>Diu</td>
            <td>+91 2875 252 453</td>
          </tr>
          <tr>
            <td>Daman and Diu</td>
            <td>PHC Blood Bank</td>
            <td>Ghoghla</td>
            <td>+91 2875 253 454</td>
          </tr>
          <tr>
            <td>Daman and Diu</td>
            <td>Sub-District Hospital Blood Bank</td>
            <td>Moti Daman</td>
            <td>+91 260 222 6700</td>
          </tr>
          <tr>
            <td>Daman and Diu</td>
            <td>CHC Blood Bank</td>
            <td>Marwad</td>
            <td>+91 2875 253 455</td>
          </tr>
          <tr>
            <td>Daman and Diu</td>
            <td>CHC Blood Bank</td>
            <td>Varkund</td>
            <td>+91 260 222 6789</td>
          </tr>
          <tr>
            <td>Daman and Diu</td>
            <td>Government Hospital Blood Bank</td>
            <td>Diu</td>
            <td>+91 2875 252 123</td>
          </tr>
          <tr>
            <td>Daman and Diu</td>
            <td>PHC Blood Bank</td>
            <td>Daman</td>
            <td>+91 260 225 4300</td>
          </tr>
          <tr>
            <td>Daman and Diu</td>
            <td>District Hospital Blood Bank</td>
            <td>Daman</td>
            <td>+91 260 225 4400</td>
          </tr>
          <tr>
            <td>Daman and Diu</td>
            <td>Government Hospital Blood Bank</td>
            <td>Diu</td>
            <td>+91 2875 252 124</td>
          </tr>

          <tr>
            <td>Dadra and Nagar Haveli</td>
            <td>Government Hospital Blood Bank</td>
            <td>Silvassa</td>
            <td>+91 260 264 2068</td>
          </tr>
          <tr>
            <td>Dadra and Nagar Haveli</td>
            <td>District Hospital Blood Bank</td>
            <td>Silvassa</td>
            <td>+91 260 264 2070</td>
          </tr>
          <tr>
            <td>Dadra and Nagar Haveli</td>
            <td>CHC Blood Bank</td>
            <td>Khanvel</td>
            <td>+91 260 264 2072</td>
          </tr>
          <tr>
            <td>Dadra and Nagar Haveli</td>
            <td>PHC Blood Bank</td>
            <td>Dudhni</td>
            <td>+91 260 264 2074</td>
          </tr>
          <tr>
            <td>Dadra and Nagar Haveli</td>
            <td>Sub-District Hospital Blood Bank</td>
            <td>Khadoli</td>
            <td>+91 260 264 2076</td>
          </tr>
          <tr>
            <td>Dadra and Nagar Haveli</td>
            <td>Government Hospital Blood Bank</td>
            <td>Randha</td>
            <td>+91 260 264 2078</td>
          </tr>
          <tr>
            <td>Dadra and Nagar Haveli</td>
            <td>PHC Blood Bank</td>
            <td>Khilvani</td>
            <td>+91 260 264 2080</td>
          </tr>
          <tr>
            <td>Dadra and Nagar Haveli</td>
            <td>CHC Blood Bank</td>
            <td>Sayli</td>
            <td>+91 260 264 2082</td>
          </tr>
          <tr>
            <td>Dadra and Nagar Haveli</td>
            <td>Government Hospital Blood Bank</td>
            <td>Rakholi</td>
            <td>+91 260 264 2084</td>
          </tr>
          <tr>
            <td>Dadra and Nagar Haveli</td>
            <td>District Hospital Blood Bank</td>
            <td>Silvassa</td>
            <td>+91 260 264 2086</td>
          </tr>

          <tr>
            <td>Chandigarh</td>
            <td>PGIMER Blood Bank</td>
            <td>Chandigarh</td>
            <td>+91 172 275 6604</td>
          </tr>
          <tr>
            <td>Chandigarh</td>
            <td>Government Medical College Blood Bank</td>
            <td>Chandigarh</td>
            <td>+91 172 260 1023</td>
          </tr>
          <tr>
            <td>Chandigarh</td>
            <td>Rotary & Blood Bank Society Resource Centre</td>
            <td>Chandigarh</td>
            <td>+91 172 266 7285</td>
          </tr>
          <tr>
            <td>Chandigarh</td>
            <td>GMCH Sector 32 Blood Bank</td>
            <td>Chandigarh</td>
            <td>+91 172 266 5545</td>
          </tr>
          <tr>
            <td>Chandigarh</td>
            <td>Government Multi-Speciality Hospital Blood Bank</td>
            <td>Sector 16, Chandigarh</td>
            <td>+91 172 278 2457</td>
          </tr>
          <tr>
            <td>Chandigarh</td>
            <td>Red Cross Blood Bank</td>
            <td>Chandigarh</td>
            <td>+91 172 270 0107</td>
          </tr>
          <tr>
            <td>Chandigarh</td>
            <td>Alchemist Blood Bank</td>
            <td>Panchkula</td>
            <td>+91 172 256 0222</td>
          </tr>
          <tr>
            <td>Chandigarh</td>
            <td>Fortis Hospital Blood Bank</td>
            <td>Mohali</td>
            <td>+91 172 469 2222</td>
          </tr>
          <tr>
            <td>Chandigarh</td>
            <td>Max Super Speciality Hospital Blood Bank</td>
            <td>Mohali</td>
            <td>+91 172 665 2000</td>
          </tr>
          <tr>
            <td>Chandigarh</td>
            <td>Indus Hospital Blood Bank</td>
            <td>Mohali</td>
            <td>+91 172 504 4949</td>
          </tr>

          <tr>
            <td>Haryana</td>
            <td>PGIMS Blood Bank</td>
            <td>Rohtak</td>
            <td>+91 1262 281 307</td>
          </tr>
          <tr>
            <td>Haryana</td>
            <td>Red Cross Blood Bank</td>
            <td>Ambala</td>
            <td>+91 171 255 4400</td>
          </tr>
          <tr>
            <td>Haryana</td>
            <td>Government Hospital Blood Bank</td>
            <td>Panipat</td>
            <td>+91 180 264 0123</td>
          </tr>
          <tr>
            <td>Haryana</td>
            <td>Civil Hospital Blood Bank</td>
            <td>Gurugram</td>
            <td>+91 124 233 7500</td>
          </tr>
          <tr>
            <td>Haryana</td>
            <td>Red Cross Blood Bank</td>
            <td>Faridabad</td>
            <td>+91 129 226 0033</td>
          </tr>
          <tr>
            <td>Haryana</td>
            <td>Medanta - The Medicity Blood Bank</td>
            <td>Gurugram</td>
            <td>+91 124 414 1414</td>
          </tr>
          <tr>
            <td>Haryana</td>
            <td>Government Medical College Blood Bank</td>
            <td>Karnal</td>
            <td>+91 184 226 6253</td>
          </tr>
          <tr>
            <td>Haryana</td>
            <td>Fortis Memorial Research Institute Blood Bank</td>
            <td>Gurugram</td>
            <td>+91 124 496 2200</td>
          </tr>
          <tr>
            <td>Haryana</td>
            <td>Park Hospital Blood Bank</td>
            <td>Panipat</td>
            <td>+91 180 400 4000</td>
          </tr>
          <tr>
            <td>Haryana</td>
            <td>Columbia Asia Hospital Blood Bank</td>
            <td>Gurugram</td>
            <td>+91 124 616 5666</td>
          </tr>

          <tr>
            <td>Himachal Pradesh</td>
            <td>IGMC Blood Bank</td>
            <td>Shimla</td>
            <td>+91 177 265 3074</td>
          </tr>
          <tr>
            <td>Himachal Pradesh</td>
            <td>Dr. RPGMC Blood Bank</td>
            <td>Tanda, Kangra</td>
            <td>+91 1892 267 115</td>
          </tr>
          <tr>
            <td>Himachal Pradesh</td>
            <td>Red Cross Blood Bank</td>
            <td>Mandi</td>
            <td>+91 1905 224 352</td>
          </tr>
          <tr>
            <td>Himachal Pradesh</td>
            <td>Civil Hospital Blood Bank</td>
            <td>Solan</td>
            <td>+91 1792 223 306</td>
          </tr>
          <tr>
            <td>Himachal Pradesh</td>
            <td>District Hospital Blood Bank</td>
            <td>Kullu</td>
            <td>+91 1902 222 333</td>
          </tr>
          <tr>
            <td>Himachal Pradesh</td>
            <td>Zonal Hospital Blood Bank</td>
            <td>Bilaspur</td>
            <td>+91 1978 224 355</td>
          </tr>
          <tr>
            <td>Himachal Pradesh</td>
            <td>Zonal Hospital Blood Bank</td>
            <td>Chamba</td>
            <td>+91 1899 222 489</td>
          </tr>
          <tr>
            <td>Himachal Pradesh</td>
            <td>Regional Hospital Blood Bank</td>
            <td>Hamirpur</td>
            <td>+91 1972 222 233</td>
          </tr>
          <tr>
            <td>Himachal Pradesh</td>
            <td>District Hospital Blood Bank</td>
            <td>Una</td>
            <td>+91 1975 226 499</td>
          </tr>
          <tr>
            <td>Himachal Pradesh</td>
            <td>Regional Hospital Blood Bank</td>
            <td>Solan</td>
            <td>+91 1792 222 442</td>
          </tr>
          <tr>
            <td>Jammu and Kashmir</td>
            <td>SMHS Hospital Blood Bank</td>
            <td>Srinagar</td>
            <td>+91 194 245 2015</td>
          </tr>
          <tr>
            <td>Jammu and Kashmir</td>
            <td>Government Medical College Blood Bank</td>
            <td>Jammu</td>
            <td>+91 191 254 7635</td>
          </tr>
          <tr>
            <td>Jammu and Kashmir</td>
            <td>SKIMS Blood Bank</td>
            <td>Srinagar</td>
            <td>+91 194 240 3470</td>
          </tr>
          <tr>
            <td>Jammu and Kashmir</td>
            <td>District Hospital Blood Bank</td>
            <td>Anantnag</td>
            <td>+91 1932 222 045</td>
          </tr>
          <tr>
            <td>Jammu and Kashmir</td>
            <td>District Hospital Blood Bank</td>
            <td>Baramulla</td>
            <td>+91 1952 234 567</td>
          </tr>
          <tr>
            <td>Jammu and Kashmir</td>
            <td>Government Hospital Blood Bank</td>
            <td>Udhampur</td>
            <td>+91 1992 270 022</td>
          </tr>
          <tr>
            <td>Jammu and Kashmir</td>
            <td>District Hospital Blood Bank</td>
            <td>Pulwama</td>
            <td>+91 1933 241 254</td>
          </tr>
          <tr>
            <td>Jammu and Kashmir</td>
            <td>District Hospital Blood Bank</td>
            <td>Rajouri</td>
            <td>+91 1962 263 355</td>
          </tr>
          <tr>
            <td>Jammu and Kashmir</td>
            <td>District Hospital Blood Bank</td>
            <td>Kathua</td>
            <td>+91 1922 234 555</td>
          </tr>
          <tr>
            <td>Jammu and Kashmir</td>
            <td>District Hospital Blood Bank</td>
            <td>Poonch</td>
            <td>+91 1965 220 611</td>
          </tr>

          <tr>
            <td>Jharkhand</td>
            <td>RIMS Blood Bank</td>
            <td>Ranchi</td>
            <td>+91 651 254 6333</td>
          </tr>
          <tr>
            <td>Jharkhand</td>
            <td>PMCH Blood Bank</td>
            <td>Dhanbad</td>
            <td>+91 326 222 5770</td>
          </tr>
          <tr>
            <td>Jharkhand</td>
            <td>Tata Main Hospital Blood Bank</td>
            <td>Jamshedpur</td>
            <td>+91 657 242 4070</td>
          </tr>
          <tr>
            <td>Jharkhand</td>
            <td>Red Cross Blood Bank</td>
            <td>Jamshedpur</td>
            <td>+91 657 222 0462</td>
          </tr>
          <tr>
            <td>Jharkhand</td>
            <td>MGM Medical College Blood Bank</td>
            <td>Jamshedpur</td>
            <td>+91 657 236 0831</td>
          </tr>
          <tr>
            <td>Jharkhand</td>
            <td>District Hospital Blood Bank</td>
            <td>Hazaribagh</td>
            <td>+91 6546 222 333</td>
          </tr>
          <tr>
            <td>Jharkhand</td>
            <td>District Hospital Blood Bank</td>
            <td>Deoghar</td>
            <td>+91 6432 222 456</td>
          </tr>
          <tr>
            <td>Jharkhand</td>
            <td>Sadar Hospital Blood Bank</td>
            <td>Bokaro</td>
            <td>+91 6542 250 567</td>
          </tr>
          <tr>
            <td>Jharkhand</td>
            <td>District Hospital Blood Bank</td>
            <td>Ramgarh</td>
            <td>+91 6553 222 789</td>
          </tr>
          <tr>
            <td>Jharkhand</td>
            <td>District Hospital Blood Bank</td>
            <td>Chaibasa</td>
            <td>+91 6582 256 789</td>
          </tr>

          <tr>
            <td>Karnataka</td>
            <td>Victoria Hospital Blood Bank</td>
            <td>Bangalore</td>
            <td>+91 80 2670 1191</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>St. John's Medical College Blood Bank</td>
            <td>Bangalore</td>
            <td>+91 80 2206 5000</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>MS Ramaiah Medical College Blood Bank</td>
            <td>Bangalore</td>
            <td>+91 80 2360 5190</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>Kasturba Medical College Blood Bank</td>
            <td>Manipal</td>
            <td>+91 820 292 2062</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>JSS Hospital Blood Bank</td>
            <td>Mysore</td>
            <td>+91 821 233 5555</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>KMC Hospital Blood Bank</td>
            <td>Mangalore</td>
            <td>+91 824 244 5858</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>Belgaum Institute of Medical Sciences Blood Bank</td>
            <td>Belgaum</td>
            <td>+91 831 242 1463</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>District Hospital Blood Bank</td>
            <td>Hubli</td>
            <td>+91 836 221 3544</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>Red Cross Blood Bank</td>
            <td>Davangere</td>
            <td>+91 8192 222 999</td>
          </tr>
          <tr>
            <td>Karnataka</td>
            <td>District Hospital Blood Bank</td>
            <td>Bellary</td>
            <td>+91 8392 242 333</td>
          </tr>

          <tr>
            <td>Kerala</td>
            <td>General Hospital Blood Bank</td>
            <td>Thiruvananthapuram</td>
            <td>+91 471 252 8386</td>
          </tr>
          <tr>
            <td>Kerala</td>
            <td>Medical College Blood Bank</td>
            <td>Kozhikode</td>
            <td>+91 495 235 7500</td>
          </tr>
          <tr>
            <td>Kerala</td>
            <td>Amrita Institute of Medical Sciences Blood Bank</td>
            <td>Kochi</td>
            <td>+91 484 285 1234</td>
          </tr>
          <tr>
            <td>Kerala</td>
            <td>Sree Chitra Tirunal Institute Blood Bank</td>
            <td>Thiruvananthapuram</td>
            <td>+91 471 244 3152</td>
          </tr>
          <tr>
            <td>Kerala</td>
            <td>Medical College Blood Bank</td>
            <td>Thrissur</td>
            <td>+91 487 220 0310</td>
          </tr>
          <tr>
            <td>Kerala</td>
            <td>Red Cross Blood Bank</td>
            <td>Kollam</td>
            <td>+91 474 274 5000</td>
          </tr>
          <tr>
            <td>Kerala</td>
            <td>Baby Memorial Hospital Blood Bank</td>
            <td>Kozhikode</td>
            <td>+91 495 277 7777</td>
          </tr>
          <tr>
            <td>Kerala</td>
            <td>Lourdes Hospital Blood Bank</td>
            <td>Kochi</td>
            <td>+91 484 412 3456</td>
          </tr>
          <tr>
            <td>Kerala</td>
            <td>Pushpagiri Medical College Blood Bank</td>
            <td>Thiruvalla</td>
            <td>+91 469 264 5000</td>
          </tr>
          <tr>
            <td>Kerala</td>
            <td>Malabar Cancer Centre Blood Bank</td>
            <td>Thalassery</td>
            <td>+91 490 235 5888</td>
          </tr>

          <tr>
            <td>Lakshadweep</td>
            <td>Indira Gandhi Hospital Blood Bank</td>
            <td>Kavaratti</td>
            <td>+91 4896 262 212</td>
          </tr>
          <tr>
            <td>Lakshadweep</td>
            <td>CHC Blood Bank</td>
            <td>Agatti</td>
            <td>+91 4894 242 267</td>
          </tr>
          <tr>
            <td>Lakshadweep</td>
            <td>PHC Blood Bank</td>
            <td>Amini</td>
            <td>+91 4897 242 345</td>
          </tr>
          <tr>
            <td>Lakshadweep</td>
            <td>CHC Blood Bank</td>
            <td>Andrott</td>
            <td>+91 4898 242 567</td>
          </tr>
          <tr>
            <td>Lakshadweep</td>
            <td>PHC Blood Bank</td>
            <td>Kalpeni</td>
            <td>+91 4899 242 789</td>
          </tr>
          <tr>
            <td>Lakshadweep</td>
            <td>CHC Blood Bank</td>
            <td>Minicoy</td>
            <td>+91 4892 242 123</td>
          </tr>
          <tr>
            <td>Lakshadweep</td>
            <td>PHC Blood Bank</td>
            <td>Bitra</td>
            <td>+91 4891 242 456</td>
          </tr>
          <tr>
            <td>Lakshadweep</td>
            <td>Indira Gandhi Hospital Blood Bank</td>
            <td>Kavaratti</td>
            <td>+91 4896 262 213</td>
          </tr>
          <tr>
            <td>Lakshadweep</td>
            <td>PHC Blood Bank</td>
            <td>Chethlath</td>
            <td>+91 4893 242 678</td>
          </tr>
          <tr>
            <td>Lakshadweep</td>
            <td>CHC Blood Bank</td>
            <td>Kalpeni</td>
            <td>+91 4899 242 790</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>KEM Hospital Blood Bank</td>
            <td>Mumbai</td>
            <td>+91 22 2413 6051</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>JJ Hospital Blood Bank</td>
            <td>Mumbai</td>
            <td>+91 22 2373 5555</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Ruby Hall Clinic Blood Bank</td>
            <td>Pune</td>
            <td>+91 20 2616 3391</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Sassoon General Hospital Blood Bank</td>
            <td>Pune</td>
            <td>+91 20 2612 3456</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Jehangir Hospital Blood Bank</td>
            <td>Pune</td>
            <td>+91 20 6681 6666</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Deenanath Mangeshkar Hospital Blood Bank</td>
            <td>Pune</td>
            <td>+91 20 4015 1000</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Lokmanya Tilak Hospital Blood Bank</td>
            <td>Mumbai</td>
            <td>+91 22 2407 6381</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Grant Medical College Blood Bank</td>
            <td>Mumbai</td>
            <td>+91 22 2373 5555</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Hinduja Hospital Blood Bank</td>
            <td>Mumbai</td>
            <td>+91 22 2444 4444</td>
          </tr>
          <tr>
            <td>Maharashtra</td>
            <td>Red Cross Blood Bank</td>
            <td>Nagpur</td>
            <td>+91 712 254 1234</td>
          </tr>

          <tr>
            <td>Madhya Pradesh</td>
            <td>Gandhi Medical College Blood Bank</td>
            <td>Bhopal</td>
            <td>+91 755 253 5320</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>MY Hospital Blood Bank</td>
            <td>Indore</td>
            <td>+91 731 270 4024</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Red Cross Blood Bank</td>
            <td>Jabalpur</td>
            <td>+91 761 240 8821</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Bundelkhand Medical College Blood Bank</td>
            <td>Sagar</td>
            <td>+91 7582 236 303</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Chirayu Medical College Blood Bank</td>
            <td>Bhopal</td>
            <td>+91 755 267 0322</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>SS Medical College Blood Bank</td>
            <td>Rewa</td>
            <td>+91 7662 241 608</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Netaji Subhash Chandra Bose Medical College Blood Bank</td>
            <td>Jabalpur</td>
            <td>+91 761 267 0444</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>GR Medical College Blood Bank</td>
            <td>Gwalior</td>
            <td>+91 751 232 1602</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>Red Cross Blood Bank</td>
            <td>Ujjain</td>
            <td>+91 734 252 1023</td>
          </tr>
          <tr>
            <td>Madhya Pradesh</td>
            <td>District Hospital Blood Bank</td>
            <td>Satna</td>
            <td>+91 7672 240 232</td>
          </tr>

          <tr>
            <td>Manipur</td>
            <td>RIMS Blood Bank</td>
            <td>Imphal</td>
            <td>+91 385 241 4629</td>
          </tr>
          <tr>
            <td>Manipur</td>
            <td>JNIMS Blood Bank</td>
            <td>Imphal</td>
            <td>+91 385 245 0283</td>
          </tr>
          <tr>
            <td>Manipur</td>
            <td>District Hospital Blood Bank</td>
            <td>Churachandpur</td>
            <td>+91 385 265 0234</td>
          </tr>
          <tr>
            <td>Manipur</td>
            <td>District Hospital Blood Bank</td>
            <td>Thoubal</td>
            <td>+91 385 241 0567</td>
          </tr>
          <tr>
            <td>Manipur</td>
            <td>District Hospital Blood Bank</td>
            <td>Bishnupur</td>
            <td>+91 385 245 0778</td>
          </tr>
          <tr>
            <td>Manipur</td>
            <td>District Hospital Blood Bank</td>
            <td>Ukhrul</td>
            <td>+91 385 257 0234</td>
          </tr>
          <tr>
            <td>Manipur</td>
            <td>District Hospital Blood Bank</td>
            <td>Senapati</td>
            <td>+91 385 263 0456</td>
          </tr>
          <tr>
            <td>Manipur</td>
            <td>District Hospital Blood Bank</td>
            <td>Chandel</td>
            <td>+91 385 265 0456</td>
          </tr>
          <tr>
            <td>Manipur</td>
            <td>District Hospital Blood Bank</td>
            <td>Jiribam</td>
            <td>+91 385 267 0334</td>
          </tr>
          <tr>
            <td>Manipur</td>
            <td>PHC Blood Bank</td>
            <td>Moreh</td>
            <td>+91 385 265 0233</td>
          </tr>

          <tr>
            <td>Meghalaya</td>
            <td>NEIGRIHMS Blood Bank</td>
            <td>Shillong</td>
            <td>+91 364 253 8029</td>
          </tr>
          <tr>
            <td>Meghalaya</td>
            <td>District Hospital Blood Bank</td>
            <td>Tura</td>
            <td>+91 3651 222 234</td>
          </tr>
          <tr>
            <td>Meghalaya</td>
            <td>District Hospital Blood Bank</td>
            <td>Nongpoh</td>
            <td>+91 364 232 236</td>
          </tr>
          <tr>
            <td>Meghalaya</td>
            <td>District Hospital Blood Bank</td>
            <td>Jowai</td>
            <td>+91 3654 220 456</td>
          </tr>
          <tr>
            <td>Meghalaya</td>
            <td>Civil Hospital Blood Bank</td>
            <td>Shillong</td>
            <td>+91 364 222 5010</td>
          </tr>
          <tr>
            <td>Meghalaya</td>
            <td>District Hospital Blood Bank</td>
            <td>Nongstoin</td>
            <td>+91 364 245 0234</td>
          </tr>
          <tr>
            <td>Meghalaya</td>
            <td>PHC Blood Bank</td>
            <td>Baghmara</td>
            <td>+91 3652 245 567</td>
          </tr>
          <tr>
            <td>Meghalaya</td>
            <td>District Hospital Blood Bank</td>
            <td>Williamnagar</td>
            <td>+91 3658 222 123</td>
          </tr>
          <tr>
            <td>Meghalaya</td>
            <td>PHC Blood Bank</td>
            <td>Mawkyrwat</td>
            <td>+91 364 245 0134</td>
          </tr>
          <tr>
            <td>Meghalaya</td>
            <td>District Hospital Blood Bank</td>
            <td>Khliehriat</td>
            <td>+91 3655 222 789</td>
          </tr>

          <tr>
            <td>Mizoram</td>
            <td>Civil Hospital Blood Bank</td>
            <td>Aizawl</td>
            <td>+91 389 232 2113</td>
          </tr>
          <tr>
            <td>Mizoram</td>
            <td>Zoram Medical College Blood Bank</td>
            <td>Falkawn</td>
            <td>+91 389 235 0529</td>
          </tr>
          <tr>
            <td>Mizoram</td>
            <td>District Hospital Blood Bank</td>
            <td>Lunglei</td>
            <td>+91 372 232 345</td>
          </tr>
          <tr>
            <td>Mizoram</td>
            <td>District Hospital Blood Bank</td>
            <td>Serchhip</td>
            <td>+91 389 234 0456</td>
          </tr>
          <tr>
            <td>Mizoram</td>
            <td>PHC Blood Bank</td>
            <td>Kolasib</td>
            <td>+91 389 236 2233</td>
          </tr>
          <tr>
            <td>Mizoram</td>
            <td>District Hospital Blood Bank</td>
            <td>Champhai</td>
            <td>+91 389 235 567</td>
          </tr>
          <tr>
            <td>Mizoram</td>
            <td>District Hospital Blood Bank</td>
            <td>Mamit</td>
            <td>+91 389 236 456</td>
          </tr>
          <tr>
            <td>Mizoram</td>
            <td>District Hospital Blood Bank</td>
            <td>Saiha</td>
            <td>+91 389 237 789</td>
          </tr>
          <tr>
            <td>Mizoram</td>
            <td>PHC Blood Bank</td>
            <td>Lawngtlai</td>
            <td>+91 389 238 456</td>
          </tr>
          <tr>
            <td>Mizoram</td>
            <td>District Hospital Blood Bank</td>
            <td>Aizawl</td>
            <td>+91 389 232 2114</td>
          </tr>

          <tr>
            <td>Nagaland</td>
            <td>Naga Hospital Blood Bank</td>
            <td>Kohima</td>
            <td>+91 370 229 0080</td>
          </tr>
          <tr>
            <td>Nagaland</td>
            <td>District Hospital Blood Bank</td>
            <td>Dimapur</td>
            <td>+91 3862 229 990</td>
          </tr>
          <tr>
            <td>Nagaland</td>
            <td>District Hospital Blood Bank</td>
            <td>Mokokchung</td>
            <td>+91 3862 221 334</td>
          </tr>
          <tr>
            <td>Nagaland</td>
            <td>District Hospital Blood Bank</td>
            <td>Zunheboto</td>
            <td>+91 3862 221 445</td>
          </tr>
          <tr>
            <td>Nagaland</td>
            <td>District Hospital Blood Bank</td>
            <td>Wokha</td>
            <td>+91 3862 223 556</td>
          </tr>
          <tr>
            <td>Nagaland</td>
            <td>District Hospital Blood Bank</td>
            <td>Phek</td>
            <td>+91 3862 224 667</td>
          </tr>
          <tr>
            <td>Nagaland</td>
            <td>District Hospital Blood Bank</td>
            <td>Tuensang</td>
            <td>+91 3862 225 778</td>
          </tr>
          <tr>
            <td>Nagaland</td>
            <td>District Hospital Blood Bank</td>
            <td>Mon</td>
            <td>+91 3862 226 889</td>
          </tr>
          <tr>
            <td>Nagaland</td>
            <td>PHC Blood Bank</td>
            <td>Peren</td>
            <td>+91 3862 227 990</td>
          </tr>
          <tr>
            <td>Nagaland</td>
            <td>District Hospital Blood Bank</td>
            <td>Kiphire</td>
            <td>+91 3862 228 001</td>
          </tr>
          <tr>
            <td>Odisha</td>
            <td>SCB Medical College Blood Bank</td>
            <td>Cuttack</td>
            <td>+91 671 241 4080</td>
          </tr>
          <tr>
            <td>Odisha</td>
            <td>Capital Hospital Blood Bank</td>
            <td>Bhubaneswar</td>
            <td>+91 674 239 1983</td>
          </tr>
          <tr>
            <td>Odisha</td>
            <td>MKCG Medical College Blood Bank</td>
            <td>Berhampur</td>
            <td>+91 680 229 2746</td>
          </tr>
          <tr>
            <td>Odisha</td>
            <td>VIMSAR Blood Bank</td>
            <td>Sambalpur</td>
            <td>+91 663 243 0768</td>
          </tr>
          <tr>
            <td>Odisha</td>
            <td>AIIMS Bhubaneswar Blood Bank</td>
            <td>Bhubaneswar</td>
            <td>+91 674 247 6789</td>
          </tr>
          <tr>
            <td>Odisha</td>
            <td>Red Cross Blood Bank</td>
            <td>Cuttack</td>
            <td>+91 671 241 4190</td>
          </tr>
          <tr>
            <td>Odisha</td>
            <td>District Hospital Blood Bank</td>
            <td>Rourkela</td>
            <td>+91 661 264 2545</td>
          </tr>
          <tr>
            <td>Odisha</td>
            <td>District Hospital Blood Bank</td>
            <td>Bolangir</td>
            <td>+91 6652 232 334</td>
          </tr>
          <tr>
            <td>Odisha</td>
            <td>District Hospital Blood Bank</td>
            <td>Puri</td>
            <td>+91 6752 232 456</td>
          </tr>
          <tr>
            <td>Odisha</td>
            <td>District Hospital Blood Bank</td>
            <td>Balasore</td>
            <td>+91 6782 240 789</td>
          </tr>

          <tr>
            <td>Pondicherry</td>
            <td>JIPMER Blood Bank</td>
            <td>Puducherry</td>
            <td>+91 413 229 6002</td>
          </tr>
          <tr>
            <td>Pondicherry</td>
            <td>Indira Gandhi Government General Hospital Blood Bank</td>
            <td>Puducherry</td>
            <td>+91 413 233 6050</td>
          </tr>
          <tr>
            <td>Pondicherry</td>
            <td>Pondicherry Institute of Medical Sciences Blood Bank</td>
            <td>Puducherry</td>
            <td>+91 413 265 6271</td>
          </tr>
          <tr>
            <td>Pondicherry</td>
            <td>Arupadai Veedu Medical College Blood Bank</td>
            <td>Puducherry</td>
            <td>+91 413 261 5449</td>
          </tr>
          <tr>
            <td>Pondicherry</td>
            <td>Red Cross Blood Bank</td>
            <td>Puducherry</td>
            <td>+91 413 222 0300</td>
          </tr>
          <tr>
            <td>Pondicherry</td>
            <td>Mahathma Gandhi Medical College Blood Bank</td>
            <td>Puducherry</td>
            <td>+91 413 261 5446</td>
          </tr>
          <tr>
            <td>Pondicherry</td>
            <td>Sri Venkateswara Medical College Blood Bank</td>
            <td>Puducherry</td>
            <td>+91 413 227 3399</td>
          </tr>
          <tr>
            <td>Pondicherry</td>
            <td>District Hospital Blood Bank</td>
            <td>Karaikal</td>
            <td>+91 4368 222 090</td>
          </tr>
          <tr>
            <td>Pondicherry</td>
            <td>CHC Blood Bank</td>
            <td>Mahe</td>
            <td>+91 490 233 2123</td>
          </tr>
          <tr>
            <td>Pondicherry</td>
            <td>CHC Blood Bank</td>
            <td>Yanam</td>
            <td>+91 884 232 1123</td>
          </tr>

          <tr>
            <td>Punjab</td>
            <td>PGIMER Blood Bank</td>
            <td>Chandigarh</td>
            <td>+91 172 275 6604</td>
          </tr>
          <tr>
            <td>Punjab</td>
            <td>Government Medical College Blood Bank</td>
            <td>Amritsar</td>
            <td>+91 183 242 6918</td>
          </tr>
          <tr>
            <td>Punjab</td>
            <td>DMCH Blood Bank</td>
            <td>Ludhiana</td>
            <td>+91 161 468 6600</td>
          </tr>
          <tr>
            <td>Punjab</td>
            <td>CMC Blood Bank</td>
            <td>Ludhiana</td>
            <td>+91 161 501 0809</td>
          </tr>
          <tr>
            <td>Punjab</td>
            <td>Government Rajindra Hospital Blood Bank</td>
            <td>Patiala</td>
            <td>+91 175 221 2017</td>
          </tr>
          <tr>
            <td>Punjab</td>
            <td>Max Super Speciality Hospital Blood Bank</td>
            <td>Mohali</td>
            <td>+91 172 665 2000</td>
          </tr>
          <tr>
            <td>Punjab</td>
            <td>Fortis Hospital Blood Bank</td>
            <td>Mohali</td>
            <td>+91 172 469 2222</td>
          </tr>
          <tr>
            <td>Punjab</td>
            <td>Government Medical College Blood Bank</td>
            <td>Faridkot</td>
            <td>+91 1639 251 115</td>
          </tr>
          <tr>
            <td>Punjab</td>
            <td>Red Cross Blood Bank</td>
            <td>Jalandhar</td>
            <td>+91 181 222 2791</td>
          </tr>
          <tr>
            <td>Punjab</td>
            <td>District Hospital Blood Bank</td>
            <td>Bathinda</td>
            <td>+91 164 221 0354</td>
          </tr>

          <tr>
            <td>Rajasthan</td>
            <td>Sawai Man Singh Hospital Blood Bank</td>
            <td>Jaipur</td>
            <td>+91 141 256 0291</td>
          </tr>
          <tr>
            <td>Rajasthan</td>
            <td>JLN Hospital Blood Bank</td>
            <td>Ajmer</td>
            <td>+91 145 243 1960</td>
          </tr>
          <tr>
            <td>Rajasthan</td>
            <td>SP Medical College Blood Bank</td>
            <td>Bikaner</td>
            <td>+91 151 252 8822</td>
          </tr>
          <tr>
            <td>Rajasthan</td>
            <td>Red Cross Blood Bank</td>
            <td>Jodhpur</td>
            <td>+91 291 243 1267</td>
          </tr>
          <tr>
            <td>Rajasthan</td>
            <td>RUHS Blood Bank</td>
            <td>Jaipur</td>
            <td>+91 141 279 5577</td>
          </tr>
          <tr>
            <td>Rajasthan</td>
            <td>Mahila Hospital Blood Bank</td>
            <td>Jaipur</td>
            <td>+91 141 256 3040</td>
          </tr>
          <tr>
            <td>Rajasthan</td>
            <td>Geetanjali Hospital Blood Bank</td>
            <td>Udaipur</td>
            <td>+91 294 250 6000</td>
          </tr>
          <tr>
            <td>Rajasthan</td>
            <td>Red Cross Blood Bank</td>
            <td>Kota</td>
            <td>+91 744 250 1371</td>
          </tr>
          <tr>
            <td>Rajasthan</td>
            <td>District Hospital Blood Bank</td>
            <td>Alwar</td>
            <td>+91 144 270 2033</td>
          </tr>
          <tr>
            <td>Rajasthan</td>
            <td>Government Hospital Blood Bank</td>
            <td>Jaipur</td>
            <td>+91 141 256 7324</td>
          </tr>

          <tr>
            <td>Sikkim</td>
            <td>STNM Hospital Blood Bank</td>
            <td>Gangtok</td>
            <td>+91 3592 202 333</td>
          </tr>
          <tr>
            <td>Sikkim</td>
            <td>District Hospital Blood Bank</td>
            <td>Namchi</td>
            <td>+91 3595 263 345</td>
          </tr>
          <tr>
            <td>Sikkim</td>
            <td>District Hospital Blood Bank</td>
            <td>Mangan</td>
            <td>+91 3593 264 234</td>
          </tr>
          <tr>
            <td>Sikkim</td>
            <td>District Hospital Blood Bank</td>
            <td>Gyalshing</td>
            <td>+91 3595 264 567</td>
          </tr>
          <tr>
            <td>Sikkim</td>
            <td>PHC Blood Bank</td>
            <td>Singtam</td>
            <td>+91 3595 232 123</td>
          </tr>
          <tr>
            <td>Sikkim</td>
            <td>District Hospital Blood Bank</td>
            <td>Rangpo</td>
            <td>+91 3595 232 456</td>
          </tr>
          <tr>
            <td>Sikkim</td>
            <td>District Hospital Blood Bank</td>
            <td>Jorethang</td>
            <td>+91 3595 264 789</td>
          </tr>
          <tr>
            <td>Sikkim</td>
            <td>PHC Blood Bank</td>
            <td>Ravangla</td>
            <td>+91 3595 265 567</td>
          </tr>
          <tr>
            <td>Sikkim</td>
            <td>District Hospital Blood Bank</td>
            <td>Pakyong</td>
            <td>+91 3595 266 234</td>
          </tr>
          <tr>
            <td>Sikkim</td>
            <td>District Hospital Blood Bank</td>
            <td>Rongli</td>
            <td>+91 3595 267 890</td>
          </tr>
          <tr>
            <td>Tamil Nadu</td>
            <td>Rajiv Gandhi Government General Hospital Blood Bank</td>
            <td>Chennai</td>
            <td>+91 44 2530 5000</td>
          </tr>
          <tr>
            <td>Tamil Nadu</td>
            <td>Government Kilpauk Medical College Blood Bank</td>
            <td>Chennai</td>
            <td>+91 44 2641 1446</td>
          </tr>
          <tr>
            <td>Tamil Nadu</td>
            <td>Christian Medical College Blood Bank</td>
            <td>Vellore</td>
            <td>+91 416 228 2691</td>
          </tr>
          <tr>
            <td>Tamil Nadu</td>
            <td>Government Stanley Medical College Blood Bank</td>
            <td>Chennai</td>
            <td>+91 44 2528 1398</td>
          </tr>
          <tr>
            <td>Tamil Nadu</td>
            <td>Madras Medical Mission Blood Bank</td>
            <td>Chennai</td>
            <td>+91 44 2656 2222</td>
          </tr>
          <tr>
            <td>Tamil Nadu</td>
            <td>PSG Hospitals Blood Bank</td>
            <td>Coimbatore</td>
            <td>+91 422 257 0170</td>
          </tr>
          <tr>
            <td>Tamil Nadu</td>
            <td>Meenakshi Mission Hospital Blood Bank</td>
            <td>Madurai</td>
            <td>+91 452 258 8741</td>
          </tr>
          <tr>
            <td>Tamil Nadu</td>
            <td>Government Rajaji Hospital Blood Bank</td>
            <td>Madurai</td>
            <td>+91 452 253 2535</td>
          </tr>
          <tr>
            <td>Tamil Nadu</td>
            <td>Government Hospital Blood Bank</td>
            <td>Salem</td>
            <td>+91 427 231 0300</td>
          </tr>
          <tr>
            <td>Tamil Nadu</td>
            <td>Government Medical College Blood Bank</td>
            <td>Thanjavur</td>
            <td>+91 4362 240 105</td>
          </tr>

          <tr>
            <td>Telangana</td>
            <td>Osmania General Hospital Blood Bank</td>
            <td>Hyderabad</td>
            <td>+91 40 2460 0010</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>Gandhi Hospital Blood Bank</td>
            <td>Secunderabad</td>
            <td>+91 40 2750 1080</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>Nizam's Institute of Medical Sciences Blood Bank</td>
            <td>Hyderabad</td>
            <td>+91 40 2348 9000</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>MNJ Institute of Oncology Blood Bank</td>
            <td>Hyderabad</td>
            <td>+91 40 2465 4505</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>Red Cross Blood Bank</td>
            <td>Hyderabad</td>
            <td>+91 40 2460 1055</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>Apollo Hospital Blood Bank</td>
            <td>Hyderabad</td>
            <td>+91 40 2360 7777</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>Care Hospital Blood Bank</td>
            <td>Hyderabad</td>
            <td>+91 40 3041 7777</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>Yashoda Hospital Blood Bank</td>
            <td>Hyderabad</td>
            <td>+91 40 4567 4567</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>Government Medical College Blood Bank</td>
            <td>Warangal</td>
            <td>+91 870 244 4556</td>
          </tr>
          <tr>
            <td>Telangana</td>
            <td>District Hospital Blood Bank</td>
            <td>Nizamabad</td>
            <td>+91 8462 220 042</td>
          </tr>

          <tr>
            <td>Tripura</td>
            <td>GB Pant Hospital Blood Bank</td>
            <td>Agartala</td>
            <td>+91 381 232 5311</td>
          </tr>
          <tr>
            <td>Tripura</td>
            <td>IGM Hospital Blood Bank</td>
            <td>Agartala</td>
            <td>+91 381 232 3366</td>
          </tr>
          <tr>
            <td>Tripura</td>
            <td>District Hospital Blood Bank</td>
            <td>Kailashahar</td>
            <td>+91 3821 222 490</td>
          </tr>
          <tr>
            <td>Tripura</td>
            <td>District Hospital Blood Bank</td>
            <td>Udaipur</td>
            <td>+91 3821 222 369</td>
          </tr>
          <tr>
            <td>Tripura</td>
            <td>District Hospital Blood Bank</td>
            <td>Dharmanagar</td>
            <td>+91 3822 222 123</td>
          </tr>
          <tr>
            <td>Tripura</td>
            <td>District Hospital Blood Bank</td>
            <td>Ambassa</td>
            <td>+91 3826 222 345</td>
          </tr>
          <tr>
            <td>Tripura</td>
            <td>District Hospital Blood Bank</td>
            <td>Bishalgarh</td>
            <td>+91 3825 222 567</td>
          </tr>
          <tr>
            <td>Tripura</td>
            <td>PHC Blood Bank</td>
            <td>Amarpur</td>
            <td>+91 3823 222 678</td>
          </tr>
          <tr>
            <td>Tripura</td>
            <td>PHC Blood Bank</td>
            <td>Sonamura</td>
            <td>+91 3824 222 789</td>
          </tr>
          <tr>
            <td>Tripura</td>
            <td>PHC Blood Bank</td>
            <td>Kamalpur</td>
            <td>+91 3827 222 456</td>
          </tr>

          <tr>
            <td>Uttarakhand</td>
            <td>AIIMS Rishikesh Blood Bank</td>
            <td>Rishikesh</td>
            <td>+91 135 246 2940</td>
          </tr>
          <tr>
            <td>Uttarakhand</td>
            <td>Government Doon Medical College Blood Bank</td>
            <td>Dehradun</td>
            <td>+91 135 271 6875</td>
          </tr>
          <tr>
            <td>Uttarakhand</td>
            <td>District Hospital Blood Bank</td>
            <td>Haridwar</td>
            <td>+91 1334 240 800</td>
          </tr>
          <tr>
            <td>Uttarakhand</td>
            <td>Himalayan Hospital Blood Bank</td>
            <td>Dehradun</td>
            <td>+91 135 247 1200</td>
          </tr>
          <tr>
            <td>Uttarakhand</td>
            <td>CMC Blood Bank</td>
            <td>Haldwani</td>
            <td>+91 5946 282 800</td>
          </tr>
          <tr>
            <td>Uttarakhand</td>
            <td>Sushila Tiwari Memorial Hospital Blood Bank</td>
            <td>Haldwani</td>
            <td>+91 5946 232 178</td>
          </tr>
          <tr>
            <td>Uttarakhand</td>
            <td>District Hospital Blood Bank</td>
            <td>Almora</td>
            <td>+91 5962 234 567</td>
          </tr>
          <tr>
            <td>Uttarakhand</td>
            <td>District Hospital Blood Bank</td>
            <td>Rudrapur</td>
            <td>+91 5944 230 789</td>
          </tr>
          <tr>
            <td>Uttarakhand</td>
            <td>District Hospital Blood Bank</td>
            <td>Pithoragarh</td>
            <td>+91 5964 223 123</td>
          </tr>
          <tr>
            <td>Uttarakhand</td>
            <td>PHC Blood Bank</td>
            <td>Srinagar</td>
            <td>+91 1346 252 345</td>
          </tr>

          <tr>
            <td>Uttar Pradesh</td>
            <td>King George's Medical University Blood Bank</td>
            <td>Lucknow</td>
            <td>+91 522 225 7450</td>
          </tr>
          <tr>
            <td>Uttar Pradesh</td>
            <td>Sanjay Gandhi Post Graduate Institute Blood Bank</td>
            <td>Lucknow</td>
            <td>+91 522 266 8000</td>
          </tr>
          <tr>
            <td>Uttar Pradesh</td>
            <td>Banaras Hindu University Blood Bank</td>
            <td>Varanasi</td>
            <td>+91 542 230 7500</td>
          </tr>
          <tr>
            <td>Uttar Pradesh</td>
            <td>Dr. Ram Manohar Lohia Hospital Blood Bank</td>
            <td>Lucknow</td>
            <td>+91 522 225 5540</td>
          </tr>
          <tr>
            <td>Uttar Pradesh</td>
            <td>Lala Lajpat Rai Memorial Medical College Blood Bank</td>
            <td>Meerut</td>
            <td>+91 121 276 0887</td>
          </tr>
          <tr>
            <td>Uttar Pradesh</td>
            <td>MLN Medical College Blood Bank</td>
            <td>Prayagraj</td>
            <td>+91 532 244 1222</td>
          </tr>
          <tr>
            <td>Uttar Pradesh</td>
            <td>Red Cross Blood Bank</td>
            <td>Kanpur</td>
            <td>+91 512 230 4057</td>
          </tr>
          <tr>
            <td>Uttar Pradesh</td>
            <td>District Hospital Blood Bank</td>
            <td>Agra</td>
            <td>+91 562 226 6414</td>
          </tr>
          <tr>
            <td>Uttar Pradesh</td>
            <td>BRD Medical College Blood Bank</td>
            <td>Gorakhpur</td>
            <td>+91 551 250 1732</td>
          </tr>
          <tr>
            <td>Uttar Pradesh</td>
            <td>Government Medical College Blood Bank</td>
            <td>Jhansi</td>
            <td>+91 510 232 1234</td>
          </tr>

          <tr>
            <td>West Bengal</td>
            <td>Institute of Blood Transfusion Medicine Blood Bank</td>
            <td>Kolkata</td>
            <td>+91 33 2244 6474</td>
          </tr>
          <tr>
            <td>West Bengal</td>
            <td>Calcutta Medical College Blood Bank</td>
            <td>Kolkata</td>
            <td>+91 33 2255 1000</td>
          </tr>
          <tr>
            <td>West Bengal</td>
            <td>Nil Ratan Sircar Medical College Blood Bank</td>
            <td>Kolkata</td>
            <td>+91 33 2265 3212</td>
          </tr>
          <tr>
            <td>West Bengal</td>
            <td>RG Kar Medical College Blood Bank</td>
            <td>Kolkata</td>
            <td>+91 33 2555 7656</td>
          </tr>
          <tr>
            <td>West Bengal</td>
            <td>Institute of Hematology Blood Bank</td>
            <td>Kolkata</td>
            <td>+91 33 2223 1280</td>
          </tr>
          <tr>
            <td>West Bengal</td>
            <td>SSKM Hospital Blood Bank</td>
            <td>Kolkata</td>
            <td>+91 33 2223 7855</td>
          </tr>
          <tr>
            <td>West Bengal</td>
            <td>Command Hospital Blood Bank</td>
            <td>Kolkata</td>
            <td>+91 33 2222 4567</td>
          </tr>
          <tr>
            <td>West Bengal</td>
            <td>Red Cross Blood Bank</td>
            <td>Asansol</td>
            <td>+91 341 230 1234</td>
          </tr>
          <tr>
            <td>West Bengal</td>
            <td>District Hospital Blood Bank</td>
            <td>Darjeeling</td>
            <td>+91 354 225 5523</td>
          </tr>
          <tr>
            <td>West Bengal</td>
            <td>North Bengal Medical College Blood Bank</td>
            <td>Siliguri</td>
            <td>+91 353 258 5478</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BloodBank;
