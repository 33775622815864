import React from 'react'
import './Disclaimer.css'

const Disclaimer = () => {
  return (
    <div class="container">
        <h1>Disclaimer</h1>
        <p>This website is dedicated to providing information and resources related to blood donation, organ donation, hair donation, and ambulance services. By using this website, you agree to the terms and conditions outlined in this disclaimer. Please read this disclaimer carefully before using the website.</p>
        
        <h2>General Information</h2>
        <p>The information provided on this website is for general informational purposes only. While we strive to keep the information up to date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>

        <h2>Blood Donor Registration</h2>
        <p>Our website allows individuals to register as blood donors and provides their information to those in need of blood. We do not endorse or guarantee the suitability, qualifications, or safety of any registered blood donor. Users are responsible for conducting their own due diligence and ensuring all necessary medical protocols are followed when arranging blood donations.</p>

        <h2>Organ and Hair Donation Information</h2>
        <p>The information provided on organ and hair donation is intended to educate users about the processes, benefits, and considerations associated with these types of donations. This website does not facilitate or participate in organ or hair donation transactions or agreements. We strongly advise users to consult with medical professionals and authorized organizations before making any decisions regarding organ or hair donation.</p>

        <h2>Ambulance Services</h2>
        <p>Information regarding ambulance services provided on this website is intended to inform users of available options for medical transportation. This website does not provide ambulance services directly and is not responsible for any services rendered by third-party ambulance providers. Users should independently verify the credentials, availability, and reliability of any ambulance service before use.</p>

        <h2>Third-Party Links</h2>
        <p>This website may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</p>

        <h2>Limitation of Liability</h2>
        <p>In no event shall this website or its owners, affiliates, partners, or employees be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the website. This includes, but is not limited to, any errors or omissions in any content, any loss or damage of any kind incurred as a result of the use of any content posted, transmitted, or otherwise made available via the website, even if advised of the possibility of such damages.</p>

        <h2>Changes to This Disclaimer</h2>
        <p>We may update our disclaimer from time to time. We will notify you of any changes by posting the new disclaimer on this page. You are advised to review this disclaimer periodically for any changes. Changes to this disclaimer are effective when they are posted on this page.</p>

        <p>If you have any questions about this disclaimer, please contact us through our website's contact page.</p>
    </div>
  )
}

export default Disclaimer