import React from 'react'
import './Ambulance.css'

const Ambulance = () => {
  return (
    <div class="container">
        <h1>Ambulance Toll-Free Numbers in India</h1>
        <p>Below is a comprehensive list of toll-free numbers for ambulance services across all states in India. Please use these numbers in case of a medical emergency.</p>
        <table>
            <thead>
                <tr>
                    <th>State/Union Territory</th>
                    <th>Toll-Free Number</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Andhra Pradesh</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Arunachal Pradesh</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Assam</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Bihar</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Chhattisgarh</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Goa</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Gujarat</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Haryana</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Himachal Pradesh</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Jharkhand</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Karnataka</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Kerala</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Madhya Pradesh</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Maharashtra</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Manipur</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Meghalaya</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Mizoram</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Nagaland</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Odisha</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Punjab</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Rajasthan</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Sikkim</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Tamil Nadu</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Telangana</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Tripura</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Uttar Pradesh</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>Uttarakhand</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>West Bengal</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Andaman and Nicobar Islands</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Chandigarh</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Dadra and Nagar Haveli and Daman and Diu</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Delhi</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Lakshadweep</td>
                    <td>102</td>
                </tr>
                <tr>
                    <td>Puducherry</td>
                    <td>108</td>
                </tr>
            </tbody>
        </table>
        <p>Please note that the toll-free numbers listed above are intended for use in emergencies only. For non-emergency inquiries, please contact local healthcare facilities or government health services.</p>
    </div>
  )
}

export default Ambulance