import React from "react";
import "./Contact.css";
const Contact = () => {
  return (
    <div className="contact">
      <div class="contact-form">
        <form action="submit_form.php" method="POST">
          <div class="form-group row">
            <div class="col">
              <label for="fullname">Full Name</label>
              <input type="text" id="fullname" name="fullname" required />
            </div>
            <div class="col">
              <label for="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
          </div>
          <div class="form-group">
            <label for="subject">Subject</label>
            <input type="text" id="subject" name="subject" required />
          </div>
          <div class="form-group">
            <label for="message">Message</label>
            <textarea id="message" name="message" required></textarea>
          </div>
          <button type="submit" class="submit-btn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
