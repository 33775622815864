import React from 'react'
import './Hair.css'

const Hair = () => {
  return (
    <>
    <img src="images/hair_banner.jpg" alt="organbanner"/>
    <div className="container">
        <h1 style={{textAlign: 'center'}}>Hair Donation in India</h1>

        <h2>What is Hair Donation?</h2>
        <p>Hair donation is the act of cutting and donating one's hair to create wigs for individuals who have lost their hair due to medical conditions such as cancer, alopecia, or other illnesses. Donated hair is used to make natural hair wigs, which can help improve the quality of life for people experiencing hair loss.</p>

        <h2>How is Hair Donation Done?</h2>
        <p>The process of hair donation is simple and can be done by following these steps:</p>
        <ol>
            <li>Ensure your hair meets the minimum length requirement (usually between 8-12 inches, depending on the organization).</li>
            <li>Wash and dry your hair thoroughly. Do not use any styling products.</li>
            <li>Divide your hair into sections and secure each section with a rubber band or hair tie.</li>
            <li>Cut the hair just above the rubber band. Make sure to use sharp scissors for a clean cut.</li>
            <li>Place the cut hair in a sealed plastic bag and send it to a hair donation organization by post or courier.</li>
        </ol>

        <h2>Who Can Donate Hair?</h2>
        <p>Anyone can donate hair as long as it meets the following criteria:</p>
        <ul>
            <li><strong>Length:</strong> The hair must be at least 8-12 inches long, depending on the requirements of the organization.</li>
            <li><strong>Condition:</strong> The hair should be clean and dry, free of any styling products or chemicals. Some organizations accept colored or chemically treated hair, while others do not. Check the specific guidelines of the organization you wish to donate to.</li>
            <li><strong>Type:</strong> Both natural and synthetic hair can be donated, but most organizations prefer natural, untreated hair.</li>
        </ul>

        <h2>Benefits of Hair Donation</h2>
        <p>Hair donation provides several benefits, including:</p>
        <ul>
            <li><strong>Helping Those in Need:</strong> Donated hair is used to make wigs for individuals who have lost their hair due to medical conditions, helping them regain confidence and feel more like themselves.</li>
            <li><strong>Raising Awareness:</strong> By donating hair, you help raise awareness about hair loss conditions and the emotional impact they can have on individuals.</li>
            <li><strong>Promoting Empathy and Compassion:</strong> Hair donation is a simple yet impactful way to show support and compassion for those experiencing hair loss.</li>
        </ul>

        <h2>How to Register for Hair Donation</h2>
        <p>Registering for hair donation is not typically required, but following these steps can help ensure a smooth donation process:</p>
        <div className="register-info">
            <ol>
                <li>Visit the website of a recognized hair donation organization to understand their specific guidelines and requirements.</li>
                <li>Follow the hair cutting and packaging instructions provided by the organization.</li>
                <li>Fill out any donation forms or provide necessary information as requested by the organization.</li>
                <li>Mail the packaged hair along with the completed forms to the organization's address.</li>
            </ol>
        </div>

        <h2>Whom to Contact in India</h2>
        <p>If you are interested in donating hair in India or need more information, you can contact the following organizations:</p>
        <div className="contact-info">
            <h3>Hair for Hope India</h3>
            <p>Website: <a href="https://www.hairforhope.in" target="_blank" rel="noreferrer">https://www.hairforhope.in</a></p>
            <p>Email: <a href="mailto:info@hairforhope.in">info@hairforhope.in</a></p>

            <h3>Cope with Cancer – Madat Trust</h3>
            <p>Website: <a href="https://www.copewithcancer.org" target="_blank" rel="noreferrer">https://www.copewithcancer.org</a></p>
            <p>Email: <a href="mailto:info@copewithcancer.org">info@copewithcancer.org</a></p>
        </div>
    </div>
    </>
  )
}

export default Hair